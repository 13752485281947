import usePostMessage from 'hooks/usePostMessage'
import { useState } from 'react'

import { Container } from './style'

interface DiscountBoxInformationProps {
  invites?: number
  discount?: number
  discountIncrement?: number
}

const DiscountBoxInformation = ({ invites, discount, discountIncrement }: DiscountBoxInformationProps) => {
  const [state, setState] = useState<DiscountBoxInformationProps>({
    invites,
    discount,
    discountIncrement,
  })
  usePostMessage('DiscountBoxInformation', setState)

  return (
    <Container>
      <div className="wrapperBox">
        <div className="header">Quanto você está economizando</div>
        <div className="box">
          <span className="title">Convites ativos</span>
          <span data-testid="invites" className="value">
            {state.invites}
          </span>
        </div>
        <div className="box">
          <span className="title">Desconto ativo</span>
          <span data-testid="discount" className="value">
            {state.discount}%
          </span>
        </div>
        <div className="information" data-testid="information">
          Ganhe {state.discountIncrement}% de desconto no custo de suas transferências para cada amigo que usar o seu
          código. Esse desconto é cumulativo e pode chegar até 50%.
        </div>
      </div>
    </Container>
  )
}

DiscountBoxInformation.defaultProps = {
  invites: 0,
  discount: 0,
  discountIncrement: 0,
}

export default DiscountBoxInformation
