import { Container, FormContainer } from './styles'

interface ITemplateForm {
  form: React.ReactNode
  titleSubtitle: React.ReactNode
  linkButton?: React.ReactNode
}

export default function TemplateForm({ form, titleSubtitle, linkButton }: ITemplateForm) {
  return (
    <Container>
      {titleSubtitle}
      <FormContainer>
        {form}
        {linkButton}
      </FormContainer>
    </Container>
  )
}
