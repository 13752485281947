import styled, { css } from 'styled-components'

interface StyleProps {
  width?: number
}

export const PageContainer = styled.section`
  width: 100%;
  margin: 0 auto 0 10px;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-itens: center;
    width: 95%;
    margin: auto;
  }

  & > .box {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;

    & > .information {
      display: flex;
      margin-top: 40px;
      margin-bottom: 50px;

      &--details {
        @extend .information;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 40px;
      }

      & > .number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        background: #556688;
        color: #ffffff;
        font-size: 1em;
        border-radius: 100%;
        margin-right: 8px;
        line-height: 100%;
      }

      & > p {
        font-size: 1em;
        line-height: 160%;
        color: #556688;
      }
    }

    .cta {
      width: 100%;
      background: linear-gradient(310.56deg, #2244ff 27.06%, #0099ff 100%);
      border-radius: 32px;
      align-items: center;
      padding: 15px 40px;
      color: #ffffff;
      font-size: 0.8em;
      box-shadow: none;
      border: none;
      cursor: pointer;

      &:disabled {
        background: #c7d0db;
      }
    }

    & > .uploadBox {
      width: 100%;
      max-width: 454px;
      height: 240px;
      background: #ffffff;
      border: 1px dashed #556688;
      box-sizing: border-box;
      border-radius: 4px;
      margin-bottom: 50px;

      .changeFile {
        font-size: 0.8em;
        color: #34a3ff;
        background: none;
        border: none;
        cursor: pointer;
        border-bottom: 1px solid #0096ff;
        line-height: 18px;
      }

      .cta {
        margin-top: 40px;
        max-width: 112px;
        font-size: 1.2em;
        padding: 11px 0;
      }

      .boxDocument {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        cursor: pointer;
        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          p {
            margin-top: 10px;
            font-size: 1.1em;
            line-height: 20px;
            text-align: center;
            color: #556688;
          }
        }
      }

      .boxDocumentImage {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 45px);
        background: #f1f2f3;

        .imgBox {
          width: 85%;
          height: 85%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .boxDocumentInfos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        background: #fff;
        width: 100%;
        height: 45px;

        .fileName {
          font-size: 0.8em;
          color: #576684;
        }
      }

      .boxDocumentSuccess,
      .boxDocumentError {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #f9d1d3;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: 4px;

        p {
          font-size: 1.1em;
          line-height: 20px;
          text-align: center;
          color: #700f15;
          margin-top: 10px;
          margin-bottom: 30px;
        }

        .changeFile {
          font-size: 1.2em;
        }
      }

      .boxDocumentSuccess {
        background: #caf4d6;
        height: calc(100% - 45px);

        p {
          color: #124f23;
        }
      }
    }

    & > .sendLater {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      margin-top: 20px;
    }

    #legacyButton {
      font-size: 1.1em;
    }

    & > .selectDocument {
      margin: 0;
      padding: 0;
      list-style: none;

      & > li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 16px 0;
        background: #ffffff;
        box-shadow: 1px 1px 12px rgba(19, 49, 109, 0.12);
        width: 100%;
        height: 48px;

        cursor: pointer;

        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        & > button {
          width: 100%;
          height: 100%;
          background: none;
          border: none;
          color: #121761;
          font-size: 1em;
          font-weight: 600;
        }
      }
    }

    & > .documentTopicList {
      margin-bottom: 50px;
      & > li {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        font-size: 1em;
        color: #556688;
        margin: 26px 0;
        line-height: 150%;

        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          background: #0099ff;
        }

        .text {
          width: calc(100% - 57px);
        }
      }
    }
  }
`
export const Container = styled.section<StyleProps>`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto 0 auto;

  & .sendDocument {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    h2 {
      margin: 0;
    }

    .backButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1em;
      font-weight: 700;
      color: #0099ff;
      position: absolute;
      left: 0px;
      cursor: pointer;
      background: none;
      border: none;
      left: 70px;
      ${(props) =>
        props.width &&
        props.width < 800 &&
        css`
          left: 0px;
        `}
      @media (max-width: 640px) {
        align-items: center;
        justify-content: center;
        left: 10px;

        .backButtonLabel {
          display: none;
        }
      }
    }
    @media only screen and (max-width: 640px) {
      position: relative;
    }
  }
`
