import styled from 'styled-components'

interface ComponentProps {
  fontSize?: number
  fontWeight?: number
  children: React.ReactNode
}

const TitleStyle = styled.h2<ComponentProps>`
  font-size: ${(props) => props.fontSize}px;
  line-height: 1.3125;
  margin-bottom: 13px;
  font-weight: ${(props) => props.fontWeight};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #404b6a;
`

export default function Title({ children, fontSize = 16, fontWeight = 600 }: ComponentProps) {
  return (
    <TitleStyle fontSize={fontSize} fontWeight={fontWeight}>
      {children}
    </TitleStyle>
  )
}
