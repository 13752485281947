import { createTheme } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

declare module '@mui/material/styles' {
  interface Theme {
    remessa: {
      palette: {
        primary: {
          main: string
        }
        common: {
          icon: string
          arrowSelect: string
          title: string
          white: string
          headerBorder: string
          error: string
          logoRemessa: string
          inputText: string
          registerModalTitle: string
          registerModalSubtitle: string
          natureTitle: string
          natureSubtitle: string
          natureBoxShaddow: string
          natureCardText: string
          corruptedFileModalTitle: string
          corruptedFileModalSubtitle: string
        }
        gradients: {
          registerButtonGradient: string
        }
        nubank: {
          nuPrimary: string
          nuError: string
        }
      }
      breakpoints: {
        sm: string
        lg: string
      }
    }
  }
  interface ThemeOptions {
    remessa?: {
      palette?: {
        primary: {
          main: string
        }
        common?: {
          icon: string
          arrowSelect: string
          title: string
          white: string
          headerBorder: string
          error: string
          logoRemessa: string
          inputText: string
          registerModalTitle: string
          registerModalSubtitle: string
          natureTitle: string
          natureSubtitle: string
          natureBoxShaddow: string
          natureCardText: string
          corruptedFileModalTitle: string
          corruptedFileModalSubtitle: string
        }
        gradients?: {
          registerButtonGradient?: string
        }
        nubank: {
          nuPrimary?: string
          nuError?: string
        }
      }
      breakpoints?: {
        sm: string
        lg: string
      }
    }
  }
}

const Theme = createTheme({
  remessa: {
    palette: {
      primary: {
        main: '#4870F2',
      },
      common: {
        icon: '#0088EE',
        arrowSelect: '#3F51B5',
        title: '#1D3A78',
        white: '#fff',
        headerBorder: '#e5f1f8',
        error: '#c04320',
        logoRemessa: '#0099FF',
        inputText: '#1F3A56',
        registerModalTitle: '#40588d',
        registerModalSubtitle: '#717f9c',
        natureTitle: '#121761',
        natureSubtitle: '#13316d',
        natureBoxShaddow: '#07187e29',
        natureCardText: '#556688',
        corruptedFileModalTitle: '#006ff2',
        corruptedFileModalSubtitle: '#556688',
      },
      gradients: {
        registerButtonGradient: 'linear-gradient(90deg, #009ff5, #126fff)',
      },
      nubank: {
        nuPrimary: '#820ad1',
        nuError: '#C95400',
      },
    },
    breakpoints: {
      sm: '640px',
      lg: '1024px',
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      fontStyle: 'inherit',
    },
  },
})

export const RegisterInputProps = {
  selectInputProps: {
    MenuProps: {
      PaperProps: {
        style: {
          maxHeight: 36 * 5,
          color: Theme.remessa.palette.common.inputText,
        },
      },
    },
    IconComponent: KeyboardArrowDownIcon,
  },
  inputProps: {
    style: {
      color: Theme.remessa.palette.common.inputText,
    },
  },
}

export default Theme
