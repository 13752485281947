import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 346px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  & > .wrapperBox {
    width: calc(100% - 10px);
    border-radius: 0 0 8px 8px;
    height: 336px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);

    & > .header {
      background: #09f;
      color: #fff;
      padding: 24px;
      border-radius: 8px 8px 0 0;
      font-size: 1.6em;
      font-weight: 600;
      letter-spacing: 0.15px;
    }

    & > .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      color: #136;
      font-size: 1.6em;
      font-weight: 400;
      border-bottom: 1px solid #eee;
    }

    & > .information {
      padding: 24px;
      font-size: 1.6em;
      font-weight: 300;
      color: #888;
      line-height: 22px;
      letter-spacing: 0.15px;
    }
  }
`
