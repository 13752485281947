import api from 'api'

interface IgetRemessaMais {
  descriptionInfo: string
  descriptionInfoApp: string
  discount: string
  discountTag: string
  elegible: boolean
  promotionExpiredTime: string
  validity?: string
}

interface IgetMgmInfo {
  discountIncrement: string
  maximumDiscount: string
  validMonthlyPeriod: number
  mgmVoucherDiscount: string
  mgmCurrentDiscount: string
  mgmCode: string
}

const getRemessaMais = async (): Promise<IgetRemessaMais> => {
  try {
    const response = await api.get(`/v2/promotion-remessa-mais-info`)
    if (!response || !response.data || !response.data.discountTag) {
      throw new Error('Resposta inválida da API')
    }

    return response.data
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Erro ao buscar dados de promoção')
  }
}

interface GetMgmInfoParams {
  userId: string | number
}

const getMgmInfo = async ({ userId }: GetMgmInfoParams): Promise<IgetMgmInfo> => {
  try {
    const response = await api.get(`/v2/mgm/config/${userId}`)
    if (!response || !response.data || !response.data.maximumDiscount) {
      throw new Error('Resposta inválida da API')
    }

    return response.data
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Erro ao buscar dados de mgm')
  }
}

interface GetMgmInviteList {
  userId: string | number
  page: number
  limit?: number
}

interface IgetMgmInviteList {
  meta: {
    current_page: number
    items_per_page: number
    total_items: number
    total_pages: number
  }
  data: {
    id: number
    id_customer: number
    invited_id_customer: number
    mgm_code: string
    invited_id_remittance_operation: number
    spread_discount_earned: string
    start_date: string
    end_date: string
    name: string
    email: string
  }[]
}

const getMgmInvitesList = async ({ userId, page, limit = 5 }: GetMgmInviteList): Promise<IgetMgmInviteList> => {
  try {
    const response = await api.get(`/v2/mgm/activated-discounts/${userId}?page=${page}&limit=${limit}`)
    if (!response || !response.data) {
      throw new Error('Resposta inválida da API')
    }

    return response.data
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Erro ao buscar lista de convites mgm')
  }
}

interface InviteFriendList {
  invites: { email: string }[]
}

interface InviteSuccess {
  id: number
  id_customer: number
  name: string
  email: string
  code: string
  created_at: string
  is_active: boolean
  deleted: boolean
  customer_name: string | null
  invited: boolean
}

interface IinviteFriendList {
  data: InviteSuccess[]
}

const InviteFriendsList = async ({ invites }: InviteFriendList): Promise<IinviteFriendList> => {
  try {
    const response = await api.post(`/v2/mgm/invite`, { invites })
    if (!response || !response.data) {
      throw new Error('Resposta inválida da API')
    }

    return response.data
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Erro ao enviar a lista de convites mgm')
  }
}

export default {
  getRemessaMais,
  getMgmInfo,
  getMgmInvitesList,
  InviteFriendsList,
}
