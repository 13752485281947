import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

  @font-face {
  font-family: 'Okta Neue';
  font-weight: 900;
  src: url('https://rods-dictionary.remessaonline.com.br/1.7.0/assets/fonts/OktaNeue-Bold.otf');
  }

  @font-face {
    font-family: 'Okta Neue';
    font-weight: 800;
    src: url('https://rods-dictionary.remessaonline.com.br/1.7.0/assets/fonts/OktaNeue-Bold.otf');
  }

  @font-face {
    font-family: 'Okta Neue';
    font-weight: 700;
    src: url('https://rods-dictionary.remessaonline.com.br/1.7.0/assets/fonts/OktaNeue-Bold.otf');
  }

  @font-face {
    font-family: 'Okta Neue';
    font-weight: 600;
    src: url('https://rods-dictionary.remessaonline.com.br/1.7.0/assets/fonts/OktaNeue-Semibold.otf');
  }

  @font-face {
    font-family: 'Okta Neue';
    font-weight: 500;
    src: url('https://rods-dictionary.remessaonline.com.br/1.7.0/assets/fonts/OktaNeue-Medium.otf');
  }

  @font-face {
    font-family: 'Okta Neue';
    font-weight: 400;
    src: url('https://rods-dictionary.remessaonline.com.br/1.7.0/assets/fonts/OktaNeue-Normal.otf');
  }

  @font-face {
    font-family: 'Okta Neue';
    font-weight: 300;
    src: url('https://rods-dictionary.remessaonline.com.br/1.7.0/assets/fonts/OktaNeue-Light.otf');
  }

  @font-face {
    font-family: 'Okta Neue';
    font-weight: 200;
    src: url('https://rods-dictionary.remessaonline.com.br/1.7.0/assets/fonts/OktaNeue-Light.otf');
  }

  @font-face {
    font-family: 'Okta Neue';
    font-weight: 100;
    src: url('https://rods-dictionary.remessaonline.com.br/1.7.0/assets/fonts/OktaNeue-Light.otf');
  }

  :root {
    --rods-grid-1200-pattern: columns;
    --rods-grid-1200-gutter-size: 24;
    --rods-grid-1200-alignment: stretch;
    --rods-grid-1200-count: 12;
    --rods-grid-1200-offset: 36;
    --rods-grid-1366-pattern: columns;
    --rods-grid-1366-gutter-size: 24;
    --rods-grid-1366-alignment: stretch;
    --rods-grid-1366-count: 12;
    --rods-grid-1366-offset: 120;
    --rods-grid-1440-pattern: columns;
    --rods-grid-1440-gutter-size: 24;
    --rods-grid-1440-alignment: stretch;
    --rods-grid-1440-count: 12;
    --rods-grid-1440-offset: 156;
    --rods-grid-1920-pattern: columns;
    --rods-grid-1920-gutter-size: 24;
    --rods-grid-1920-alignment: stretch;
    --rods-grid-1920-count: 12;
    --rods-grid-1920-offset: 396;
    --rods-grid-mobile-pattern: columns;
    --rods-grid-mobile-gutter-size: 16;
    --rods-grid-mobile-alignment: stretch;
    --rods-grid-mobile-count: 4;
    --rods-grid-mobile-offset: 32;
    --rods-shadow-1: 0px 1px 3px 0px #747d8614,0px 1px 1px 0px #747d8614,0px 2px 1px -1px #747d8633;
    --rods-shadow-2: 0px 1px 5px 0px #747d8614,0px 2px 2px 0px #747d8614,0px 3px 1px -2px #747d8633;
    --rods-shadow-3: 0px 1px 8px 0px #747d8614,0px 3px 4px 0px #747d8614,0px 3px 3px -2px #747d8633;
    --rods-shadow-4: 0px 1px 10px 0px #747d8614,0px 4px 5px 0px #747d8614,0px 2px 4px -1px #747d8633;
    --rods-shadow-5: 0px 1px 10px 0px #747d8614,0px 4px 5px 0px #747d8614,0px 2px 4px -1px #747d8633;
    --rods-shadow-6: 0px 1px 10px 0px #747d8614,0px 4px 5px 0px #747d8614,0px 2px 4px -1px #747d8633;
    --rods-shadow-7: 0px 2px 16px 1px #747d8614,0px 7px 10px 1px #747d8614,0px 4px 5px -2px #747d8633;
    --rods-shadow-8: 0px 3px 14px 2px #747d8614,0px 8px 10px 1px #747d8614,0px 5px 5px -3px #747d8633;
    --rods-shadow-9: 0px 4px 18px 3px #747d8614,0px 10px 14px 1px #747d8614,0px 6px 6px -3px #747d8633;
    --rods-shadow-10: 0px 4px 18px 3px #747d8614,0px 10px 14px 1px #747d8614,0px 6px 6px -3px #747d8633;
    --rods-shadow-11: 0px 4px 18px 3px #747d8614,0px 10px 14px 1px #747d8614,0px 6px 6px -3px #747d8633;
    --rods-shadow-13: 0px 5px 24px 4px #747d8614,0px 13px 19px 2px #747d8614,0px 7px 8px -4px #747d8633;
    --rods-shadow-14: 0px 5px 26px 4px #747d8614,0px 14px 21px 2px #747d8614,0px 7px 9px -4px #747d8633;
    --rods-shadow-15: 0px 6px 32px 5px #747d8614,0px 17px 26px 2px #747d8614,0px 8px 11px -5px #747d8633;
    --rods-shadow-17: 0px 6px 32px 5px #747d8614,0px 17px 26px 2px #747d8614,0px 8px 11px -5px #747d8633;
    --rods-shadow-18: 0px 7px 36px 6px #747d8614,0px 19px 29px 2px #747d8614,0px 9px 12px -6px #747d8633;
    --rods-shadow-19: 0px 7px 36px 6px #747d8614,0px 19px 29px 2px #747d8614,0px 9px 12px -6px #747d8633;
    --rods-shadow-20: 0px 8px 38px 7px #747d8614,0px 20px 31px 3px #747d8614,0px 10px 13px -6px #747d8633;
    --rods-shadow-21: 0px 8px 40px 7px #747d8619,0px 21px 33px 3px #747d8619,0px 10px 13px -6px #747d8633;
    --rods-shadow-22: 0px 8px 42px 7px #747d8614,0px 22px 35px 3px #747d8614,0px 10px 14px -6px #747d8633;
    --rods-shadow-23: 0px 8px 42px 7px #747d8614,0px 22px 35px 3px #747d8614,0px 10px 14px -6px #747d8633;
    --rods-elevation-12: 0px 5px 22px 4px #747d8614,0px 12px 17px 2px #747d8614,0px 7px 8px -4px #747d8633;
    --rods-elevation-16: 0px 6px 30px 5px #747d8614,0px 16px 24px 2px #747d8614,0px 8px 10px -5px #747d8633;
    --rods-elevation-24: 0px 6px 30px 5px #747d8614,0px 16px 24px 2px #747d8614,0px 8px 10px -5px #747d8626;
    --rods-color-white: #ffffffff;
    --rods-color-black: #000000ff;
    --rods-color-background: #F5F5F5ff;
    --rods-color-border: #E0E0E0ff;
    --rods-color-success-main: #24a148ff;
    --rods-color-success-light: #a7ecbbff;
    --rods-color-success-dark: #124f23ff;
    --rods-color-success-semi-light: #92d0a3ff;
    --rods-color-success-lighter: #caf4d6ff;
    --rods-color-warning-main: #f1c21bff;
    --rods-color-warning-light: #fbf0c5ff;
    --rods-color-warning-dark: #876b08ff;
    --rods-color-warning-semi-light: #f8e08dff;
    --rods-color-warning-lighter: #fdf6dcff;
    --rods-color-error-main: #da1e28ff;
    --rods-color-error-light: #f5b3b6ff;
    --rods-color-error-dark: #700f15ff;
    --rods-color-error-lighter: #f9d1d3ff;
    --rods-color-error-semi-light: #ed8e94ff;
    --rods-color-grayscale-lighter: #F5F5F5FF;
    --rods-color-grayscale-light: #E0E0E0FF;
    --rods-color-grayscale-semi-light: #B8B8B8FF;
    --rods-color-grayscale-main: #666666ff;
    --rods-color-grayscale-dark: #292929ff;
    --rods-color-text-primary: #292929ff;
    --rods-color-text-secondary: #666666ff;
    --rods-color-dark: #00137AFF;
    --rods-color-main: #1F41FFFF;
    --rods-color-semi-light: #7086FFFF;
    --rods-color-light: #C2CBFFFF;
    --rods-color-lighter: #EBEEFFFF;
    --rods-font-h1-size: 96;
    --rods-font-h1-text-decoration: none;
    --rods-font-h1-family: 'OktaNeue';
    --rods-font-h1-weight: 700;
    --rods-font-h1-style: normal;
    --rods-font-h1-stretch: normal;
    --rods-font-h1-font-style-old: Bold;
    --rods-font-h1-letter-spacing: 0;
    --rods-font-h1-line-height: 104;
    --rods-font-h1-paragraph-indent: 0;
    --rods-font-h1-paragraph-spacing: 0;
    --rods-font-h1-text-transform: none;
    --rods-font-h2-size: 60;
    --rods-font-h2-text-decoration: none;
    --rods-font-h2-family: 'OktaNeue';
    --rods-font-h2-weight: 700;
    --rods-font-h2-style: normal;
    --rods-font-h2-stretch: normal;
    --rods-font-h2-font-style-old: Bold;
    --rods-font-h2-letter-spacing: 0;
    --rods-font-h2-line-height: 72;
    --rods-font-h2-paragraph-indent: 0;
    --rods-font-h2-paragraph-spacing: 0;
    --rods-font-h2-text-transform: none;
    --rods-font-h3-size: 48;
    --rods-font-h3-text-decoration: none;
    --rods-font-h3-family: 'OktaNeue';
    --rods-font-h3-weight: 700;
    --rods-font-h3-style: normal;
    --rods-font-h3-stretch: normal;
    --rods-font-h3-font-style-old: Bold;
    --rods-font-h3-letter-spacing: 0;
    --rods-font-h3-line-height: 60;
    --rods-font-h3-paragraph-indent: 0;
    --rods-font-h3-paragraph-spacing: 0;
    --rods-font-h3-text-transform: none;
    --rods-font-h4-size: 32;
    --rods-font-h4-text-decoration: none;
    --rods-font-h4-family: 'OktaNeue';
    --rods-font-h4-weight: 700;
    --rods-font-h4-style: normal;
    --rods-font-h4-stretch: normal;
    --rods-font-h4-font-style-old: Bold;
    --rods-font-h4-letter-spacing: 0;
    --rods-font-h4-line-height: 40;
    --rods-font-h4-paragraph-indent: 0;
    --rods-font-h4-paragraph-spacing: 0;
    --rods-font-h4-text-transform: none;
    --rods-font-h5-size: 24;
    --rods-font-h5-text-decoration: none;
    --rods-font-h5-family: 'OktaNeue';
    --rods-font-h5-weight: 700;
    --rods-font-h5-style: normal;
    --rods-font-h5-stretch: normal;
    --rods-font-h5-font-style-old: Bold;
    --rods-font-h5-letter-spacing: 0;
    --rods-font-h5-line-height: 32;
    --rods-font-h5-paragraph-indent: 0;
    --rods-font-h5-paragraph-spacing: 0;
    --rods-font-h5-text-transform: none;
    --rods-font-h6-size: 20;
    --rods-font-h6-text-decoration: none;
    --rods-font-h6-family: 'OktaNeue';
    --rods-font-h6-weight: 700;
    --rods-font-h6-style: normal;
    --rods-font-h6-stretch: normal;
    --rods-font-h6-font-style-old: Bold;
    --rods-font-h6-letter-spacing: 0;
    --rods-font-h6-line-height: 28;
    --rods-font-h6-paragraph-indent: 0;
    --rods-font-h6-paragraph-spacing: 0;
    --rods-font-h6-text-transform: none;
    --rods-font-button-size: 14;
    --rods-font-button-text-decoration: none;
    --rods-font-button-family: 'OktaNeue';
    --rods-font-button-weight: 600;
    --rods-font-button-style: normal;
    --rods-font-button-stretch: normal;
    --rods-font-button-font-style-old: SemiBold;
    --rods-font-button-letter-spacing: 0.4;
    --rods-font-button-line-height: 18;
    --rods-font-button-paragraph-indent: 0;
    --rods-font-button-paragraph-spacing: 0;
    --rods-font-button-text-transform: none;
    --rods-font-caption-size: 12;
    --rods-font-caption-text-decoration: none;
    --rods-font-caption-family: 'OktaNeue';
    --rods-font-caption-weight: 500;
    --rods-font-caption-style: normal;
    --rods-font-caption-stretch: normal;
    --rods-font-caption-font-style-old: Medium;
    --rods-font-caption-letter-spacing: 0;
    --rods-font-caption-line-height: 20;
    --rods-font-caption-paragraph-indent: 0;
    --rods-font-caption-paragraph-spacing: 0;
    --rods-font-caption-text-transform: none;
    --rods-font-subtitle1-size: 16;
    --rods-font-subtitle1-text-decoration: none;
    --rods-font-subtitle1-family: 'OktaNeue';
    --rods-font-subtitle1-weight: 700;
    --rods-font-subtitle1-style: normal;
    --rods-font-subtitle1-stretch: normal;
    --rods-font-subtitle1-font-style-old: Bold;
    --rods-font-subtitle1-letter-spacing: 0.15;
    --rods-font-subtitle1-line-height: 24;
    --rods-font-subtitle1-paragraph-indent: 0;
    --rods-font-subtitle1-paragraph-spacing: 0;
    --rods-font-subtitle1-text-transform: none;
    --rods-font-subtitle2-size: 14;
    --rods-font-subtitle2-text-decoration: none;
    --rods-font-subtitle2-family: 'OktaNeue';
    --rods-font-subtitle2-weight: 700;
    --rods-font-subtitle2-style: normal;
    --rods-font-subtitle2-stretch: normal;
    --rods-font-subtitle2-font-style-old: Bold;
    --rods-font-subtitle2-letter-spacing: 0.1;
    --rods-font-subtitle2-line-height: 22;
    --rods-font-subtitle2-paragraph-indent: 0;
    --rods-font-subtitle2-paragraph-spacing: 0;
    --rods-font-subtitle2-text-transform: none;
    --rods-font-overline-size: 12;
    --rods-font-overline-text-decoration: none;
    --rods-font-overline-family: 'OktaNeue';
    --rods-font-overline-weight: 600;
    --rods-font-overline-style: normal;
    --rods-font-overline-stretch: normal;
    --rods-font-overline-font-style-old: SemiBold;
    --rods-font-overline-letter-spacing: 1;
    --rods-font-overline-line-height: 20;
    --rods-font-overline-paragraph-indent: 0;
    --rods-font-overline-paragraph-spacing: 0;
    --rods-font-overline-text-transform: uppercase;
    --rods-font-body1-size: 16;
    --rods-font-body1-text-decoration: none;
    --rods-font-body1-family: 'OktaNeue';
    --rods-font-body1-weight: 500;
    --rods-font-body1-style: normal;
    --rods-font-body1-stretch: normal;
    --rods-font-body1-font-style-old: Medium;
    --rods-font-body1-letter-spacing: 0.2;
    --rods-font-body1-line-height: 24;
    --rods-font-body1-paragraph-indent: 0;
    --rods-font-body1-paragraph-spacing: 0;
    --rods-font-body1-text-transform: none;
    --rods-font-body1-bold-size: 16;
    --rods-font-body1-bold-text-decoration: none;
    --rods-font-body1-bold-family: 'OktaNeue';
    --rods-font-body1-bold-weight: 700;
    --rods-font-body1-bold-style: normal;
    --rods-font-body1-bold-stretch: normal;
    --rods-font-body1-bold-font-style-old: Bold;
    --rods-font-body1-bold-letter-spacing: 0.2;
    --rods-font-body1-bold-line-height: 24;
    --rods-font-body1-bold-paragraph-indent: 0;
    --rods-font-body1-bold-paragraph-spacing: 0;
    --rods-font-body1-bold-text-transform: none;
    --rods-font-body2-size: 14;
    --rods-font-body2-text-decoration: none;
    --rods-font-body2-family: 'OktaNeue';
    --rods-font-body2-weight: 500;
    --rods-font-body2-style: normal;
    --rods-font-body2-stretch: normal;
    --rods-font-body2-font-style-old: Medium;
    --rods-font-body2-letter-spacing: 0.2;
    --rods-font-body2-line-height: 22;
    --rods-font-body2-paragraph-indent: 0;
    --rods-font-body2-paragraph-spacing: 0;
    --rods-font-body2-text-transform: none;
    --rods-font-body2-bold-size: 14;
    --rods-font-body2-bold-text-decoration: none;
    --rods-font-body2-bold-family: 'OktaNeue';
    --rods-font-body2-bold-weight: 700;
    --rods-font-body2-bold-style: normal;
    --rods-font-body2-bold-stretch: normal;
    --rods-font-body2-bold-font-style-old: Bold;
    --rods-font-body2-bold-letter-spacing: 0.2;
    --rods-font-body2-bold-line-height: 22;
    --rods-font-body2-bold-paragraph-indent: 0;
    --rods-font-body2-bold-paragraph-spacing: 0;
    --rods-font-body2-bold-text-transform: none;
    --rods-opacity-0: 0;
    --rods-opacity-1: 0.8;
    --rods-opacity-2: 0.6;
    --rods-opacity-3: 0.4;
    --rods-opacity-4: 0.2;
    --rods-size-border-radius-none: 0;
    --rods-size-border-radius-small: 8;
    --rods-size-border-radius-regular: 16;
    --rods-size-border-radius-large: 32;
    --rods-size-border-radius-extra-large: 48;
    --rods-size-border-radius-pill: 50;
    --rods-size-border-width-none: 0;
    --rods-size-border-width-light: 1;
    --rods-size-border-width-regular: 2;
    --rods-size-border-width-medium: 4;
    --rods-size-border-width-large: 8;
    --rods-size-margin-extra-small: 8;
    --rods-size-margin-small: 16;
    --rods-size-margin-regular: 24;
    --rods-size-margin-medium: 32;
    --rods-size-margin-large: 48;
    --rods-size-margin-extra-large: 64;
    --rods-size-padding-extra-small: 8;
    --rods-size-padding-small: 16;
    --rods-size-padding-regular: 24;
    --rods-size-padding-medium: 32;
    --rods-size-padding-large: 40;
    --rods-size-padding-extra-large: 64;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body, button {
    font-family: "Open Sans", -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Helvetica, Arial, sans-serif;
    -webkit-font-feature-settings: "kern" 1, "liga" 1;
    font-feature-settings: "kern" 1, "liga" 1;
    line-height: 1.5;
    font-size: 62.5%
  }
`
