import UploadBox from 'components/OnBoarding/RegisterDocument/UploadBox'
import { useContext, useEffect, useState } from 'react'
import Context from 'components/OnBoarding/context'
import LegacyButton from 'components/UI/LegacyButton'
import Paragraph from 'components/UI/Typography/Paragraph'
import ButtonLink from 'components/UI/ButtonLink'
import tracker from 'utils/tracker'
import gtmTracker from 'utils/gtmTracker'
import { PageContainer } from '../../style'
import unleash from '../../../../../services/Unleash/unleash.service'

const UploadFrontDocument = () => {
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)
  const [shouldShowPassportUpload, setshouldShowPassportUpload] = useState<boolean>(false)
  const context = useContext(Context)

  const isPassport = context.documentType === 'PASSPORT'

  const handleContinueButton = () => {
    if (isPassport) {
      tracker({ category: 'account_pf' }).click({
        label: 'send_documents_button',
      })
      context.sendDocument()
      return
    }
    context.setPage(context.page + 1)
    tracker({ category: 'account_pf' }).click({
      label: 'continue_button',
    })
  }

  const handleSendLaterButton = () => {
    context.sendPostMessage({
      type: 'data',
      data: 'skipUpload',
    })
    tracker({ category: 'account_pf' }).click({
      label: 'send_later_button',
    })

    const interaction = {
      type: 'button',
      target: 'signup',
      label: 'enviar-documento-depois',
    }

    const eventPage = {
      page: 'app/criar-conta',
      flow_type: 'individual',
      flow_type_tier1: 'signup',
    }

    gtmTracker({ event: 'internal_click', interaction, page: eventPage })
  }

  useEffect(() => {
    const getFeaturePassport = async () => {
      const feature = await unleash.getFeatureData('ro_onboarding-upload-passport', {})
      setshouldShowPassportUpload(feature.isEnabled)
    }
    getFeaturePassport()
    tracker({ category: 'account_pf' }).pageView({
      label: 'upload_front_document_page',
    })

    const interaction = {
      type: 'section',
      target: 'signup',
      label: 'front-document',
    }

    const eventPage = {
      page: 'app/criar-conta',
      flow_type: 'individual',
      flow_type_tier1: 'signup',
    }

    gtmTracker({ event: 'view', interaction, page: eventPage })
  }, [])

  const stepDescription = () => {
    if (!isPassport) {
      return (
        <Paragraph fontSize={16} fontWeight={700} marginTop={0}>
          Envie a foto da frente do seu {context.documentType?.toUpperCase()}
        </Paragraph>
      )
    }

    return (
      <Paragraph fontSize={16} fontWeight={400} marginTop={0}>
        <b>Envie a foto do seu passaporte brasileiro.</b> Ela precisa mostrar os seus dados de identificação e o
        documento deve estar dentro do prazo de validade.
      </Paragraph>
    )
  }

  return (
    <PageContainer>
      <div className="box">
        <div data-testid="uploadFrontInfo" className="information">
          <span className="number">2</span>
          {stepDescription()}
        </div>

        <UploadBox
          setUploadSuccess={setUploadSuccess}
          documentSide="Frente"
          document={context.frontDocument}
          setDocument={context.setFrontDocument}
          onClick={() => {
            tracker({
              category: 'account_pf',
              eventProperties: {
                'A/B_Test_Name': 'onboarding_document_upload_new_flow',
                'A/B_Test_Group': 'Variant_B',
                'A/B_Test_Passport_Name': 'ro_onboarding-upload-passport',
                'A/B_Test_Passport_is_active': shouldShowPassportUpload,
                side: 'front',
              },
            }).click({
              label: 'input_upload_document',
            })

            const interaction = {
              type: 'button',
              target: 'signup-front-document',
              label: 'adicionar-frente-documento',
            }

            const eventPage = {
              page: 'app/criar-conta',
              flow_type: 'individual',
              flow_type_tier1: 'signup',
            }

            gtmTracker({ event: 'internal_click', interaction, page: eventPage })
          }}
          onClickChangeFile={(error: boolean) => {
            tracker({
              category: 'account_pf',
              eventProperties: {
                'A/B_Test_Name': 'onboarding_document_upload_new_flow',
                'A/B_Test_Group': 'Variant_B',
                'A/B_Test_Passport_Name': 'ro_onboarding-upload-passport',
                'A/B_Test_Passport_is_active': shouldShowPassportUpload,
                side: 'front',
                error: error ? 'yes' : 'no',
              },
            }).click({
              label: 'change_file_document',
            })

            const interaction = {
              type: 'button',
              target: 'signup-front-document',
              label: 'alterar-frente-documento',
            }

            const eventPage = {
              page: 'app/criar-conta',
              flow_type: 'individual',
              flow_type_tier1: 'signup',
            }

            gtmTracker({ event: 'internal_click', interaction, page: eventPage })
          }}
          setImageType={context.setFrontImageType}
        />

        <LegacyButton
          data-testid="buttonCtaInformation"
          disabled={!uploadSuccess}
          loading={context.loading}
          onClick={() => handleContinueButton()}
        >
          {!isPassport ? 'Continuar' : 'Enviar Documento'}
        </LegacyButton>
        <div className="sendLater">
          <ButtonLink onClick={() => handleSendLaterButton()}>Enviar depois</ButtonLink>
        </div>
      </div>
    </PageContainer>
  )
}

export default UploadFrontDocument
