import styled from 'styled-components'

const Container = styled.span`
  border-top: 0;
  text-transform: none;
  height: auto;
  width: auto;
  padding: 4px 16px;
  border-radius: 64px;
  background: #a7ecbb;
  color: #124f23;
  font-weight: 600;
  font-size: 12px;
`
export default function Badge({ children, styles }: any) {
  return <Container style={styles}>{children}</Container>
}
