import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'

export const ContentContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin: 0.5em 0 1em 0;

  @media screen and (min-width: ${({ theme }) => theme.remessa.breakpoints.sm}) {
    gap: 2em;
    margin-top: 2em;
  }
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.remessa.palette.common.registerModalTitle};
  font-size: 24px;
`

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.remessa.palette.common.registerModalSubtitle};
  font-size: 16px;

  @media screen and (min-width: ${({ theme }) => theme.remessa.breakpoints.sm}) {
    width: 75%;
    padding-top: 0.6em;
  }
`
