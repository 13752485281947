import { Container } from './styles'

interface ITitleSubtitle {
  title: string
  subtitle: string
}

export default function TitleSubtitle({ title, subtitle }: ITitleSubtitle) {
  return (
    <Container>
      <h3>{title}</h3>
      <p>{subtitle}</p>
    </Container>
  )
}
