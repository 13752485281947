import { useState, useEffect } from 'react'
import { FileWithPath } from 'react-dropzone'
import uploadDocument from 'services/OnBoarding/UploadDocument'
import uploadDoubleSidedDocument from 'services/OnBoarding/UploadDoubleSidedDocument'
import usePostMessage from 'hooks/usePostMessage'
import Title from 'components/UI/Typography/Title'
import tracker from 'utils/tracker'
import { ScopedCssBaseline } from '@mui/material'
import Context from '../context'
import OnBoardingRegisterSelectDocument from './SelectDocument'
import OnBoardingRegisterDocumentInformation from './Information'
import OnBoardingUploadFront from './UploadBox/UploadFront'
import OnBoardingUploadVerse from './UploadBox/UploadVerse'
import { Container } from './style'
import { RequestStatus } from '../../../enums/requestStatus'

const pdfType = 'application/pdf'
interface ComponentProps {
  userToken?: string
}

const OnBoardingRegisterDocument = ({ userToken }: ComponentProps) => {
  const [page, setPage] = useState(1)
  const [documentType, setDocumentType] = useState<string | undefined>()
  const [frontImageType, setFrontImageType] = useState<string>()
  const [verseImageType, setVerseImageType] = useState<string>()
  const [frontDocument, setFrontDocument] = useState<FileWithPath | undefined>()
  const [verseDocument, setVerseDocument] = useState<FileWithPath | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [state, setState] = useState<ComponentProps>({
    userToken,
  })
  const [countErrorFile, setCountErrorFile] = useState(0)

  const postMessageHook = usePostMessage('OnboardingDocument', setState)

  useEffect(() => {
    if (state.userToken) {
      localStorage.setItem('beero.customerToken', state.userToken)
    }
  }, [state.userToken])

  const handleBackButton = () => {
    tracker({ category: 'account_pf' }).click({
      label: 'go_back_button',
    })
    setPage(page - 1)
  }

  const defineDocumentToSend = () => {
    const hasAnyPdfImageType = frontImageType === pdfType || verseImageType === pdfType

    if (hasAnyPdfImageType && documentType === 'RG') {
      return verseDocument
    }

    if (hasAnyPdfImageType && (documentType === 'CNH' || documentType === 'RNE' || documentType === 'PASSPORT')) {
      return frontDocument
    }

    return null
  }

  const resetDocument = () => {
    setFrontDocument(undefined)
    setVerseDocument(undefined)
  }

  const countErrorDocumentCorrupted = () => {
    setCountErrorFile(countErrorFile + 1)
  }

  const setToPreviousPage = () => {
    setPage(page - 1)
  }

  const triggerPostMessageSuccess = () => {
    postMessageHook.sendMessage({
      type: 'data',
      data: 'documentUpload',
    })
  }

  const triggerPostMessageGenericError = () => {
    postMessageHook.sendMessage({
      type: 'data',
      data: 'documentUploadError',
    })
  }

  const triggerPostMessageCorruptedError = () => {
    postMessageHook.sendMessage({
      type: 'data',
      data: 'documentCorruptedError',
      properties: {
        countErrorFile,
      },
    })
  }

  const sendDocument = async () => {
    setLoading(true)
    const hasOnlyImageType = frontImageType?.includes('image') && verseImageType?.includes('image')
    const isImageType = frontImageType?.includes('image')
    const isDoubleSidedDocument = documentType !== 'PASSPORT'

    if (hasOnlyImageType && isDoubleSidedDocument) {
      if (!documentType || !state.userToken || !frontDocument || !verseDocument) {
        setLoading(false)
        return
      }

      const requestStatus = await uploadDoubleSidedDocument(documentType, frontDocument, verseDocument, state.userToken)

      if (requestStatus === RequestStatus.success) {
        triggerPostMessageSuccess()
      } else if (requestStatus === RequestStatus.documentCorruptedError) {
        resetDocument()
        countErrorDocumentCorrupted()
        setToPreviousPage()
        triggerPostMessageCorruptedError()
      } else {
        triggerPostMessageGenericError()
      }
    } else if (isImageType && !isDoubleSidedDocument) {
      if (!documentType || !state.userToken || !frontDocument) {
        setLoading(false)

        return
      }

      const requestStatus = await uploadDocument(documentType, frontDocument, state.userToken)

      if (requestStatus === RequestStatus.success) {
        triggerPostMessageSuccess()
      } else if (requestStatus === RequestStatus.documentCorruptedError) {
        resetDocument()
        countErrorDocumentCorrupted()
        triggerPostMessageCorruptedError()
      } else {
        triggerPostMessageGenericError()
      }
    } else {
      const documentFile = defineDocumentToSend()

      if (!documentType || !documentFile || !state.userToken) {
        triggerPostMessageGenericError()

        return
      }

      const requestStatus = await uploadDocument(documentType, documentFile, state.userToken)

      if (requestStatus === RequestStatus.success) {
        triggerPostMessageSuccess()
      } else {
        triggerPostMessageGenericError()
      }
    }

    setLoading(false)
  }

  const contextValues = {
    userToken: state.userToken,
    loading,
    page,
    setPage,
    documentType,
    setDocumentType,
    frontDocument,
    setFrontDocument,
    verseDocument,
    setVerseDocument,
    sendDocument,
    sendPostMessage: postMessageHook.sendMessage,
    setLoading,
    setFrontImageType,
    setVerseImageType,
    frontImageType,
    verseImageType,
  }

  return (
    <ScopedCssBaseline>
      <Context.Provider value={contextValues}>
        <Container width={postMessageHook.width}>
          <div className="sendDocument">
            {page > 1 && (
              <button type="button" onClick={() => handleBackButton()} className="backButton">
                <img src="/img/remessa-icons/back.svg" alt="back" />
                <span className="backButtonLabel">Voltar</span>
              </button>
            )}
            <Title fontSize={24} fontWeight={300}>
              Documento
            </Title>
          </div>
          {page === 1 && <OnBoardingRegisterDocumentInformation />}
          {page === 2 && <OnBoardingRegisterSelectDocument />}
          {page === 3 && <OnBoardingUploadFront />}
          {page === 4 && <OnBoardingUploadVerse />}
        </Container>
      </Context.Provider>
    </ScopedCssBaseline>
  )
}

export default OnBoardingRegisterDocument
