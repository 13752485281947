import { styled } from '@mui/material'

interface IButtonContainer {
  link?: boolean
}

export const CepContainer = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .circular-progress {
    position: absolute;
    right: 10px;
    top: 40px;
  }
`
export const StreetAndNumberContainer = styled('div')`
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    & > div {
      width: 70%;
    }
    & > div:last-child {
      width: 30%;
    }
  }
`
export const ButtonContainer = styled('div')<IButtonContainer>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 3em;
  margin: 2.8em 0 0.8em 0;

  ${(props) => props.link && `margin-top: 0;`}

  @media only screen and (min-width: 1024px) {
    width: 62%;
  }
`
