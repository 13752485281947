import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'

export const ContentContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6em;

  @media screen and (min-width: ${({ theme }) => theme.remessa.breakpoints.sm}) {
    margin-bottom: 0.5em;
  }
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.remessa.palette.common.corruptedFileModalTitle};
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.2px;
`
export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.remessa.palette.common.corruptedFileModalSubtitle};
  font-size: 16px;
  line-height: 24px;
`
export const IconContainer = styled('div')`
  display: flex;
  gap: 1.1em;
`
