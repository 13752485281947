import styled from 'styled-components'

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 0.5em;
`
const LeftHeader = styled.span`
  display: flex;
  align-items: center;
`
const HeaderFlag = styled.span`
  & img {
    width: 32px;
  }
`
const HeaderTitle = styled.h2`
  margin: 0;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #568;
  font-size: 12px;
`
const HeaderTitleIcon = styled.span`
  margin: 0 8px;
`
const HeaderDelete = styled.span`
  position: absolute;
  right: 0%;
`
interface HistoryHeaderProps {
  title: string
  icon: JSX.Element
  flag?: JSX.Element
  onDeleteClick: any
}
export default function HistoryHeader({ title, icon, flag, onDeleteClick }: HistoryHeaderProps) {
  return (
    <Header>
      <LeftHeader>
        <HeaderFlag>{flag}</HeaderFlag>
        <HeaderTitleIcon>{icon}</HeaderTitleIcon>
        <HeaderTitle>{title}</HeaderTitle>
      </LeftHeader>

      <HeaderDelete onClick={(e) => onDeleteClick(e)}>
        <img height={14} width={12} src="/img/remessa-icons/trash.svg" alt="icone de lixeira" />
      </HeaderDelete>
    </Header>
  )
}
