import styled from 'styled-components'

export const Container = styled.section<{ elegible: boolean }>`
  display: flex;
  height: max-content;
  flex-direction: column;
  margin: 40px 0;
  color: var(--rods-color-text-primary);
  font-family: 'Okta Neue';
  gap: 16px;

  & > h1 {
    color: var(--rods-color-text-primary);
    font-family: 'Okta Neue';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  & > .tagContainer {
    display: flex;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 64px;
    width: fit-content;
    background: ${({ elegible }) => (elegible ? 'var(--rods-color-success-light)' : 'var(--rods-color-warning-light)')};

    & > .tagText {
      color: ${({ elegible }) => (elegible ? 'var(--rods-color-success-dark)' : 'var(--rods-color-text-primary)')};
      text-align: center;
      font-family: 'Okta Neue';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  & > .description {
    color: var(--rods-color-text-secondary);
    font-family: 'Okta Neue';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    width: 288px;
  }
`
