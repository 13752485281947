import styled from 'styled-components'

export const InformationContainer = styled.section`
  width: 100%;

  & > .box {
    flex-direction: row;
    background: #ffffff;
    padding: 16px 32px 32px 32px;
    justify-content: center;
    display: block;
    align-items: center;

    @media (max-device-width: 721px) {
      flex-direction: column;
    }

    & > .title {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: #121761;
      font-weight: 600;

      @media (max-device-width: 768px) {
        font-size: 16px;
      }
    }

    & > .steps {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;

      @media (max-device-width: 721px) {
        flex-direction: column;
      }

      & > span {
        font-size: 16px;
        font-weight: 600;
        color: #121761;
        margin-bottom: 8px;

        &:first-child {
          color: #747d86;
        }

        @media (max-device-width: 768px) {
          margin-bottom: 8px;
          font-size: 14px;
        }
      }
    }
  }
`
