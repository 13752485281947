import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-family: 'Okta Neue', sans-serif;
  color: var(--rods-color-text-primary);

  .titleContainer {
    width: 100%;
    padding: 16px 0;

    h1 {
      font-size: 20px;
      font-weight: 600;
      color: var(--rods-color-text-primary);
    }
  }

  .invitesTableContainer {
    width: 100%;
    background: var(--rods-color-white);
    box-shadow: 0px 8px 10px -5px rgba(116, 125, 134, 0.15), 0px 16px 24px 2px rgba(116, 125, 134, 0.08),
      0px 6px 30px 5px rgba(116, 125, 134, 0.08);
    border-radius: 8px;
    padding: 16px;
    overflow-x: auto;

    table {
      width: 100%;
      border-spacing: 0;
      min-width: 800px;

      thead {
        background-color: var(--rods-color-white);
        box-shadow: 0px -1px 0px 0px var(--rods-color-border) inset;

        th {
          padding: 16px;
          font-size: 14px;
          font-weight: 700;
          color: var(--rods-color-text-primary);
          text-align: left;
        }
      }

      tbody {
        box-shadow: 0px -1px 0px 0px var(--rods-color-border) inset;

        td {
          padding: 16px;
          font-size: 14px;
          font-weight: 600;
          color: var(--rods-color-text-secondary);
          text-align: left;
        }
      }

      .discountColumn,
      .expirationColumn {
        width: auto;
        text-align: right;
      }

      .indications {
        width: 70%;
      }
    }

    .paginationContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-top: 20px;
      gap: 26px;

      .resultsPerPage {
        display: flex;
        align-items: center;
        gap: 8px;

        label {
          font-size: 12px;
          color: var(--rods-color-text-secondary);
          font-weight: 600;
        }

        select {
          border: none;
          background-color: var(--rods-color-white);
          height: 24px;
          font-size: 12px;
          color: var(--rods-color-text-secondary);
          outline: none;
          box-shadow: none;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background: url('/img/discounts-page/icons/arrow-dropdown.svg') no-repeat right center;
          background-size: 24px;
          padding-right: 20px;

          &:focus,
          &:active,
          &:focus-visible {
            border: none;
            box-shadow: none;
            outline: none;
          }

          &::-ms-expand {
            display: none;
          }
        }
      }

      .paginationControls {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 26px;

        button {
          background: transparent;
          border: none;
          padding: 0;
          cursor: pointer;

          &:disabled {
            cursor: not-allowed;
          }

          img {
            display: block;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
`
