import { Zoom, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainerStyled } from './styles'

interface IToast {
  errorMessage: string
}

export const ToastMessage = ({ errorMessage }: IToast) => {
  toast(errorMessage, {
    toastId: 'toast',
  })

  return (
    <ToastContainerStyled
      position="top-center"
      autoClose={10000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      transition={Zoom}
      icon={false}
    />
  )
}
