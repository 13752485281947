import { useContext, useState } from 'react'
import { RegisterContext } from 'context/register'
import tracker from 'utils/tracker'
import Title from 'components/UI/Typography/Title'
import OnBoardingRegisterDocumentWelcome from 'components/Register/SendDocument/Welcome'
import OnBoardingRegisterSelectDocument from 'components/Register/SendDocument/SelectDocument'
import OnBoardingRegisterDocumentInformation from 'components/Register/SendDocument/Information'
import TemplateRegister from 'components/Templates/TemplateRegister'
import OnBoardingUploadFront from 'components/Register/SendDocument/UploadBox/UploadFront'
import OnBoardingUploadVerse from 'components/Register/SendDocument/UploadBox/UploadVerse'
import SendDocumentModal from 'components/UI/Modals/SendDocumentModal'
import CorruptedFileModal from 'components/UI/Modals/CorruptedFileModal'
import { Container } from './style'

export default function SendDocumentPage() {
  const { page, setPage, openCorruptedFileModal, setOpenCorruptedFileModal } = useContext(RegisterContext)
  const [open, setOpen] = useState(false)

  const handleClick = async () => {
    setOpen((prev) => !prev)
  }

  const handleClickCorruptedFileModal = async () => {
    setOpenCorruptedFileModal(false)
  }

  const handleBackButton = () => {
    tracker({ category: 'account_pf' }).click({
      label: 'go_back_button',
    })
    setPage(page - 1)
  }

  return (
    <TemplateRegister handleCloseIcon={handleClick}>
      {open && <SendDocumentModal show={open} handleState={handleClick} />}
      {openCorruptedFileModal && (
        <CorruptedFileModal show={openCorruptedFileModal} firstButtonFunction={handleClickCorruptedFileModal} />
      )}
      <Container>
        <div className="sendDocument">
          {page > 1 && (
            <button type="button" onClick={() => handleBackButton()} className="backButton">
              <img src="/img/remessa-icons/back.svg" alt="back" />
              <span className="backButtonLabel">Voltar</span>
            </button>
          )}
          <Title fontSize={24} fontWeight={300}>
            Documento
          </Title>
        </div>
        {page === 1 && <OnBoardingRegisterDocumentWelcome />}
        {page === 2 && <OnBoardingRegisterSelectDocument />}
        {page === 3 && <OnBoardingRegisterDocumentInformation />}
        {page === 4 && <OnBoardingUploadFront />}
        {page === 5 && <OnBoardingUploadVerse />}
      </Container>
    </TemplateRegister>
  )
}
