import { Container } from './style'

interface RemessaMaisRulesProps {
  rule1: string
  rule2: string
}

const Rules = ({ rule1, rule2 }: RemessaMaisRulesProps) => {
  return (
    <Container>
      <div className="titleContainer">
        <h1>Regras</h1>
      </div>
      <div className="rules">
        <div className="rule">
          <p>{rule1}</p>
        </div>
        <div className="rule">
          <p>{rule2}</p>
        </div>
      </div>
    </Container>
  )
}

export default Rules
