import ShareMgm from '../../../DiscountsPage/Mgm/share'
import { Container } from './style'

interface BannerProps {
  title: string
  description: string
  image: string
  mgmCode?: string
  mgmVoucherDiscount?: string
}

const Banner = ({ title, description, image, mgmCode, mgmVoucherDiscount }: BannerProps) => {
  return (
    <Container>
      <div className="infoContainer">
        <h1 data-testid="banner-title">{title}</h1>
        <p data-testid="banner-description">{description}</p>
        {mgmCode && mgmVoucherDiscount && (
          <ShareMgm
            buttonShareColor="#0015A1"
            buttonCopyColor="#0015A1"
            code={mgmCode}
            discount={mgmVoucherDiscount}
            addImage="/img/discounts-page/icons/add-email-dark.svg"
          />
        )}
      </div>
      <div className="imgContainer">
        <img src={image} alt="" data-testid="banner-image" />
      </div>
    </Container>
  )
}

export default Banner
