import CloseIcon from '@mui/icons-material/Close'
import { Container, Content } from './styles'

interface IHeader {
  handleCloseIcon?: () => void
}

export default function Header({ handleCloseIcon }: IHeader) {
  return (
    <Container>
      <Content>
        <img className="remessa-online-logo" src="/img/remessa-icons/remessa-header-logo.svg" alt="Remessa-icon" />
        <CloseIcon className="close-icon" data-testid="close-icon" onClick={handleCloseIcon} fontSize="large" />
      </Content>
    </Container>
  )
}
