import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Container, Content, FlexContainer } from './styles'

interface IBanner {
  discountValue?: string | null
  subtitle?: string
  handleClick?: () => void
  message?: string | null
}

export default function NubankBanner({ discountValue, subtitle, handleClick, message }: IBanner) {
  return (
    <Container className={discountValue ? 'nubank' : 'nubank-error'}>
      <FlexContainer>
        <img
          id="nuSvg"
          src={discountValue ? '/img/nubank-icons/nubank-icon.svg' : '/img/nubank-icons/nubank-icon-error.svg'}
          alt="nubank-icon"
        />
        <Content>
          <p>{discountValue}</p>
          <h3>{subtitle}</h3>
          <p>{message}</p>
        </Content>
      </FlexContainer>
      {discountValue && <HelpOutlineIcon onClick={handleClick} data-testid="NuIcon" id="icon" />}
    </Container>
  )
}
