import TemplateModal from 'components/Templates/TemplateModal'
import { ImageContainer, ContentContainer, Title, Subtitle } from './styles'

interface IAppModal {
  show: boolean
  handleState?: () => void
}

export default function AppModal({ show, handleState }: IAppModal) {
  const handleButton = () => {
    window.open('https://applink.remessaonline.com.br/app/recomendation', '_blank')
  }

  return (
    <TemplateModal
      show={show}
      closeIconFunction={handleState}
      firstButtonLabel="Ir para o aplicativo"
      firstButtonFunction={handleButton}
    >
      <ImageContainer>
        <img src="/img/app-icons/divulgacao-app.svg" alt="Divulgação App Remessa Online" />
      </ImageContainer>
      <ContentContainer>
        <Title>A Remessa Online na palma da sua mão!</Title>
        <Subtitle>Utilize o nosso app e aproveite para fazer ou acompanhar suas transações para o exterior.</Subtitle>
      </ContentContainer>
    </TemplateModal>
  )
}
