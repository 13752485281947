import { PostMessageEventI, PostMessageSendMessageI } from 'interfaces/postMessage'
import { useEffect, useState } from 'react'

interface DimensionsI {
  width?: number
  height?: number
}

function usePostMessage(component: string, setState: (data: any) => void) {
  const [dimensions, setDimensions] = useState<DimensionsI>({})

  const sendMessage = (message: PostMessageSendMessageI) => {
    window?.top?.postMessage(JSON.stringify(message), '*')
  }

  useEffect(() => {
    window.onmessage = (e) => {
      const { data }: { data: PostMessageEventI } = e
      if (
        e.origin !== process.env.REACT_APP_POST_MESSAGE_ORIGIN_PF &&
        e.origin !== process.env.REACT_APP_POST_MESSAGE_ORIGIN_PJ
      ) {
        return
      }

      if (data && data.component === component) {
        setState(data.data)
      }

      if (data && data.metadados) {
        setDimensions({
          width: data.metadados.width,
          height: data.metadados.height,
        })
      }
    }

    window?.top?.postMessage(JSON.stringify({ type: 'renderComplete' }), '*')
  }, [component, setState])

  return {
    width: dimensions.width,
    height: dimensions.height,
    sendMessage,
  }
}

export default usePostMessage
