import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  font-family: 'Okta Neue';
  align-items: center;
  color: var(--rods-color-text-primary);
  width: 100%;

  .codeContainer {
    display: flex;
    width: 400px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: var(--rods-color-background);
    font-family: 'Okta Neue';

    box-shadow: 0px 6px 6px -3px rgba(116, 125, 134, 0.2), 0px 10px 14px 1px rgba(116, 125, 134, 0.08),
      0px 4px 18px 3px rgba(116, 125, 134, 0.08);

    .code {
      font-family: 'Okta Neue';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      font-family: 'Okta Neue';
    }
    .actionsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      .buttonCopy {
        color: var(--rods-color-main);
        display: flex;
        padding: 12px 24px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.4px;
        background-color: transparent;
        border-radius: 8px;
        border: none;
        cursor: pointer;

        :hover {
          background-color: var(--rods-color-lighter);
          border-radius: 8px;
        }
      }

      .buttonShare {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 12px 24px;
        color: var(--rods-color-white);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.4px;
        border-radius: 8px;
        background-color: var(--rods-color-main);
        border: none;
        cursor: pointer;

        :hover {
          background-color: var(--rods-color-dark);
        }
      }
    }
  }

  .popup {
    justify-self: center;
    align-self: center;
    margin: 0 auto;

    filter: drop-shadow(0px 6px 32px rgba(116, 125, 134, 0.08)) drop-shadow(0px 17px 26px rgba(116, 125, 134, 0.08))
      drop-shadow(0px 8px 11px rgba(116, 125, 134, 0.16));
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Okta Neue';
    .popupContentOptions {
      display: flex;
      width: 485px;
      padding: 32px 24px;
      flex-direction: column;
      align-items: flex-end;
      position: absolute;
      border-radius: 8px;
      gap: 8px;
      background: var(--rods-color-white);

      box-shadow: 0px 7px 8px -4px rgba(116, 125, 134, 0.2), 0px 12px 17px 2px rgba(116, 125, 134, 0.08),
        0px 5px 22px 4px rgba(116, 125, 134, 0.08);

      .popupButtonContent {
        display: flex;
        width: 100%;
        padding: 12px 24px 12px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        background: var(--rods-color-white);
        border: none;
        cursor: pointer;

        .buttonIcon {
          height: 24px;
          width: 24px;
        }

        .buttonText {
          color: var(--rods-color-text-primary);
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: 'Okta Neue';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }

        :hover {
          background: var(--rods-color-background);
        }
      }
    }
    .popupContentEmail {
      display: flex;
      width: 485px;
      padding: 32px 24px;
      flex-direction: column;
      align-items: flex-end;
      position: absolute;
      gap: 24px;
      border-radius: 8px;
      background: var(--rods-color-white);
      font-family: 'Okta Neue';
      box-shadow: 0px 7px 8px -4px rgba(116, 125, 134, 0.2), 0px 12px 17px 2px rgba(116, 125, 134, 0.08),
        0px 5px 22px 4px rgba(116, 125, 134, 0.08);

      .emailFormContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        .emailInputContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;
          label {
            display: flex;
            align-items: center;
            gap: 10px;
            color: var(--rods-color-text-secondary);
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: 'Okta Neue';
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
          input {
            font-family: 'Okta Neue';
            display: flex;
            padding: 10px 0px;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            flex: 1 0 0;
            border: none;
            border-bottom: 1px solid var(--rods-color-border);
            background: transparent;

            color: var(--rods-color-text-primary);
            font-feature-settings: 'liga' off, 'clig' off;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
          .removeEmailButton {
            position: relative;
            right: -95%;
            margin-top: -30px;
            transform: translateY(-50%);
            background: none;
            border: none;
            cursor: pointer;
          }
        }
      }
      .newEmailInput {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: none;
        background: transparent;
        padding: 4px 5px;
        gap: 8px;
        font-family: 'Okta Neue';
        cursor: pointer;

        img {
          display: flex;
          height: 18px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        p {
          display: flex;
          padding: 4px 0px;
          align-items: center;
          gap: 12px;
          color: var(--rods-color-main);
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: 'Okta Neue';
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
        :hover {
          background-color: var(--rods-color-lighter);
          border-radius: 8px;
        }
      }

      .buttonCtaEmail {
        padding: 8px 32px;
        background-color: var(--rods-color-main);
        color: white;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-family: 'Okta Neue';
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        &:hover {
          background-color: var(--rods-color-dark);
        }
        &:disabled {
          background-color: var(--rods-color-background) !important;
          color: var(--rods-color-text-secondary);
          cursor: not-allowed;
        }
      }

      .emailSuccessContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        font-family: 'Okta Neue';

        img {
          width: 56px;
          height: 56px;
        }

        h5 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-shrink: 0;
          color: var(--rods-color-text-primary);
          text-align: center;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: 'Okta Neue';
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
        }

        p {
          max-width: 312px;
          color: var(--rods-color-text-secondary);
          text-align: center;
          font-feature-settings: 'liga' off, 'clig' off;

          font-family: 'Okta Neue';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 24px;
        }
      }
    }

    .popupContentClose {
      position: absolute;
      margin-right: -14px;
      top: 10px;

      .popupCloseButton {
        background: transparent;
        border: none;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    font-family: 'Okta Neue';
    align-items: flex-end;
    justify-content: flex-end;
    color: var(--rods-color-text-primary);
    width: 100%;

    .codeContainer {
      display: flex;
      width: 100%;
      padding: 16px;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 8px;
      background: var(--rods-color-background);
      gap: 16px;
      font-family: 'Okta Neue';

      box-shadow: 0px 6px 6px -3px rgba(116, 125, 134, 0.2), 0px 10px 14px 1px rgba(116, 125, 134, 0.08),
        0px 4px 18px 3px rgba(116, 125, 134, 0.08);

      .code {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
      .actionsContainer {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        gap: 16px;

        .buttonCopy {
          color: var(--rods-color-main);
          display: flex;
          padding: 8px 12px;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0.4px;
          background-color: transparent;
          border: none;
          cursor: pointer;
        }

        .buttonShare {
          display: flex;
          padding: 4px 8px;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          background: var(--rods-color-main);
          color: var(--rods-color-white);
          border: none;
          cursor: pointer;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0.4px;
        }
      }
    }

    .popup {
      position: fixed;
      width: 100%;
      max-width: 100%;

      .popupContentOptions,
      .popupContentEmail {
        padding: 32px 24px;
        width: 90%;
      }
    }
  }
`
