import { useContext, useEffect, useState } from 'react'
import { RegisterContext } from 'context/register'
import tracker from 'utils/tracker'
import UploadBox from 'components/Register/SendDocument/UploadBox'
import LegacyButton from 'components/UI/LegacyButton'
import Paragraph from 'components/UI/Typography/Paragraph'
import { PageContainer } from '../../../../../pages/Register/SendDocument/style'

const UploadFrontDocument = () => {
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)
  const context = useContext(RegisterContext)

  const handleContinueButton = () => {
    context.setPage(context.page + 1)
    tracker({ category: 'account_pf' }).click({
      label: 'continue_button',
    })
  }

  useEffect(() => {
    tracker({ category: 'account_pf' }).pageView({
      label: 'upload_front_document_page',
    })
  }, [])

  return (
    <PageContainer>
      <div className="box">
        <div data-testid="uploadFrontInfo" className="information">
          <span className="number">2</span>
          <Paragraph fontSize={16} fontWeight={700} marginTop={0}>
            Envie a foto da frente do seu {context.documentType}
          </Paragraph>
        </div>

        <UploadBox
          setUploadSuccess={setUploadSuccess}
          documentSide="Frente"
          document={context.frontDocument}
          setDocument={context.setFrontDocument}
          onClick={() =>
            tracker({
              category: 'account_pf',
              eventProperties: {
                'A/B_Test_Name': 'onboarding_document_upload_new_flow',
                'A/B_Test_Group': 'Variant_B',
                side: 'front',
              },
            }).click({
              label: 'input_upload_document',
            })
          }
          onClickChangeFile={(error: boolean) =>
            tracker({
              category: 'account_pf',
              eventProperties: {
                'A/B_Test_Name': 'onboarding_document_upload_new_flow',
                'A/B_Test_Group': 'Variant_B',
                side: 'front',
                error: error ? 'yes' : 'no',
              },
            }).click({
              label: 'change_file_document',
            })
          }
          setImageType={context.setFrontImageType}
        />

        <LegacyButton
          data-testid="buttonCtaInformation"
          disabled={!uploadSuccess}
          onClick={() => handleContinueButton()}
        >
          Continuar
        </LegacyButton>
      </div>
    </PageContainer>
  )
}

export default UploadFrontDocument
