import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Okta Neue';
  color: var(--rods-color-text-primary);

  & > .titleContainer {
    display: flex;
    padding: 16px 0;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--rods-color-border);

    & > h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
    }
  }

  & > .stepsContainer {
    display: flex;
    padding: 32px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    align-self: stretch;

    & > .step {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      & > .badgeContainer {
        position: relative;
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        & > .stepIcon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 32px;
          height: 32px;
          z-index: 1;
        }

        & > .stepNumber {
          color: var(--rods-color-white);
          font-size: 16px;
          font-style: normal;
          font-weight: 900;
          line-height: 100%;
          letter-spacing: 0.4px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
        }
      }

      & > .textContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        width: calc(100% - 40px);
        min-width: 0;

        & > h2 {
          color: var(--rods-color-text-primary);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.2px;
          align-self: stretch;
        }

        & > p {
          color: var(--rods-color-text-primary);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          align-self: stretch;
        }
      }
    }
  }

  @media (min-width: 1025px) {
    & > .stepsContainer {
      flex-direction: row;
      padding: 32px 0;
      gap: 48px;
      flex-shrink: 0;

      & > .step {
        flex: 1 0 0;
        gap: 8px;

        & > .badgeContainer {
          width: 32px;
          height: 32px;
        }

        & > .textContainer {
          flex: 1 0 0;
          min-width: 0;
        }
      }
    }
  }
`
