import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Okta Neue';
  color: var(--rods-color-text-primary);

  & > .titleContainer {
    display: flex;
    padding: 16px 0;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--rods-color-border);

    & > h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
    }
  }

  & > .textsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 0;
    gap: 40px;

    & > .description {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }

    & > .discounts {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;

      & > .discount {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 16px;
        gap: 2px;
        border-bottom: 1px solid var(--rods-color-border);

        & > h2 {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.2px;
          align-self: stretch;
        }

        & > p {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          align-self: stretch;
        }
      }
    }
  }

  @media (min-width: 1025px) {
    width: 100%;

    & > .textsContainer {
      flex-direction: row;
      width: 100%;
      max-width: 1250px;
      gap: 80px;

      & > .description {
        width: 267px;
        flex-shrink: 0;
      }

      & > .discounts {
        flex-direction: row;
        justify-content: space-between;
        flex: 1 0 0;

        & > .discount {
          border-bottom: none;
          border-left: 1px solid var(--rods-color-border);
        }
      }
    }
  }
`
