import React from 'react'
import styled from 'styled-components'

interface CardProps {
  children: React.ReactNode
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export default function Card({ children }: CardProps) {
  return <Container>{children}</Container>
}
