/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react'
import { useDropzone, FileWithPath } from 'react-dropzone'
import { RegisterContext } from 'context/register'
import { Container } from './style'

interface ComponentProps {
  setUploadSuccess(value: boolean): void
  documentSide: string
  document?: FileWithPath
  setDocument(file: FileWithPath): void
  onClick?(): void
  onClickChangeFile?(imgError?: boolean): void
  setImageType(imageType: string): void
}

const UploadBox = ({
  setUploadSuccess,
  documentSide,
  setDocument,
  document,
  onClick,
  onClickChangeFile,
  setImageType,
}: ComponentProps) => {
  const [currentFile, setCurrentFile] = useState<FileWithPath | undefined>(document)
  const [imgBase64, setImgBase64] = useState<string | undefined>()
  const [imgName, setImgName] = useState<string | undefined>()
  const [imgType, setImgType] = useState<string | undefined>()
  const [imgError, setImgError] = useState<boolean>(false)
  const [differentExtension, setDifferentExtension] = useState<boolean>(false)

  const context = useContext(RegisterContext)

  const getBase64 = (file: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (reader.result && typeof reader.result === 'string') {
        setImgBase64(reader.result)
      }
    }
  }

  const handleImage = useCallback(
    (file?: FileWithPath) => {
      if (!file) {
        setImgError(true)
        setUploadSuccess(false)
        return
      }

      if (context.frontDocument && context.frontDocument.type !== file.type) {
        setDifferentExtension(true)
        return
      }

      setDifferentExtension(false)

      setImgBase64(undefined)

      if (file?.type.includes('image')) {
        getBase64(file)
      }

      setImgType(file?.type)
      setImageType(file?.type)
      setImgName(file?.name)
      setImgError(false)
      setUploadSuccess(true)
    },
    [setUploadSuccess],
  )

  const onDrop = useCallback(
    (acceptedFiles) => {
      setCurrentFile(acceptedFiles?.[0])
      handleImage(acceptedFiles?.[0])
      setDocument(acceptedFiles?.[0])
    },
    [handleImage, setDocument],
  )

  useEffect(() => {
    if (currentFile) {
      handleImage(currentFile)
    }
  }, [currentFile, handleImage])

  const resetFile = () => {
    setCurrentFile(undefined)
    setImgBase64(undefined)
    setImgType(undefined)
  }

  useEffect(() => {
    if (!document) {
      resetFile()
    }
  }, [document])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxSize: 10485760,
    accept: 'image/jpeg, image/png, application/pdf',
    noClick: !!currentFile,
  }) // 10MB

  const handleChangeFile = () => {
    open()

    if (onClickChangeFile) {
      onClickChangeFile(imgError)
    }
  }

  return (
    <Container>
      <div
        className="uploadBox"
        {...getRootProps({
          onClick: () => {
            if (onClick) {
              onClick()
            }
          },
        })}
      >
        <input data-testid="dropInput" {...getInputProps()} />

        {!imgError && !differentExtension && !imgBase64 && !imgType && (
          <div className="boxDocument">
            <div className="info" data-testid="documentInfo">
              <img className="icon" src="/img/remessa-icons/upload.svg" alt="upload" />
              {isDragActive ? <p>Solte seu arquivo aqui...</p> : <p>{documentSide} do seu documento</p>}
            </div>
            <button className="cta" type="button">
              Adicionar
            </button>
          </div>
        )}
        {!imgError && !differentExtension && imgBase64 && (
          <>
            <div className="boxDocumentImage">
              <div className="imgBox">
                <img src={imgBase64} alt="imp upload" />
              </div>
            </div>
            <div className="boxDocumentInfos">
              <div className="fileName" data-testid="imageFilename">
                {imgName}
              </div>
              <button type="button" className="changeFile" onClick={handleChangeFile}>
                Trocar arquivo
              </button>
            </div>
          </>
        )}
        {!imgError && !differentExtension && imgType && imgType === 'application/pdf' && (
          <>
            <div className="boxDocumentSuccess" data-testid="pdfSuccess">
              <img src="/img/remessa-icons/success.svg" alt="success" className="icon" />
              <p>Sucesso</p>
            </div>
            <div className="boxDocumentInfos">
              <div className="fileName">{imgName}</div>
              <button type="button" className="changeFile" onClick={handleChangeFile}>
                Trocar arquivo
              </button>
            </div>
          </>
        )}
        {imgError && (
          <div className="boxDocumentError" data-testid="warning">
            <img src="/img/remessa-icons/warningfile.svg" alt="warning" className="icon" />
            <p>
              O arquivo excede o tamanho
              <br /> máximo de 10MB
            </p>
            <button type="button" className="changeFile" onClick={handleChangeFile}>
              Trocar arquivo
            </button>
          </div>
        )}
        {differentExtension && (
          <div className="boxDocumentError" data-testid="warning">
            <img src="/img/remessa-icons/warningfile.svg" alt="warning" className="icon" />
            <p>É necessário que os dois arquivos enviados possuam a mesma extensão</p>
            <button type="button" className="changeFile" onClick={handleChangeFile}>
              Trocar arquivo
            </button>
          </div>
        )}
      </div>
    </Container>
  )
}

export default UploadBox
