import { ScopedCssBaseline } from '@mui/material'
import { ModalStyled, BoxStyled, CloseIconStyled, CloseIconContainer, ButtonContainer, Container } from './styles'
import Button from '../../Register/Button'

interface ITemplateModal {
  show: boolean
  children?: React.ReactNode
  closeIconFunction?: () => void
  firstButtonLabel?: string
  firstButtonFunction?: () => void
  firstButtonVariant?: 'link' | 'contained' | 'outlined'
  secondButtonLabel?: string
  secondButtonFunction?: () => void
  secondButtonVariant?: 'link' | 'contained' | 'outlined'
}

export default function TemplateModal({
  show,
  children,
  firstButtonLabel,
  closeIconFunction,
  firstButtonFunction,
  firstButtonVariant = 'contained',
  secondButtonVariant,
  secondButtonLabel,
  secondButtonFunction,
}: ITemplateModal) {
  return (
    <ModalStyled open={show} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <ScopedCssBaseline>
        <BoxStyled>
          <CloseIconContainer>
            <CloseIconStyled data-locator="close" aria-label="close-modal-icon" onClick={closeIconFunction} />
          </CloseIconContainer>
          {children}
          <Container>
            <ButtonContainer>
              <Button handleClick={firstButtonFunction} element={firstButtonLabel} variant={firstButtonVariant} />
            </ButtonContainer>
            {secondButtonLabel &&
              (secondButtonVariant === 'link' ? (
                <ButtonContainer link>
                  <Button
                    handleClick={secondButtonFunction}
                    element={secondButtonLabel}
                    variant={secondButtonVariant}
                  />
                </ButtonContainer>
              ) : (
                <ButtonContainer>
                  <Button
                    handleClick={secondButtonFunction}
                    element={secondButtonLabel}
                    variant={secondButtonVariant}
                  />
                </ButtonContainer>
              ))}
          </Container>
        </BoxStyled>
      </ScopedCssBaseline>
    </ModalStyled>
  )
}
