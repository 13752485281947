import { Route as ReactDOMRoute, RouteProps } from 'react-router-dom'

interface RoutePropsI extends RouteProps {
  component: React.ComponentType<any>
  provider?: React.ComponentType<any>
}

const Route = ({ component: Component, provider: Provider, ...rest }: RoutePropsI) => {
  return (
    <ReactDOMRoute
      {...rest}
      render={() =>
        Provider ? (
          <Provider>
            <Component />
          </Provider>
        ) : (
          <Component />
        )
      }
    />
  )
}

export default Route
