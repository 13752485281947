import api from 'api'

const getCountries = async () => {
  try {
    const response = await api.get('/v1/country')
    return response?.data?.result
  } catch (error) {
    return error
  }
}

export default {
  getCountries,
}
