import styled from 'styled-components'
import { TextField } from '@mui/material'

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: max-content;
  padding: 16px;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: center;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 24px 0px;
  border: 1px solid #f3f3f4;
`
export const Form = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const FormGroup = styled.div``

export const FormGroupTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #666666;
  margin-bottom: 8px;
`

export const FormGroupPanel = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 24px 24px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`

export const FormGroupContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
// @ts-ignore
export const CustomTextField = styled(TextField)(() => ({
  '&': {
    margin: '0px !important',
    backgroundColor: '#ffffff',
  },
  '& .MuiInputLabel-root': {
    padding: '4px 8px 0px 8px',
  },
  '& .MuiInput-root': {
    padding: '0px 8px 4px 8px',
  },
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    top: '100%',
  },
}))

export const CompletedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 62px;
`

export const CompletedTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin: 24px 0px 20px 0px;
  width: 280px;
  text-align: center;
`

export const CompletedImage = styled.img`
  width: 56px;
  height: 56px;
`

export const ToastContainer = styled.div`
  position: absolute;
  width: 100%;
  top: -40px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px 24px;
  background-color: #da1e28;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 8px;
  box-shadow: 0px 5px 22px 4px #747d8614;
  box-shadow: 0px 12px 17px 2px #747d8614;
  box-shadow: 0px 7px 8px -4px #747d8633;
`

export const Loader = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  img {
    animation: loading 0.5s infinite linear;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`
