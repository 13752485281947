import AddressForm from 'components/Register/Forms/AddressForm'
import TitleSubtitle from 'components/Register/TitleSubtitle'
import TemplateRegister from 'components/Templates/TemplateRegister'
import TemplateForm from 'components/Templates/TemplateForm'
import { useState } from 'react'
import RegisterModal from 'components/UI/Modals/RegisterModal'

export default function AddressPage() {
  const [open, setOpen] = useState(false)

  const handleClick = async () => {
    setOpen((prev) => !prev)
  }

  return (
    <TemplateRegister handleCloseIcon={handleClick}>
      {open && <RegisterModal show={open} handleState={handleClick} />}
      <TemplateForm titleSubtitle={<TitleSubtitle title="Endereço" subtitle="Passo 3/3" />} form={<AddressForm />} />
    </TemplateRegister>
  )
}
