import { styled } from '@mui/material'

export const Container = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  & label.Mui-focused {
    color: ${({ theme }) => theme.remessa.palette.common.logoRemessa};
  }

  & .MuiInput-underline.Mui-focused:after {
    border-bottom-color: ${({ theme }) => theme.remessa.palette.common.logoRemessa};
  }

  .MuiSelect-icon {
    color: ${({ theme }) => theme.remessa.palette.common.icon};
  }
`
