import { styled } from '@mui/material'

export const Container = styled('header')`
  height: 4.688em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.remessa.palette.common.headerBorder};
  padding: 0 0.78125em;
  background-color: #ffffff;
`

export const Content = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 93.5%;
  max-width: 74.4em;

  .remessa-online-logo {
    width: 182px;
    height: 32px;
  }

  .close-icon {
    color: ${({ theme }) => theme.remessa.palette.common.icon};
    cursor: pointer;
  }

  @media screen and (min-width: ${({ theme }) => theme.remessa.breakpoints.sm}) {
    width: 90%;
  }
`
