import { InformationContainer } from './style'

const TransferenceInformation = () => {
  return (
    <InformationContainer>
      <div className="box">
        <div className="title">
          <span>Agora é com você!</span>
        </div>
        <div className="steps">
          <span>1. A remessa foi confirmada</span>
          <span>2. Abra o aplicativo do seu banco</span>
          <span>3. Pague o valor a ser enviado ao exterior</span>
          <span>4. Aguarde o e-mail de identificação do pagamento</span>
        </div>
      </div>
    </InformationContainer>
  )
}

export default TransferenceInformation
