import api from 'api'
import { IRegistrationForm, IUserData, IValidateCustomerData, Status } from 'interfaces/Register/customer'

const getCustomerData = async (): Promise<IUserData> => {
  const response = await api.get('/v1/customer')

  return response?.data?.result
}

const updateCustomerData = async (userData: unknown): Promise<IUserData> => {
  const response = await api.put('/v2/customer', userData)

  return response?.data?.result
}

const validateCustomerData = async (): Promise<IValidateCustomerData> => {
  const response = await api.post('/v2/customer/validate-info')

  return { status: response?.status, message: response?.data?.message }
}

const sendNatureTypeToCRM = async (selectedNature: string) => {
  await api.post('/v2/customer/crmaas-semaphore', { presignupNature: selectedNature })
}

const getRegistratioForm = async (): Promise<IRegistrationForm> => {
  const response = await api.get('/v2/registration-form')

  return response?.data?.[0]
}

const createOrUpdateRegistratioForm = async (data: any): Promise<IRegistrationForm> => {
  const response = await api.post('/v2/registration-form', data)

  return response?.data?.[0]
}

const getCustomerPolicy = async (): Promise<Status[]> => {
  const response = await api.get('/v2/customer-update-policy/status')

  return response.data
}

const confirmCustomerPolicy = async (data: any): Promise<IValidateCustomerData> => {
  const response = await api.post('/v2/customer-update-policy/confirm', data)

  return { status: response?.status, message: response?.data?.message }
}

export default {
  getCustomerPolicy,
  confirmCustomerPolicy,
  getCustomerData,
  updateCustomerData,
  validateCustomerData,
  sendNatureTypeToCRM,
  getRegistratioForm,
  createOrUpdateRegistratioForm,
}
