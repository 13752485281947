import { Container } from './style'
import ShareMgm from '../../Mgm/share'

interface BannerProps {
  title: string
  description: string
  image: string
  mgmCode?: string
  mgmVoucherDiscount?: string
}

const Banner = ({ title, description, image, mgmCode, mgmVoucherDiscount }: BannerProps) => {
  return (
    <Container>
      <div className="infoContainer">
        <h1 data-testid="banner-title">{title}</h1>
        <p data-testid="banner-description">{description}</p>
        {mgmCode && mgmVoucherDiscount && <ShareMgm code={mgmCode} discount={mgmVoucherDiscount} />}
      </div>
      <div className="imgContainer">
        <img src={image} alt="" data-testid="banner-image" />
      </div>
    </Container>
  )
}

export default Banner
