import { Modal, styled } from '@mui/material'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'

interface IButtonContainer {
  link?: boolean
}

export const ModalStyled = styled(Modal)`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  align-items: center;
  text-align: center;
  width: 100%;
  background-color: ${({ theme }) => theme.remessa.palette.common.white};
  border-radius: 5px 5px 0 0;
  padding: 2em;
  position: absolute;
  bottom: 0;
  left: 0;

  @media screen and (min-width: ${({ theme }) => theme.remessa.breakpoints.sm}) {
    position: unset;
    bottom: unset;
    width: 574px;
    border-radius: 5px;
  }
`

export const CloseIconStyled = styled(CloseIcon)`
  color: ${({ theme }) => theme.remessa.palette.common.icon};
  font-size: 30px;
`

export const CloseIconContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const ButtonContainer = styled('div')<IButtonContainer>`
  width: 300px;
  height: 48px;
  font-size: 16px;
  font-weight: 700;

  ${(props) =>
    props.link &&
    `
  padding-bottom: 2.8em;
  `}
`
export const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
`
