import { ButtonContainer } from './style'

interface ComponentProps {
  testId?: string
  disabled?: boolean
  loading?: boolean
  children: React.ReactChild
  onClick(): void
}

const LegacyButton = ({ testId, children, disabled = false, loading = false, onClick }: ComponentProps) => (
  <ButtonContainer
    id="legacyButton"
    data-testid={testId}
    onClick={onClick}
    type="button"
    className={loading ? 'btLoading' : ''}
    disabled={disabled}
  >
    {children}
  </ButtonContainer>
)

export default LegacyButton
