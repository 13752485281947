import { styled } from '@mui/material'

export const Container = styled('div')`
  width: 100%;
  height: 640px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
