import Context from 'components/OnBoarding/context'
import Paragraph from 'components/UI/Typography/Paragraph'
import { useContext, useEffect, useState } from 'react'
import tracker from 'utils/tracker'
import gtmTracker from 'utils/gtmTracker'
import { PageContainer } from '../style'
import unleash from '../../../../services/Unleash/unleash.service'

const OnBoardingRegisterSelectDocument = () => {
  const context = useContext(Context)
  const [shouldShowPassportUpload, setshouldShowPassportUpload] = useState<boolean>(true)

  const handleClickDocument = (document: string) => {
    context.setDocumentType(document)
    context.setPage(context.page + 1)
    tracker({
      category: 'account_pf',
      eventProperties: {
        'A/B_Test_Name': 'onboarding_document_upload_new_flow',
        'A/B_Test_Group': 'Variant_B',
        'A/B_Test_Passport_Name': 'ro_onboarding-upload-passport',
        'A/B_Test_Passport_is_active': shouldShowPassportUpload,
        id_type: document,
      },
    }).click({
      label: 'select_id_doc_type',
    })

    const interaction = {
      type: 'button',
      target: 'signup',
      label: document,
    }

    const eventPage = {
      page: 'app/criar-conta',
      flow_type: 'individual',
      flow_type_tier1: 'signup',
    }

    gtmTracker({ event: 'internal_click', interaction, page: eventPage })
  }

  const renderPassportUpload = () => {
    if (shouldShowPassportUpload) {
      return (
        <li>
          <button onClick={() => handleClickDocument('PASSPORT')} type="button">
            Passaporte
          </button>
        </li>
      )
    }

    return null
  }

  useEffect(() => {
    const getFeaturePassport = async () => {
      const feature = await unleash.getFeatureData('ro_onboarding-upload-passport', {})
      setshouldShowPassportUpload(feature.isEnabled)
    }
    getFeaturePassport()
    tracker({ category: 'account_pf' }).pageView({
      label: 'select_document_page',
    })

    const interaction = {
      type: 'section',
      target: 'signup',
      label: 'documents-select',
    }

    const eventPage = {
      page: 'app/criar-conta',
      flow_type: 'individual',
      flow_type_tier1: 'signup',
    }

    gtmTracker({ event: 'view', interaction, page: eventPage })
  }, [])

  return (
    <PageContainer>
      <div className="box">
        <div data-testid="selectDocumentInfo" className="information">
          <span className="number">1</span>
          <Paragraph fontSize={16} fontWeight={700} marginTop={0}>
            Escolha um documento para enviar:
          </Paragraph>
        </div>

        <ul className="selectDocument">
          <li>
            <button data-testid="buttonCtaSelectDocumentRG" onClick={() => handleClickDocument('RG')} type="button">
              RG
            </button>
          </li>
          <li>
            <button onClick={() => handleClickDocument('CNH')} type="button">
              CNH
            </button>
          </li>
          <li>
            <button onClick={() => handleClickDocument('RNE')} type="button">
              RNE
            </button>
          </li>
          {renderPassportUpload()}
        </ul>
      </div>
    </PageContainer>
  )
}

export default OnBoardingRegisterSelectDocument
