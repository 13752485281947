import { styled } from '@mui/material'
import Link from '@mui/material/Link'

export const LinkStyled = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid #09f;
  color: #09f;
  padding-bottom: 2px;
  text-align: center;
  font-size: 1em;
`
