import api from 'api'

interface UnleashResponse {
  isEnabled: boolean
}

const getFeatureData = async (featureName: string, qs: object): Promise<UnleashResponse> => {
  const response = await api.get(`/v2/feature-toggle/${featureName}`, {
    params: qs,
  })

  return response.data
}

const getFeatureVariantData = async (featureName: string, qs: object): Promise<UnleashResponse> => {
  const response = await api.get(`/v2/feature-toggle-variant/${featureName}`, {
    params: qs,
  })

  return response.data
}

export default { getFeatureData, getFeatureVariantData }
