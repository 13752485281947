import { useContext, useEffect } from 'react'
import { RegisterContext } from 'context/register'
import tracker from 'utils/tracker'
import LegacyButton from 'components/UI/LegacyButton'
import Paragraph from 'components/UI/Typography/Paragraph'
import { PageContainer } from '../../../../pages/Register/SendDocument/style'

const OnBoardingRegisterDocumentInformation = () => {
  const context = useContext(RegisterContext)

  const handleUnderstoodButton = () => {
    context.setPage(context.page + 1)
    tracker({ category: 'account_pf' }).click({
      label: 'understood_button',
    })
  }

  useEffect(() => {
    tracker({ category: 'account_pf' }).pageView({
      label: 'documents_tips_page',
    })
  }, [])

  return (
    <PageContainer>
      <div className="box">
        <div data-testid="informationInfo" className="information">
          <Paragraph fontSize={16} fontWeight={700} marginTop={0}>
            Confira as dicas para enviar seu documento:
          </Paragraph>
        </div>

        <ul className="documentTopicList">
          <li>
            <span className="icon">
              <img src="/img/remessa-icons/check.svg" alt="check" />
            </span>
            <div className="text">
              <Paragraph fontSize={16} fontWeight={700} marginTop={0}>
                O documento deve ser oficial e válido
              </Paragraph>
            </div>
          </li>
          <li>
            <span className="icon">
              <img src="/img/remessa-icons/camera.svg" alt="camera" />
            </span>
            <div className="text">
              <Paragraph fontSize={16} fontWeight={700} marginTop={0}>
                Retire o documento do plástico e tire uma foto em um lugar iluminado
              </Paragraph>
            </div>
          </li>
          <li>
            <span className="icon">
              <img src="/img/remessa-icons/folder.svg" alt="folder" />
            </span>

            <div className="text">
              <Paragraph fontSize={16} fontWeight={700} marginTop={0}>
                A foto deve estar legível e no formato PNG, JPEG ou PDF
              </Paragraph>
            </div>
          </li>
        </ul>

        <LegacyButton data-testid="buttonCtaInformation" onClick={() => handleUnderstoodButton()}>
          Entendi
        </LegacyButton>
      </div>
    </PageContainer>
  )
}

export default OnBoardingRegisterDocumentInformation
