import { styled } from '@mui/material'

interface IButtonContainer {
  variant?: string
}

export const Container = styled('button')<IButtonContainer>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.06em;
  font-weight: 700;
  border-radius: 2em;
  border: none;
  background-color: transparent;
  cursor: pointer;

  ${(props) =>
    props.variant === 'contained' &&
    `
    color: ${props.theme.remessa.palette.common.white};
    background-image: ${props.theme.remessa.palette.gradients.registerButtonGradient};
  `}
  ${(props) =>
    props.variant === 'outlined' &&
    `
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    color: ${props.theme.remessa.palette.common.logoRemessa};
    border: 1px solid ${props.theme.remessa.palette.common.logoRemessa};
  `}
  ${(props) =>
    props.variant === 'link' &&
    `
    color: ${props.theme.remessa.palette.common.logoRemessa};
    text-decoration: underline ${props.theme.remessa.palette.common.logoRemessa};
    text-underline-offset: 0.5em;
    text-align: center;
    font-weight: 300;
    &:hover {
      background-color: unset;
      text-decoration: underline ${props.theme.remessa.palette.common.logoRemessa};
    }
  `}
`
