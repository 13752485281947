import GlobalStyle from 'styles/global'

import { EventData } from 'components/UserUpdate'
import MemberGetMemberPJ from '../../components/MemberGetMemberPJ'

const MemberGetMemberPJPage = () => {
  return (
    <>
      <GlobalStyle />
      <MemberGetMemberPJ userToken={process.env.REACT_APP_USER_TOKEN} eventData={{} as EventData} />
    </>
  )
}

export default MemberGetMemberPJPage
