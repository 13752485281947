import { RegisterContext } from 'context/register'
import { useContext, useEffect } from 'react'
import LegacyButton from 'components/UI/LegacyButton'
import Paragraph from 'components/UI/Typography/Paragraph'
import ButtonLink from 'components/UI/ButtonLink'
import tracker from 'utils/tracker'
import { PageContainer } from '../../../../pages/Register/SendDocument/style'

const OnBoardingRegisterDocumentWelcome = () => {
  const context = useContext(RegisterContext)

  const handleSendNowButton = () => {
    context.setPage(context.page + 1)
    tracker({ category: 'account_pf' }).click({
      label: 'send_now_button',
    })
  }

  const handleSendLaterButton = () => {
    tracker({ category: 'account_pf' }).click({
      label: 'send_later_button',
    })
  }

  useEffect(() => {
    tracker({ category: 'account_pf' }).pageView({
      label: 'send_document_now_or_later_page',
    })
  }, [])

  return (
    <PageContainer>
      <div className="box">
        <div data-testid="welcomeInfo" className="information">
          <Paragraph fontSize={16} fontWeight={700} marginTop={0}>
            Vamos precisar do seu documento de identificação por exigência do Banco Central.
            <br />
            <br /> Prefere enviar agora ou depois?
          </Paragraph>
        </div>

        <LegacyButton data-testid="buttonCtaWelcome" onClick={() => handleSendNowButton()}>
          Enviar agora
        </LegacyButton>

        <div className="sendLater">
          <ButtonLink onClick={() => handleSendLaterButton()}>Enviar depois</ButtonLink>
        </div>
      </div>
    </PageContainer>
  )
}

export default OnBoardingRegisterDocumentWelcome
