import api from 'api'
import { Nu, NuToken } from 'interfaces/Nu/nu'

const getNuToken = async ({ token, affiliateId, offerId, customerType = 'F' }: NuToken): Promise<Nu> => {
  const response = await api.get(
    `/v2/nubank-integration/validate-token?&token=${token}&affiliateId=${affiliateId}&offerId=${offerId}&customerType=${customerType}`,
  )
  return response?.data
}

export default { getNuToken }
