import { useEffect, useState } from 'react'
import Banner from '../Generic/banner'
import {
  RemessaMaisBannerProps,
  RemessaMaisYourDiscountDefaultProps,
  RemessaMaisHowWorksProps,
  RemessaMaisRulesProps,
} from '../enum'
import YourDiscount from '../Generic/yourDiscount'
import DiscountsService from '../../../services/Discounts/discounts.service'
import HowWorks from './howWorks'
import Rules from './rules'

const RemessaMaisContainer = () => {
  const [yourDiscountProps, setYourDiscountProps] = useState(RemessaMaisYourDiscountDefaultProps)

  useEffect(() => {
    const remessaMaisInfo = async () => {
      try {
        const info = await DiscountsService.getRemessaMais()
        setYourDiscountProps({
          elegible: info.elegible,
          discountTag: info.discountTag,
          descriptionInfo: info.descriptionInfo,
        })
      } catch {
        setYourDiscountProps(RemessaMaisYourDiscountDefaultProps)
      }
    }

    remessaMaisInfo()
  }, [])

  return (
    <>
      <Banner {...RemessaMaisBannerProps} />
      <YourDiscount {...yourDiscountProps} />
      <HowWorks {...RemessaMaisHowWorksProps} />
      <Rules {...RemessaMaisRulesProps} />
    </>
  )
}

export default RemessaMaisContainer
