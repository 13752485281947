import { styled } from '@mui/material'

export const Container = styled('div')`
  height: 5em;
  width: 100%;
  padding-right: 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &.nubank {
    background-color: ${({ theme }) => theme.remessa.palette.nubank.nuPrimary};
  }

  &.nubank-error {
    background-color: ${({ theme }) => theme.remessa.palette.nubank.nuError};
  }

  #icon {
    cursor: pointer;
    color: ${({ theme }) => theme.remessa.palette.common.white};
  }

  #nuSvg {
    height: 60px;
    margin-left: 2em;
  }
`
export const FlexContainer = styled('div')`
  display: flex;
`

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 74.4em;
  justify-content: center;
  align-items: center;
  margin: 0 0.01em;
  padding: 0 0.5em;

  @media screen and (min-width: ${({ theme }) => theme.remessa.breakpoints.sm}) {
    align-items: flex-start;
  }

  p {
    font-weight: 500;
    color: ${({ theme }) => theme.remessa.palette.common.white};
  }

  h3 {
    font-size: 0.9em;
    font-weight: bold;
    color: ${({ theme }) => theme.remessa.palette.common.white};
  }
`
