/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react'
import copy from 'copy-to-clipboard'
import ShareOptionsPopup from './subComponents/ShareOptionsPopup'
import EmailSetupPopup from './subComponents/EmailSetupPopup'
import EmailSentPopup from './subComponents/EmailSentPopup'
import { Container } from './style'
import { WhatsAppMessageTemplate } from '../../enum'
import discountService from '../../../../services/Discounts/discounts.service'
import usePostMessage from '../../../../hooks/usePostMessage'
import gtmTracker from '../../../../utils/gtmTracker'

interface ShareComponentProps {
  code: string
  discount: string
  buttonShareColor?: string
  buttonCopyColor?: string
  addImage?: string
}

interface Email {
  id: string
  email: string
}

const generateUniqueId = (() => {
  let idCounter = 0

  return () => {
    idCounter += 1

    return idCounter.toString()
  }
})()

const ShareComponent = ({ code, discount, buttonShareColor, buttonCopyColor, addImage }: ShareComponentProps) => {
  const [showPopup, setShowPopup] = useState(false)
  const [showEmailSetup, setShowEmailSetup] = useState(false)
  const [emails, setEmails] = useState<Email[]>([{ id: generateUniqueId(), email: '' }])
  const [showEmailSent, setShowEmailSent] = useState(false)
  const [pageData, setPageData] = useState<any>({})

  const usePostMessageHook = usePostMessage('DiscountsPage', () => {})

  const sendClickEvent = (label: string) => {
    const interaction = {
      type: 'button',
      target: 'mgm',
      label,
    }

    gtmTracker({ event: 'internal_click', interaction, page: pageData })
  }

  const handleCopy = () => {
    copy(code)
    usePostMessageHook.sendMessage({
      type: 'copySuccess',
    })
    sendClickEvent('copiar-cupom')
  }

  const handleShareWhatsApp = () => {
    const message = WhatsAppMessageTemplate.message(code, discount)
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`
    sendClickEvent('compartilhar-por-whatsapp')
    window.open(url, '_blank')
  }

  const handleShareEmail = () => {
    setShowEmailSetup(true)
    sendClickEvent('compartilhar-por-email')
  }

  const handleEmailChange = (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedEmails = emails.map((email) => (email.id === id ? { ...email, email: e.target.value } : email))
    setEmails(updatedEmails)
  }

  const handleAddEmail = () => {
    setEmails([...emails, { id: generateUniqueId(), email: '' }])
  }

  const handleRemoveEmail = (id: string) => {
    const updatedEmails = emails.filter((email) => email.id !== id)
    setEmails(updatedEmails)
  }

  const hasValidEmails = emails.some((email) => email.email.trim() !== '')

  const handleSendEmail = async () => {
    if (!hasValidEmails) return
    try {
      const emailsToSend = emails.map(({ email }) => ({ email }))
      const result = await discountService.InviteFriendsList({ invites: emailsToSend })
      setEmails([{ id: generateUniqueId(), email: '' }])
      setShowEmailSent(true)

      const interaction = {
        type: 'message',
        target: 'mgm',
        label: result,
      }

      gtmTracker({ event: 'event_success', interaction, page: pageData })
    } catch (error) {
      usePostMessageHook.sendMessage({
        type: 'errorOnSendInvites',
      })
      const interaction = {
        type: 'message',
        target: 'mgm',
        label: error,
      }
      gtmTracker({ event: 'event_error', interaction, page: pageData })
    }
  }

  const handleClosePopup = () => {
    setShowPopup(false)
    setShowEmailSetup(false)
    setShowEmailSent(false)
  }

  useEffect(() => {
    const storedPageData = localStorage.getItem('beero.mgmEventPage')
    if (storedPageData) {
      setPageData(JSON.parse(storedPageData))
    }
  }, [])

  return (
    <Container>
      <div className="codeContainer">
        <h6 className="code">{code}</h6>
        <div className="actionsContainer">
          <button type="button" className="buttonCopy" onClick={handleCopy} style={{ color: buttonCopyColor }}>
            Copiar
          </button>
          <button
            type="button"
            className="buttonShare"
            onClick={() => setShowPopup(true)}
            style={{ backgroundColor: buttonShareColor }}
          >
            Compartilhar
          </button>
        </div>
      </div>
      {showPopup && (
        <div className="popup">
          {!showEmailSetup && !showEmailSent && (
            <ShareOptionsPopup
              onCopy={handleCopy}
              onShareWhatsApp={handleShareWhatsApp}
              onShareEmail={handleShareEmail}
              onClose={handleClosePopup}
            />
          )}
          {showEmailSetup && !showEmailSent && (
            <EmailSetupPopup
              emails={emails}
              buttonShareColor={buttonShareColor}
              onAddEmail={handleAddEmail}
              onRemoveEmail={handleRemoveEmail}
              onEmailChange={handleEmailChange}
              onSendEmail={handleSendEmail}
              hasValidEmails={hasValidEmails}
              onClose={handleClosePopup}
              addImage={addImage}
            />
          )}
          {showEmailSent && <EmailSentPopup buttonShareColor={buttonShareColor} onClose={handleClosePopup} />}
        </div>
      )}
    </Container>
  )
}

export default ShareComponent
