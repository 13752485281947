import styled from 'styled-components'

interface ComponentProps {
  fontSize?: number
  fontWeight?: number
  marginTop?: number
  fadeEffect?: boolean
  children: React.ReactNode
}

const Text = styled.p<ComponentProps>`
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #568;
  margin-bottom: 0;
  margin-top: ${(props) => props.marginTop}px;

  ${({ fadeEffect }) =>
    fadeEffect
      ? `
    animation: fadein 2s;

    @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }
  `
      : ``}
`

export default function Paragraph({
  children,
  fontSize = 12,
  fontWeight = 400,
  marginTop = 23,
  fadeEffect = false,
}: ComponentProps) {
  return (
    <Text fontSize={fontSize} fontWeight={fontWeight} marginTop={marginTop} fadeEffect={fadeEffect}>
      {children}
    </Text>
  )
}
