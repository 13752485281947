/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react'
import { Container } from './style'

interface Invite {
  id: number
  name: string
  email: string
  spread_discount_earned: string
  start_date: string
  end_date: string
}

interface InviteListProps {
  invites: Invite[]
  loadMore: () => void
  hasMore: boolean
  resultsPerPage: number
  onResultsPerPageChange: (limit: number) => void
  totalInvites: number
}

const InviteList = ({
  invites,
  loadMore,
  hasMore,
  resultsPerPage,
  onResultsPerPageChange,
  totalInvites,
}: InviteListProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const handleResultsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onResultsPerPageChange(Number(event.target.value))
    setCurrentPage(1)
  }

  const handlePageChange = (page: number, nextPage: boolean) => {
    setCurrentPage(page)

    if (hasMore && nextPage) {
      loadMore()
    }
  }

  const displayedInvites = invites.slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage)

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)

    return date.toLocaleDateString('pt-BR')
  }

  return (
    <Container>
      <div className="titleContainer">
        <h1>Veja suas indicações</h1>
      </div>
      <div className="invitesTableContainer">
        <table className="invitesTable">
          <thead>
            <tr>
              <th className="tableHeader indications">Indicações</th>
              <th className="tableHeader discountColumn">Desconto</th>
              <th className="tableHeader expirationColumn">Vencimento</th>
            </tr>
          </thead>
          <tbody>
            {displayedInvites.length > 0 ? (
              displayedInvites.map((invite) => (
                <tr key={invite.id} className="tableRow">
                  <td className="indications">
                    {invite.name} - {invite.email}
                  </td>
                  <td className="discountColumn">{parseInt(invite.spread_discount_earned, 10)}%</td>
                  <td className="expirationColumn">{formatDate(invite.end_date)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>Você ainda não fez nenhuma indicação</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="paginationContainer">
          <div className="resultsPerPage">
            <label htmlFor="resultsPerPageSelect">Resultados por página:</label>
            <select id="resultsPerPageSelect" value={resultsPerPage} onChange={handleResultsPerPageChange}>
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </div>
          <div className="paginationInfo">
            <span>
              {(currentPage - 1) * resultsPerPage + 1}-{Math.min(currentPage * resultsPerPage, invites.length)} de{' '}
              {totalInvites}
            </span>
          </div>
          <div className="paginationControls">
            <button type="button" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1, false)}>
              <img
                src={`/img/discounts-page/icons/arrow-left-chevron${currentPage === 1 ? '-disabled' : ''}.svg`}
                alt="página anterior"
              />
            </button>
            <button
              type="button"
              disabled={currentPage * resultsPerPage >= totalInvites}
              onClick={() => handlePageChange(currentPage + 1, true)}
            >
              <img
                src={`/img/discounts-page/icons/arrow-right-chevron${
                  currentPage * resultsPerPage >= totalInvites ? '-disabled' : ''
                }.svg`}
                alt="próxima página"
              />
            </button>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default InviteList
