import { CircularProgress } from '@mui/material'
import { Container } from './styles'

interface IButton {
  element?: string
  handleClick?: React.MouseEventHandler<HTMLButtonElement>
  type?: 'submit' | 'button'
  isLoading?: boolean
  variant?: 'contained' | 'outlined' | 'link'
}

export default function Button({ element, handleClick, type, isLoading, variant }: IButton) {
  return (
    <Container variant={variant} type={type} onClick={handleClick}>
      {isLoading ? <CircularProgress size={20} color="inherit" title="circular-progress" /> : element}
    </Container>
  )
}
