import { FileWithPath } from 'react-dropzone'
import api from 'api'
import { RequestStatus } from '../../../enums/requestStatus'

let maxRetry = 3

const maxAttempt = () => {
  maxRetry -= 1

  return maxRetry
}

const uploadDoubleSidedDocument = async (
  documentType: string,
  documentFront: FileWithPath,
  documentVerse: FileWithPath,
  userToken: string,
  attempt = 0,
): Promise<Number> => {
  try {
    const formData = new FormData()

    formData.append('files', documentFront)
    formData.append('files', documentVerse)
    formData.append('ocrDocType', documentType)

    const sendDocument = await api.post('/v2/requirements/identity-document-front-back', formData, {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    })

    return sendDocument.status
  } catch (err: any) {
    if (err.response.status === RequestStatus.documentCorruptedError || attempt >= maxAttempt()) {
      return err?.response?.status
    }

    return uploadDoubleSidedDocument(documentType, documentFront, documentVerse, userToken, attempt + 1)
  }
}

export default uploadDoubleSidedDocument
