import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 auto;
  padding: 0 16px 0 16px;

  @media screen and (min-width: 1025px) {
    padding: 0 75px 100px 75px;
  }
`
