import * as yup from 'yup'

export const registerSchema = {
  initialValues: {
    name: '',
    cpf: '',
    rg: '',
    birth: '',
    nationality: 'Brasil',
    gender: '',
    mothersName: '',
    ddi: 'Brasil',
    phone: '',
  },

  validationSchema: yup.object().shape({
    name: yup.string().required(''),
    cpf: yup.string().required(''),
    rg: yup.string().required(''),
    birth: yup
      .string()
      .required('')
      .matches(/^[0-9]+$/),
    nationality: yup.string().required(''),
    gender: yup.string().required(''),
    mothersName: yup.string().required(''),
    ddi: yup.string().required(''),
    phone: yup
      .string()
      .required('')
      .matches(/^[0-9]+$/),
  }),
}

export const addressSchema = {
  initialValues: {
    cep: '',
    address: '',
    number: '',
    complement: '',
    neighbor: '',
    city: '',
    uf: '',
  },

  validationSchema: yup.object().shape({
    cep: yup.string().required(''),
    address: yup.string().required('').max(50),
    number: yup.string().required('').max(10),
    complement: yup.string().max(30),
    neighbor: yup.string().required('').max(30),
    city: yup.string().required('').max(30),
    uf: yup.string().required('').max(2),
  }),
}
