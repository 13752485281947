import { format, isValid, parse } from 'date-fns'

export const AgeValidation = (date: string) => {
  const splitDate = date.split('/')
  const joinDate = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`

  const birthDate = Date.parse(joinDate)
  const currentDate = new Date().getTime()

  const oneDay = 1000 * 3600 * 24
  const sexteenYearsInDays = 365 * 16 + 4

  const diffInTime = currentDate - birthDate
  const diffInDays = Math.round(diffInTime / oneDay)

  if (diffInDays < sexteenYearsInDays) {
    return true
  }
  return false
}

export const dateValidation = (date: string): boolean => {
  const parsedDate = parse(date, 'dd/MM/yyyy', new Date())
  const today = format(new Date(), 'dd/MM/yyyy')

  if (parsedDate > parse(today, 'dd/MM/yyyy', new Date())) {
    return false
  }

  const isValidDate = isValid(parsedDate)
  return isValidDate
}
