import { styled } from '@mui/material'

export const Container = styled('section')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  & > .uploadBox {
    width: 100%;
    max-width: 454px;
    height: 240px;
    background: #ffffff;
    border: 1px dashed #556688;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 50px;

    .changeFile {
      font-size: 1em;
      font-weight: 400;
      color: #34a3ff;
      background: none;
      border: none;
      cursor: pointer;
      border-bottom: 1px solid #0096ff;
      line-height: 18px;
    }

    .boxDocument {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      cursor: pointer;

      & > .cta {
        width: 100%;
        background: linear-gradient(310.56deg, #2244ff 27.06%, #0099ff 100%);
        border-radius: 32px;
        align-items: center;
        color: #ffffff;
        font-size: 1em;
        font-weight: 700;
        box-shadow: none;
        border: none;
        cursor: pointer;
        margin-top: 40px;
        max-width: 112px;
        padding: 11px 0;
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          margin-top: 10px;
          font-weight: 400;
          font-size: 1em;
          line-height: 20px;
          text-align: center;
          color: #556688;
        }
      }
    }

    .boxDocumentImage {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 45px);
      background: #f1f2f3;

      .imgBox {
        width: 85%;
        height: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .boxDocumentInfos {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      background: #fff;
      width: 100%;
      height: 45px;

      .fileName {
        font-size: 1em;
        color: #576684;
        font-weight: 400;
        max-width: 250px;
      }
    }

    .boxDocumentSuccess,
    .boxDocumentError {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #f9d1d3;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 4px;

      p {
        font-size: 1.1em;
        font-weight: 600;
        line-height: 20px;
        text-align: center;
        color: #700f15;
        margin-top: 10px;
        margin-bottom: 30px;
      }

      .changeFile {
        font-weight: 700;
        font-size: 1.2em;
      }
    }

    .boxDocumentSuccess {
      background: #caf4d6;
      height: calc(100% - 45px);

      p {
        color: #124f23;
      }
    }
  }
`
