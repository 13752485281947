import { styled } from '@mui/material'

export const ButtonIcon = styled('button')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: none;
  width: 100%;
  gap: 12px;
  height: 88px;
  background: ${({ theme }) => theme.remessa.palette.common.white};
  box-shadow: 0px 1px 4px ${({ theme }) => theme.remessa.palette.common.natureBoxShaddow};
  border-radius: 5px;
  transition: 0.3s linear;
  cursor: pointer;

  :hover {
    box-shadow: 0px 2px 16px ${({ theme }) => theme.remessa.palette.common.natureBoxShaddow};
    transition: 0.3s linear;
  }

  @media screen and (min-width: ${({ theme }) => theme.remessa.breakpoints.lg}) {
    height: 88px;
    max-width: 286px;
  }

  img {
    width: 56px;
  }

  p {
    font-size: 16px;
    width: 100%;
    text-align: left;
    color: ${({ theme }) => theme.remessa.palette.common.natureCardText};
    font-weight: 400;
    @media screen and (min-width: ${({ theme }) => theme.remessa.breakpoints.lg}) {
      font-size: 14px;
    }
  }
`
