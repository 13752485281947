import styled from 'styled-components'

interface ButtonStyleProps {
  block?: boolean
  variant?: 'outline' | 'primary'
  width?: number
}

const ButtonStyle = styled.button<ButtonStyleProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.028em;
  text-align: center;
  height: 40px;
  min-width: 195px;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  margin-top: 35px;
  cursor: pointer;
  user-select: none;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  width: ${(props) => {
    if (props.block) return '100%'
    if (props.width) return `${props.width}px`
    return 'auto'
  }};

  background: ${(props) => {
    if (props.variant === 'primary') return '#006ff2'
    return '#fff'
  }};

  color: ${(props) => {
    if (props.variant === 'primary') return '#fff'
    return '#006ff2'
  }};

  border: ${(props) => {
    if (props.variant === 'primary') return '1px solid #006ff2'
    return '1px solid #006ff2'
  }};

  &:hover {
    background: ${(props) => {
      if (props.variant === 'primary') return 'rgba(0, 111, 242, 0.8)'
      return 'rgba(179, 214, 255, 0.4)'
    }};
  }
`

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  block?: boolean
  variant?: 'outline' | 'primary'
  width?: number
}

export default function Button({
  children,
  onClick,
  block = false,
  variant = 'outline',
  width,
  ...props
}: ButtonProps) {
  return (
    <ButtonStyle
      {...props} // Passa todas as props padrão para o ButtonStyle
      onClick={onClick}
      block={block}
      variant={variant}
      width={width}
    >
      {children}
    </ButtonStyle>
  )
}
