import { ThemeProvider } from '@mui/material'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from 'routes'
import GlobalStyle from 'styles/global'
import Theme from 'components/UI/Theme'

const App = () => (
  <Router>
    <ThemeProvider theme={Theme}>
      <Routes />
      <GlobalStyle />
    </ThemeProvider>
  </Router>
)

export default App
