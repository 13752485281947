import { useState, useEffect, useRef } from 'react'

import usePostMessage from '../../hooks/usePostMessage'

import MgmContainer from './Mgm'
import { Container } from './style'

interface ComponentProps {
  userToken?: string
  eventData: {
    page: object
    user: {
      user_id: string | number
    }
  }
}

const MemberGetMemberPJ = ({ userToken, eventData }: ComponentProps) => {
  const [state, setState] = useState<ComponentProps>({
    userToken,
    eventData,
  })

  const [isTokenSet, setIsTokenSet] = useState(false)
  const [lastHeight, setLastHeight] = useState(0)

  const usePostMessageHook = usePostMessage('MemberGetMemberPJ', setState)
  const observerRef = useRef<MutationObserver | null>(null)

  const iframeResize = () => {
    const currentHeight = document.documentElement.scrollHeight || document.body.scrollHeight

    if (currentHeight !== lastHeight) {
      setLastHeight(currentHeight)
      usePostMessageHook.sendMessage({
        type: 'changeMemberGetMemberPJIframeSize',
        data: currentHeight,
      })
    }
  }

  useEffect(() => {
    iframeResize()
    window.addEventListener('resize', iframeResize)
    observerRef.current = new MutationObserver(() => {
      iframeResize()
    })

    observerRef.current.observe(document.body, { childList: true, subtree: true })

    return () => {
      window.removeEventListener('resize', iframeResize)
      observerRef.current?.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.userToken) {
      localStorage.setItem('beero.customerToken', state.userToken)
      setIsTokenSet(true)
    }
  }, [state.userToken])

  useEffect(() => {
    if (state.eventData && state.eventData.page) {
      localStorage.setItem('beero.mgmEventPage', JSON.stringify(state.eventData.page))
    }
  }, [state.eventData])

  if (!isTokenSet) {
    return null
  }

  return (
    <Container>
      <MgmContainer userId="264690b5-7315-466e-981e-c5bae4e4ee79-tuctddcbca8" />
    </Container>
  )
}

export default MemberGetMemberPJ
