import UploadBox from 'components/OnBoarding/RegisterDocument/UploadBox'
import { useContext, useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import Context from 'components/OnBoarding/context'
import LegacyButton from 'components/UI/LegacyButton'
import Paragraph from 'components/UI/Typography/Paragraph'
import tracker from 'utils/tracker'
import gtmTracker from 'utils/gtmTracker'
import { PageContainer } from '../../style'
import { Container } from '../style'

const UploadFrontDocument = () => {
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)
  const context = useContext(Context)

  const isLoading = () => !!context.loading

  const handleSendButton = () => {
    tracker({ category: 'account_pf' }).click({
      label: 'send_documents_button',
    })

    const interaction = {
      type: 'pf',
    }

    const eventPage = {
      page: 'app/criar-conta',
      flow_type: 'individual',
      flow_type_tier1: 'signup',
    }

    gtmTracker({ event: 'signup_success', interaction, page: eventPage })
    context.sendDocument()
    isLoading()
  }

  useEffect(() => {
    tracker({ category: 'account_pf' }).pageView({
      label: 'upload_verse_document_page',
    })

    const interaction = {
      type: 'section',
      target: 'signup',
      label: 'verse-document',
    }

    const eventPage = {
      page: 'app/criar-conta',
      flow_type: 'individual',
      flow_type_tier1: 'signup',
    }

    gtmTracker({ event: 'view', interaction, page: eventPage })
  }, [])

  return (
    <PageContainer>
      <div className="box">
        <div className="information">
          <span className="number">3</span>
          <Paragraph fontSize={16} fontWeight={700} marginTop={0}>
            Agora envie a foto do verso do seu {context.documentType}
          </Paragraph>
        </div>

        <UploadBox
          setUploadSuccess={setUploadSuccess}
          documentSide="Verso"
          document={context.verseDocument}
          setDocument={context.setVerseDocument}
          onClick={() => {
            tracker({
              category: 'account_pf',
              eventProperties: {
                'A/B_Test_Name': 'onboarding_document_upload_new_flow',
                'A/B_Test_Group': 'Variant_B',
                side: 'back',
              },
            }).click({
              label: 'input_upload_document',
            })

            const interaction = {
              type: 'button',
              target: 'signup-verse-document',
              label: 'adicionar-verso-documento',
            }

            const eventPage = {
              page: 'app/criar-conta',
              flow_type: 'individual',
              flow_type_tier1: 'signup',
            }

            gtmTracker({ event: 'internal_click', interaction, page: eventPage })
          }}
          onClickChangeFile={(error: boolean) => {
            tracker({
              category: 'account_pf',
              eventProperties: {
                'A/B_Test_Name': 'onboarding_document_upload_new_flow',
                'A/B_Test_Group': 'Variant_B',
                side: 'back',
                error: error ? 'yes' : 'no',
              },
            }).click({
              label: 'input_upload_document',
            })

            const interaction = {
              type: 'button',
              target: 'signup-verse-document',
              label: 'alterar-verso-documento',
            }

            const eventPage = {
              page: 'app/criar-conta',
              flow_type: 'individual',
              flow_type_tier1: 'signup',
            }

            gtmTracker({ event: 'internal_click', interaction, page: eventPage })
          }}
          setImageType={context.setVerseImageType}
        />

        <LegacyButton loading={context.loading} disabled={!uploadSuccess} onClick={() => handleSendButton()}>
          Enviar documento
        </LegacyButton>

        {isLoading() && (
          <>
            <div className="information">
              <Paragraph fontSize={12} fontWeight={400} marginTop={0} fadeEffect>
                Aguarde um momento enquanto enviamos o seu documento para a análise.
              </Paragraph>
            </div>
            <Container>
              <CircularProgress className="circular-progress" size={14} />
            </Container>
          </>
        )}
      </div>
    </PageContainer>
  )
}

export default UploadFrontDocument
