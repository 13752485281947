import EventTracking from '../../interfaces/eventTracking'

/**
 * This is a window.dataLayer wrapper used for send events to analytics
 * @param {Object} baseEventData the base structure of event
 * example:
 *  {
 *    category: string
 *    action: string
 *    label: string
 *  }
 *
 * @returns {Function} function for fire events
 */

export interface DataLayerFactoryProps {
  category: string
  eventProperties?: object
  eventTimeout?: number
}

const dataLayerFactory =
  ({ category, eventProperties, eventTimeout = 1000 }: DataLayerFactoryProps): Function =>
  (baseEventData: any) =>
  /**
   * This fire events with merged events data
   * @param {Object} eventData the event values to be merged with base data
   *
   */

  (eventData: object): Promise<void> =>
    new Promise((resolve) => {
      const event = {
        category,
        eventTimeout,
        eventProperties,
        ...baseEventData,
        ...eventData,
        eventCallback: resolve,
      }

      const payload: EventTracking = {
        type: 'event',
        data: {
          event: `${event.category}.${event.action}.${event.label}`,
          ...event,
        },
      }
      if (window?.dataLayer) {
        window.dataLayer.push({
          event: `${event.category}.${event.action}.${event.label}`,
          ...event,
        })
      }
      window?.top?.postMessage(JSON.stringify(payload), '*')
    })

export default dataLayerFactory
