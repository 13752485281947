const EmailSentPopup = ({ onClose, buttonShareColor }: { onClose: () => void; buttonShareColor?: string }) => (
  <div className="popupContentEmail">
    <div className="emailSuccessContainer">
      <img src="/img/discounts-page/icons/check-success.svg" alt="" />
      <h5>E-mail enviado</h5>
      <p>
        Peça para o seu amigo verificar a caixa de entrada. Em breve ele receberá o convite com seu cupom de desconto.
      </p>
      <button style={{ backgroundColor: buttonShareColor }} type="button" className="buttonCtaEmail" onClick={onClose}>
        Fechar
      </button>
    </div>
  </div>
)

export default EmailSentPopup
