import styled from 'styled-components'

const Line = styled.div`
  border-bottom: 1px solid #e3e7ed;
  margin-bottom: 16px;
`

export default function Separator() {
  return <Line />
}
