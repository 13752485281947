import styled from 'styled-components'

const Box = styled.div`
  width: 274px;
  min-height: 350px;
  margin-bottom: 32px;
  display: flex;
  place-content: center;
`

const PaperCard = styled.section`
  width: 234px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(199, 208, 219, 0.5);
  border-radius: 4px;
  padding: 8px 12px 12px 12px;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;

  &:hover {
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.2);
  }
`
interface PaperProps {
  children: JSX.Element
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export default function Paper({ children, onClick }: PaperProps) {
  return (
    <Box onClick={onClick}>
      <PaperCard>{children}</PaperCard>
    </Box>
  )
}
