import api from 'api'

interface IgetAddressByCep {
  address: string
  cep: string
  city: string
  id_uf: string
  neighbor: string
  uf: string
  complement?: string
}

interface IpostAddressData {
  address: string
  city: string
  neighbor: string
  state: string
  str_number: string
  uf: string
  zip: string
}

const getAddressByCep = async (cep: string): Promise<IgetAddressByCep | undefined> => {
  try {
    const response = await api.get(`/v2/address/zipCode/${cep}`)
    return response.data
  } catch (error) {
    return undefined
  }
}

const postAddressData = async (addressData: IpostAddressData) => {
  const response = await api.post('/v2/customer/address', addressData)
  return response
}

const postCompletePreSignup = async () => {
  await api.post('/v2/customer/complete-signup')
}

export default {
  getAddressByCep,
  postAddressData,
  postCompletePreSignup,
}
