import { useContext, useEffect } from 'react'
import Context from 'components/OnBoarding/context'
import LegacyButton from 'components/UI/LegacyButton'
import Paragraph from 'components/UI/Typography/Paragraph'
import tracker from 'utils/tracker'
import gtmTracker from 'utils/gtmTracker'
import { PageContainer } from '../style'

const OnBoardingRegisterDocumentInformation = () => {
  const context = useContext(Context)

  const continueButton = () => {
    context.setPage(context.page + 1)
    tracker({ category: 'account_pf' }).click({
      label: 'tips_continue_button',
    })
  }

  useEffect(() => {
    const interaction = {
      type: 'section',
      target: 'signup',
      label: 'documents-tips',
    }

    tracker({ category: 'account_pf' }).pageView({
      label: 'documents_tips_page',
    })

    const eventPage = {
      page: 'app/criar-conta',
      flow_type: 'individual',
      flow_type_tier1: 'signup',
    }

    gtmTracker({ event: 'view', interaction, page: eventPage })
  }, [])

  return (
    <PageContainer>
      <div className="box" id="document-content-size">
        <div data-testid="informationInfo" className="information information--details">
          <Paragraph fontSize={16} fontWeight={400} marginTop={0}>
            Vamos precisar do seu documento de identificação por exigência do Banco Central.
          </Paragraph>
          <Paragraph fontSize={16} fontWeight={700}>
            Confira as dicas para enviar seu documento:
          </Paragraph>
        </div>

        <ul className="documentTopicList">
          <li>
            <span className="icon">
              <img src="/img/remessa-icons/check.svg" alt="check" />
            </span>
            <div className="text">
              <Paragraph fontSize={16} fontWeight={700} marginTop={0}>
                Ele deve ser oficial e válido
              </Paragraph>
            </div>
          </li>
          <li>
            <span className="icon">
              <img src="/img/remessa-icons/camera.svg" alt="camera" />
            </span>
            <div className="text">
              <Paragraph fontSize={16} fontWeight={700} marginTop={0}>
                Retire o documento do plástico e procure um lugar bem iluminado para fazer a foto
              </Paragraph>
            </div>
          </li>
          <li>
            <span className="icon">
              <img src="/img/remessa-icons/folder.svg" alt="folder" />
            </span>

            <div className="text">
              <Paragraph fontSize={16} fontWeight={700} marginTop={0}>
                A foto deve ter no máximo 10MB e ser do formato PNG, JPEG ou PDF
              </Paragraph>
            </div>
          </li>
        </ul>

        <LegacyButton data-testid="buttonCtaInformation" onClick={() => continueButton()}>
          Continuar
        </LegacyButton>
      </div>
    </PageContainer>
  )
}

export default OnBoardingRegisterDocumentInformation
