import dataLayerFactory, { DataLayerFactoryProps } from './data-layer-factory'

/**
 * tracker is a helper for send tracking
 */

interface ObjectType {
  pageView: Function
  click: Function
  blur: Function
  change: Function
  select: Function
  upload: Function
  submit: Function
  alert: Function
  referrer: Function
  identify: Function
}

const tracker = ({ category, eventProperties, eventTimeout = 1000 }: DataLayerFactoryProps): ObjectType => {
  const dataLayer = dataLayerFactory({ category, eventProperties, eventTimeout })

  return {
    /**
     * tracker method for page view events
     */

    pageView: dataLayer({ action: 'view' }),

    /**
     * tracker method for click events
     */

    click: dataLayer({ action: 'click' }),

    /**
     * tracker method for blur events
     */

    blur: dataLayer({ action: 'blur' }),

    /**
     * tracker method for change events
     */

    change: dataLayer({ action: 'change' }),

    /**
     * tracker method for select events
     */

    select: dataLayer({ action: 'select' }),

    /**
     * tracker method for upload events
     */

    upload: dataLayer({ action: 'upload' }),

    /**
     * tracker method for submit events
     */

    submit: dataLayer({ action: 'submit', companyType: '' }),

    /**
     * tracker method for alert events
     */

    alert: dataLayer({ action: 'alert' }),

    /**
     * tracker method for referrer events
     */

    referrer: dataLayer({ action: 'referrer' }),

    /**
     * tracker method for identify events
     */

    identify: dataLayer({ action: 'identify' }),
  }
}

export default tracker
