import { Container } from './style'

type Step = {
  step: number
  title: string
  description: string
}

type HowWorksProps = {
  steps: Step[]
}

const HowWorks = ({ steps }: HowWorksProps) => {
  return (
    <Container>
      <div className="titleContainer">
        <h1>Como Funciona</h1>
      </div>
      <div className="stepsContainer">
        {steps.map((step) => (
          <div key={step.step} className="step">
            <div className="badgeContainer">
              <div className="stepNumber">{step.step}</div>
              <img className="stepIcon" src="/img/discounts-page/icons/item-icon.svg" alt="" />
            </div>
            <div className="textContainer">
              <h2>{step.title}</h2>
              <p>{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </Container>
  )
}

export default HowWorks
