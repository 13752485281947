import styled from 'styled-components'
import ProgressBar from '../../../ProgressBar'

interface LimitBarProps {
  limit: string
  used: string
  currency: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Legend = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
`

export default function LimitBar({ limit, used, currency }: LimitBarProps) {
  function getPaddingClip(): string {
    return `${100 - (100 * (Number(limit) - Number(used || 0))) / Number(limit)}%`
  }

  const currencyFormatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency })

  return (
    <Container>
      <Legend>
        <div>Utilizado</div>

        <div>
          <b>{currencyFormatter.format(Number(used) || 0)}</b>
        </div>
      </Legend>

      <ProgressBar progressPercentage={getPaddingClip()} />

      <Legend>
        <div>Limite</div>

        <div>{currencyFormatter.format(Number(limit))}</div>
      </Legend>
    </Container>
  )
}
