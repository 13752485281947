import { styled } from '@mui/material'
import { ToastContainer } from 'react-toastify'
import Theme from '../Theme'

export const ToastContainerStyled = styled(ToastContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .Toastify__toast {
    background-color: ${Theme.remessa.palette.common.error};
    opacity: 85%;
    color: ${Theme.remessa.palette.common.white};
    z-index: 10;
    cursor: default;

    @media screen and (min-width: ${Theme.remessa.breakpoints.sm}) {
      width: ${Theme.remessa.breakpoints.sm};
    }
  }

  .Toastify__close-button {
    color: ${Theme.remessa.palette.common.white};
    opacity: 100%;
    margin-left: 0.5em;
    margin-top: 1.5em;
  }
`
