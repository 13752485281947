import { useContext, useEffect } from 'react'
import { RegisterContext } from 'context/register'
import tracker from 'utils/tracker'
import Paragraph from 'components/UI/Typography/Paragraph'
import { PageContainer } from '../../../../pages/Register/SendDocument/style'

const OnBoardingRegisterSelectDocument = () => {
  const context = useContext(RegisterContext)

  const handleClickDocument = (document: string) => {
    context.setDocumentType(document)
    context.setPage(context.page + 1)
    tracker({
      category: 'account_pf',
      eventProperties: {
        'A/B_Test_Name': 'onboarding_document_upload_new_flow',
        'A/B_Test_Group': 'Variant_B',
        id_type: document,
      },
    }).click({
      label: 'select_id_doc_type',
    })
  }

  useEffect(() => {
    tracker({ category: 'account_pf' }).pageView({
      label: 'select_document_page',
    })
  }, [])

  return (
    <PageContainer>
      <div className="box">
        <div data-testid="selectDocumentInfo" className="information">
          <span className="number">1</span>
          <Paragraph fontSize={16} fontWeight={700} marginTop={0}>
            Escolha um documento para enviar:
          </Paragraph>
        </div>

        <ul className="selectDocument">
          <li>
            <button data-testid="buttonCtaSelectDocumentRG" onClick={() => handleClickDocument('RG')} type="button">
              RG
            </button>
          </li>
          <li>
            <button onClick={() => handleClickDocument('CNH')} type="button">
              CNH
            </button>
          </li>
          <li>
            <button onClick={() => handleClickDocument('RNE')} type="button">
              RNE
            </button>
          </li>
        </ul>
      </div>
    </PageContainer>
  )
}

export default OnBoardingRegisterSelectDocument
