import NatureRegister from 'components/Register/NatureRegister'
import TemplateRegister from 'components/Templates/TemplateRegister'
import RegisterModal from 'components/UI/Modals/RegisterModal'
import { useState } from 'react'
import { Title, Subtitle, Container, TitleAndSubtitleContainer, ContentContainer } from './style'

export default function NaturePage() {
  const [open, setOpen] = useState(false)

  const handleOpen = async () => {
    setOpen((prev) => !prev)
  }

  return (
    <TemplateRegister handleCloseIcon={handleOpen}>
      {open && <RegisterModal show={open} handleState={handleOpen} />}
      <ContentContainer>
        <Container>
          <TitleAndSubtitleContainer>
            <Title>Qual seria o motivo do seu cadastro? Escolha o mais adequado para sua rotina =)</Title>
            <Subtitle>Queremos conhecer você e ajudar com a melhor experiência.</Subtitle>
          </TitleAndSubtitleContainer>
          <NatureRegister />
        </Container>
      </ContentContainer>
    </TemplateRegister>
  )
}
