import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Okta Neue';
  color: var(--rods-color-text-primary);

  & > .titleContainer {
    display: flex;
    width: 100%;
    padding: 16px 0;
    align-items: center;
    border-bottom: 1px solid var(--rods-color-border);

    & > h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
    }
  }

  & > .rules {
    display: flex;
    flex-direction: column;
    padding: 32px 0;
    align-items: flex-start;
    gap: 40px;

    & > .rule {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      width: 100%;

      & > p {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  @media (min-width: 1025px) {
    flex-direction: column;
    padding: 0;

    & > .titleContainer {
      width: 100%;
      flex-direction: colu;
      padding: 16px 0;
      border-bottom: 1px solid var(--rods-color-border);
    }

    & > .rules {
      flex-direction: row;
      padding: 32px 0;
      gap: 40px;

      & > .rule {
        width: 400px;
        flex-direction: column;
        & > p {
          font-size: 14px;
        }
      }
    }
  }
`
