import { useEffect, useState } from 'react'

import * as yup from 'yup'
import { useFormik } from 'formik'
import { MenuItem } from '@mui/material'
import InputMask from 'react-input-mask'
import Button from 'components/UI/Button'
import gtmTracker from 'utils/gtmTracker'
import usePostMessage from 'hooks/usePostMessage'
import { Status } from 'interfaces/Register/customer'
import { RegisterInputProps } from 'components/UI/Theme'
import addressService from 'services/Register/address.service'
import customerService from 'services/Register/customer.service'

import {
  Container,
  FormGroup,
  CustomTextField,
  FormGroupContent,
  FormGroupTitle,
  Content,
  ButtonContainer,
  CompletedContainer,
  CompletedTitle,
  CompletedImage,
  FormGroupPanel,
  ToastContainer,
  Form,
  Loader,
} from './style'

interface Option {
  label: string
  value: string
}

const patrimonyOption: Option[] = [
  { value: '50000.000000', label: 'Até R$ 50.000,00' },
  { value: '100000.000000', label: 'de 50.001,00 até R$ 100.000,00' },
  { value: '250000.000000', label: 'de 100.001,00 até R$ 250.000,00' },
  { value: '500000.000000', label: 'de R$ 250.001,00 até R$ 500.000,00' },
  { value: '1000000.000000', label: 'de R$ 500.001,00 até R$ 1.000.000,00' },
  { value: '1000001.000000', label: 'Acima de R$ 1.000.000,00' },
]

const montlyIncomeOption: Option[] = [
  { value: '3000.000000', label: 'Até R$ 3.000,00' },
  { value: '10000.000000', label: 'de 3.001,00 até R$ 10.000,00' },
  { value: '22000.000000', label: 'de 10.001,00 até R$ 22.000,00' },
  { value: '30000.000000', label: 'de R$ 22.001,00 até R$ 30.000,00' },
  { value: '30001.000000', label: 'Acima de R$ 30.000,00' },
]

const statesOptions: Option[] = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
]

const initialValues = {
  country_code: '',
  mobile_phone: '',
  location: {
    zip: '',
    address: '',
    neighbor: '',
    str_number: '',
    city: '',
    state: '',
    complement: '',
  },
  financial: {
    monthly_income: '',
    patrimony: '',
    social_security_number: '',
    american_fiscal_resident: false,
  },
}

const clrformat = (value?: string) => (value || '').replace(/[\s+_()-]+/g, '')

const validationSchema = yup.object().shape({
  country_code: yup
    .string()
    .required('Campo obrigatório')
    .test('country_code', 'Campo deve ser totalmente preenchido', (value) => {
      return clrformat(value).length <= 3
    }),

  mobile_phone: yup.string().when('country_code', {
    is: (code: string) => code === '+55',
    then: yup
      .string()
      .required('Campo obrigatório')
      .test('mobile_phone', 'Campo deve ser totalmente preenchido', (value) => {
        return /\d{11,}/.test(clrformat(value))
      }),
    otherwise: yup.string().required('Campo obrigatório'),
  }),
  location: yup.object().shape({
    zip: yup
      .string()
      .required('Campo obrigatório')
      .test('zip', 'Campo deve ser totalmente preenchido', (value) => {
        return /\d{8}/.test(clrformat(value))
      }),
    address: yup.string().required('Campo obrigatório').max(255, 'Limite máximo de 255 caracteres'),
    neighbor: yup.string().required('Campo obrigatório').max(255, 'Limite máximo de 255 caracteres'),
    str_number: yup.string().required('Campo obrigatório').max(255, 'Limite máximo de 255 caracteres'),
    city: yup.string().required('Campo obrigatório').max(255, 'Limite máximo de 255 caracteres'),
    state: yup.string().required('Campo obrigatório').max(255, 'Limite máximo de 255 caracteres'),
    complement: yup.string().max(255, 'Limite máximo de 255 caracteres'),
  }),
  financial: yup.object().shape({
    monthly_income: yup.number().required('Campo obrigatório'),
    patrimony: yup.number().required('Campo obrigatório'),
    american_fiscal_resident: yup.boolean(),
    social_security_number: yup.string().when('american_fiscal_resident', {
      is: true,
      then: yup
        .string()
        .required('Campo obrigatório')
        .matches(/^\d{3}-\d{2}-\d{4}$/, 'Campo deve ser totalmente preenchido'),
      otherwise: yup.string().notRequired(),
    }),
  }),
})

export interface EventData {
  page: object
  user: {
    user_id: string | number
  }
}
interface ComponentProps {
  userToken?: string
  eventData: EventData
}

const UserUpdate = ({ userToken, eventData }: ComponentProps) => {
  const [customer, setCustomer] = useState<any>({})
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [completed, setCompleted] = useState<boolean>(false)
  const [registrationStatus, setRegistrationStatus] = useState<Status>()
  const [props, setProps] = useState<ComponentProps>({ userToken, eventData })
  const usePostMessageHook = usePostMessage('UserUpdate', setProps)

  const onSubmit = async (data: any) => {
    try {
      setLoading(true)
      gtmTracker({
        ...eventData,
        event: 'internal_click',
        interaction: {
          target: 'atualizacao-cadastral',
          type: 'section',
          label: 'confirmar-dados',
        },
      })

      const {
        location,
        mobile_phone: mobilePhone,
        country_code: countryCode,
        financial: { american_fiscal_resident: americanFiscalResident, ...financial },
        ...rest
      } = data

      await addressService.postAddressData({ ...customer.addressList[0], ...location })
      await customerService.createOrUpdateRegistratioForm({
        ...financial,
        social_security_number: /^\d{3}-\d{2}-\d{4}$/.test(financial.social_security_number)
          ? financial.social_security_number
          : null,
      })
      await customerService.updateCustomerData({
        ...customer,
        ...rest,
        mobile_phone: clrformat(mobilePhone),
        country_code: `+${clrformat(countryCode)}`,
        american_fiscal_resident: americanFiscalResident,
      })

      if (registrationStatus?.status !== 'COMPLETE') {
        await customerService.confirmCustomerPolicy([registrationStatus?.id])
      }

      setCompleted(true)
      usePostMessageHook.sendMessage({ type: 'scrollTop' })
    } catch {
      setError(true)
      usePostMessageHook.sendMessage({ type: 'scrollTop' })
    } finally {
      setLoading(false)
    }
  }

  const redirectToDashboard = () => {
    usePostMessageHook.sendMessage({ type: 'redirectToDashboard' })
  }

  const getOptionValue = (options: Option[], value: string, defaultVal?: string): string => {
    return options.find((opt) => opt.value === value)?.value || (defaultVal ?? options[0]?.value)
  }

  const clrstg = (str: string): string => {
    return (
      str
        ?.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-zA-Z0-9\s]/g, '') || ''
    )
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  const { values, handleBlur, handleChange, errors } = formik

  const loadCustomerData = async () => {
    try {
      const data = await customerService.getCustomerData()
      const policy = await customerService.getCustomerPolicy()
      const financial = await customerService.getRegistratioForm()

      setCustomer(data)
      setRegistrationStatus(policy?.find(({ context }) => context === 'registration-update'))

      const patrimony = getOptionValue(patrimonyOption, financial?.patrimony)
      const state = getOptionValue(statesOptions, data.addressList[0]?.state, '')
      const monthlyIncome = getOptionValue(montlyIncomeOption, financial?.monthly_income)

      formik.setFieldValue('country_code', data.country_code)
      formik.setFieldValue('mobile_phone', data.mobile_phone)
      formik.setFieldValue('location.zip', data.addressList[0]?.zip)
      formik.setFieldValue('location.address', clrstg(data.addressList[0]?.address))
      formik.setFieldValue('location.neighbor', clrstg(data.addressList[0]?.neighbor))
      formik.setFieldValue('location.str_number', clrstg(data.addressList[0]?.str_number))
      formik.setFieldValue('location.city', clrstg(data.addressList[0]?.city))
      formik.setFieldValue('location.state', state)
      formik.setFieldValue('location.complement', clrstg(data.addressList[0]?.complement))
      formik.setFieldValue('financial.monthly_income', monthlyIncome)
      formik.setFieldValue('financial.patrimony', patrimony)
      formik.setFieldValue('financial.american_fiscal_resident', data.american_fiscal_resident)
      formik.setFieldValue('financial.social_security_number', financial?.social_security_number || '')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (props.userToken) {
      localStorage.setItem('beero.customerToken', props.userToken)
    }
    loadCustomerData()
  }, [props.userToken])

  useEffect(() => {
    if (props.eventData && props.eventData.page) {
      localStorage.setItem('beero.mgmEventPage', JSON.stringify(props.eventData.page))
    }
  }, [props.eventData])

  useEffect(() => {
    gtmTracker({
      ...eventData,
      event: 'page_view',
      interaction: {
        target: 'atualizacao-cadastral',
        type: 'section',
        label: 'dados-cadastrais',
      },
    })
  }, [])

  return (
    <Container>
      <Content>
        {completed ? (
          <CompletedContainer>
            <CompletedImage src="/img/remessa-icons/green-check.svg" alt="icone de check verde" />
            <CompletedTitle>Dados confirmados com sucesso!</CompletedTitle>
            <Button variant="primary" width={300} onClick={redirectToDashboard}>
              Ir para a home
            </Button>
          </CompletedContainer>
        ) : (
          <Form>
            {error && (
              <ToastContainer>
                <img src="/img/remessa-icons/white-info.svg" alt="icone info" />
                Algo deu errado. Por favor, tente novamente mais tarde.
              </ToastContainer>
            )}
            <h1>Confirme seus dados cadastrais</h1>
            <FormGroup>
              <FormGroupTitle>Telefone</FormGroupTitle>
              <FormGroupPanel>
                <FormGroupContent>
                  <InputMask mask="+999" onBlur={handleBlur} onChange={handleChange} value={values.country_code}>
                    {(inputProps: any) => (
                      <CustomTextField
                        {...inputProps}
                        id="country_code"
                        label="DDI"
                        variant="standard"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.country_code}
                        helperText={errors.country_code}
                      />
                    )}
                  </InputMask>
                </FormGroupContent>
                <FormGroupContent>
                  {clrformat(values.country_code) === '55' ? (
                    <InputMask
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mobile_phone}
                      mask="(99) 99999-9999"
                    >
                      {(inputProps: any) => (
                        <CustomTextField
                          {...inputProps}
                          id="mobile_phone"
                          label="Número"
                          variant="standard"
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          InputProps={RegisterInputProps.inputProps}
                          error={!!errors.mobile_phone}
                          helperText={errors.mobile_phone}
                        />
                      )}
                    </InputMask>
                  ) : (
                    <CustomTextField
                      id="mobile_phone"
                      label="Número"
                      variant="standard"
                      margin="normal"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={clrformat(values.mobile_phone)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={RegisterInputProps.inputProps}
                      error={!!errors.mobile_phone}
                      helperText={errors.mobile_phone}
                      onKeyDown={(event) => {
                        const isNotNumber = /\D/.test(event.key)
                        const isBackspace = event.key === 'Backspace'
                        if (isNotNumber && !isBackspace) {
                          event.preventDefault()
                        }
                      }}
                    />
                  )}
                </FormGroupContent>
              </FormGroupPanel>
            </FormGroup>
            <FormGroup>
              <FormGroupTitle>Endereço</FormGroupTitle>
              <FormGroupPanel>
                <FormGroupContent>
                  <InputMask mask="99999-999" onBlur={handleBlur} onChange={handleChange} value={values.location.zip}>
                    {(inputProps: any) => (
                      <CustomTextField
                        {...inputProps}
                        id="location.zip"
                        label="CEP"
                        variant="standard"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.location?.zip}
                        helperText={errors.location?.zip}
                        InputProps={RegisterInputProps.inputProps}
                      />
                    )}
                  </InputMask>
                </FormGroupContent>
                <FormGroupContent>
                  <CustomTextField
                    id="location.address"
                    label="Rua"
                    variant="standard"
                    margin="normal"
                    value={values.location.address}
                    error={!!errors.location?.address}
                    helperText={errors.location?.address}
                    InputLabelProps={{ shrink: true }}
                    InputProps={RegisterInputProps.inputProps}
                    onBlur={(event) => {
                      handleBlur({
                        target: {
                          id: 'location.address',
                          value: clrstg(event.target.value),
                        },
                      })
                    }}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          id: 'location.address',
                          value: clrstg(event.target.value),
                        },
                      })
                    }}
                  />
                  <CustomTextField
                    id="location.neighbor"
                    label="Bairro"
                    variant="standard"
                    margin="normal"
                    value={values.location.neighbor}
                    error={!!errors.location?.neighbor}
                    helperText={errors.location?.neighbor}
                    InputLabelProps={{ shrink: true }}
                    InputProps={RegisterInputProps.inputProps}
                    onBlur={(event) => {
                      handleBlur({
                        target: {
                          id: 'location.neighbor',
                          value: clrstg(event.target.value),
                        },
                      })
                    }}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          id: 'location.neighbor',
                          value: clrstg(event.target.value),
                        },
                      })
                    }}
                  />
                  <CustomTextField
                    id="location.city"
                    label="Cidade"
                    variant="standard"
                    margin="normal"
                    value={values.location.city}
                    error={!!errors.location?.city}
                    helperText={errors.location?.city}
                    InputLabelProps={{ shrink: true }}
                    InputProps={RegisterInputProps.inputProps}
                    onBlur={(event) => {
                      handleBlur({
                        target: {
                          id: 'location.city',
                          value: clrstg(event.target.value),
                        },
                      })
                    }}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          id: 'location.city',
                          value: clrstg(event.target.value),
                        },
                      })
                    }}
                  />
                  <CustomTextField
                    id="location.str_number"
                    label="Número"
                    variant="standard"
                    margin="normal"
                    value={values.location.str_number}
                    error={!!errors.location?.str_number}
                    helperText={errors.location?.str_number}
                    InputLabelProps={{ shrink: true }}
                    InputProps={RegisterInputProps.inputProps}
                    onBlur={(event) => {
                      handleBlur({
                        target: {
                          id: 'location.str_number',
                          value: clrstg(event.target.value),
                        },
                      })
                    }}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          id: 'location.str_number',
                          value: clrstg(event.target.value),
                        },
                      })
                    }}
                  />
                  <CustomTextField
                    id="location.complement"
                    label="Complemento"
                    variant="standard"
                    margin="normal"
                    value={values.location.complement}
                    error={!!errors?.location?.complement}
                    helperText={errors?.location?.complement}
                    InputLabelProps={{ shrink: true }}
                    InputProps={RegisterInputProps.inputProps}
                    onBlur={(event) => {
                      handleBlur({
                        target: {
                          id: 'location.complement',
                          value: clrstg(event.target.value),
                        },
                      })
                    }}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          id: 'location.complement',
                          value: clrstg(event.target.value),
                        },
                      })
                    }}
                  />
                  <CustomTextField
                    name="location.state"
                    label="Estado"
                    select
                    variant="standard"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.location.state}
                    error={!!errors.location?.state}
                    inputProps={RegisterInputProps.inputProps}
                  >
                    {statesOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </FormGroupContent>
              </FormGroupPanel>
            </FormGroup>
            <FormGroup>
              <FormGroupTitle>Dados Financeiros</FormGroupTitle>
              <FormGroupPanel>
                <FormGroupContent>
                  <CustomTextField
                    select
                    name="financial.monthly_income"
                    label="Renda mensal"
                    variant="standard"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.financial.monthly_income}
                    error={!!errors.financial?.monthly_income}
                    inputProps={RegisterInputProps.inputProps}
                  >
                    {montlyIncomeOption.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </FormGroupContent>
                <FormGroupContent>
                  <CustomTextField
                    select
                    name="financial.patrimony"
                    label="Patrimônio"
                    variant="standard"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.financial.patrimony}
                    error={!!errors.financial?.patrimony}
                    inputProps={RegisterInputProps.inputProps}
                  >
                    {patrimonyOption.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </FormGroupContent>
                <FormGroupContent>
                  <CustomTextField
                    select
                    name="financial.american_fiscal_resident"
                    label="Residente no EUA?"
                    variant="standard"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.financial.american_fiscal_resident}
                    inputProps={RegisterInputProps.inputProps}
                  >
                    <MenuItem value="true">Sim</MenuItem>
                    <MenuItem value="false">Não</MenuItem>
                  </CustomTextField>
                </FormGroupContent>
                {/* @ts-ignore */}
                {String(values.financial.american_fiscal_resident) === 'true' && (
                  <FormGroupContent>
                    <InputMask
                      mask="999-99-9999"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.financial.social_security_number}
                    >
                      {(inputProps: any) => (
                        <CustomTextField
                          {...inputProps}
                          id="financial.social_security_number"
                          label="Social Security Number (SSN)"
                          variant="standard"
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          error={!!errors?.financial?.social_security_number}
                          helperText={errors?.financial?.social_security_number}
                          InputProps={RegisterInputProps.inputProps}
                        />
                      )}
                    </InputMask>
                  </FormGroupContent>
                )}
              </FormGroupPanel>
            </FormGroup>
            <ButtonContainer>
              <Button
                variant="primary"
                width={300}
                type="submit"
                className="btLoading"
                onClick={() => {
                  if (formik.isValid) {
                    formik.handleSubmit()
                  } else {
                    formik.validateForm()
                    usePostMessageHook.sendMessage({ type: 'scrollTop' })
                  }
                }}
              >
                Confirmar dados
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Content>
      {loading && (
        <Loader>
          <img src="/img/remessa-icons/loading.svg" alt="icone loading" />
        </Loader>
      )}
    </Container>
  )
}

export default UserUpdate
