/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-no-comment-textnodes */
import styled from 'styled-components'
import { format } from 'date-fns'
import Separator from 'components/UI/Separator'
import Paragraph from 'components/UI/Typography/Paragraph'
import LimitBar from 'components/UI/History/OutboundHistory/LimitBar'
import Badge from 'components/UI/Badge'
import Paper from 'components/UI/Surfaces/Paper'
import Button from 'components/UI/Button'
import { HistoryStatuses } from '../models/HistoryStatuses'
import { History } from '../models/History'
import HistoryHeader from '../HistoryHeader/HistoryHeader'

interface OutboundHistoryProps {
  history: History
  statuses: HistoryStatuses
  usePostMessageHook: any
}

const Title = styled.h2`
  font-size: 16px;
  line-height: 1.3125;
  margin-bottom: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #404b6a;
`

const SendIcon = styled.div`
  display: flex;
  margin-left: 10px;
`

const LimitStatus = styled.div`
  margin-top: 15px;
  margin-bottom: -15px;
  height: 43px;
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`
const SubTitle = styled.div`
  margin-bottom: 3px;
  font-size: 12px;
  color: #737373;
`
const FormattedText = styled.div`
  font-size: 16px;
  color: #568;
`

const WarningIcon = styled.img`
  position: absolute;
  top: -12px;
  left: -12px;
`

export default function OutboundHistory({ history, statuses, usePostMessageHook }: OutboundHistoryProps) {
  function sendHistory() {
    usePostMessageHook.sendMessage({
      type: 'sendHistory',
      data: history,
    })
  }

  function historyClick(outboundHistory: History) {
    usePostMessageHook.sendMessage({
      type: 'historyClick',
      data: outboundHistory,
    })
  }

  function updateHistoryData() {
    usePostMessageHook.sendMessage({
      type: 'updateHistoryData',
      data: history,
    })
  }

  function isApproved() {
    return Number(history.status) === statuses.approved.value
  }

  function isDenied() {
    return Number(history.status) === statuses.denied.value
  }

  function isFinished() {
    return Number(history.status) === statuses.finished.value
  }

  function isExpired() {
    return Number(history.status) === statuses.expired.value
  }

  function isUnderAnalysis() {
    return Number(history.status) === statuses.under_analysis.value
  }

  function isPending() {
    return Number(history.status) === statuses.pending.value
  }

  function isAwaitingDocuments() {
    return Number(history.status) === statuses.waiting_documents.value
  }

  function pendingDoc() {
    return Number(history.status) === statuses.pending_doc.value
  }

  function getRemainingOperations() {
    if (history.operations_count) {
      return Number(history.qty_operations_allowed) - Number(history.operations_count)
    }
    return history.qty_operations_allowed
  }

  function getRemainingValue() {
    if (history.limit) {
      if (history.id_currency_limit) {
        return (Number(history.limit) - Number(history.total_quantity || 0)).toLocaleString('pt-br', {
          style: 'currency',
          currency: history.currencyObj.abbreviation,
        })
      }

      return (Number(history.limit) - Number(history.total_amount || 0)).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
    }

    return Number(history.global_limit || history.customerObj.remittance_limit).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })
  }

  function getButtonDetails() {
    const hasMissingFields = (history.missing_fields || []).length
    const hasAddressMissingFields = (history.address_missing_fields || []).length

    const triggerActionMap = {
      needsUpdateMissingFields: {
        textAndButton: {
          actionDescription: 'Atualizar dados',
          action: () => updateHistoryData(),
          showWarning: true,
        },
        validation: hasMissingFields && hasAddressMissingFields,
      },

      needsUpdateAddressMandatory: {
        textAndButton: {
          actionDescription: 'Adicionar endereço',
          action: () => updateHistoryData(),
          showWarning: true,
          eventElement: 'buttonAddAddress',
        },
        validation: hasAddressMissingFields,
      },
    }

    const triggerActionIterator = Object.values(triggerActionMap)

    const defaultState = {
      textAndButton: {
        actionDescription: 'Enviar remessa',
        action: (e: any) => {
          e.stopPropagation()
          sendHistory()
        },
        showWarning: false,
      },
    }

    const triggerAction = triggerActionIterator.find((trigger) => !!trigger.validation) || defaultState

    return triggerAction.textAndButton
  }

  const buttonDetails = getButtonDetails()

  function renderOperationAllowed() {
    const hasOperationAllowed = typeof history.qty_operations_allowed !== 'undefined'
    const remainingOperations = getRemainingOperations()
    const remainingValue = getRemainingValue()

    if (hasOperationAllowed && remainingOperations > 0) {
      const remainingOperationMessage =
        remainingOperations === 1 ? '1 envio disponível' : `${remainingOperations} envios disponíveis`

      return (
        <Paragraph>
          Você possui {remainingOperationMessage} podendo totalizar {remainingValue}
        </Paragraph>
      )
    }

    return (
      <Paragraph>
        Você possui um número ilimitado de remessas nesta história, podendo totalizar {remainingValue}
      </Paragraph>
    )
  }

  function renderLimitBar() {
    if (history.id_currency_limit) {
      return (
        <LimitBar
          limit={history.limit}
          used={history.total_quantity}
          currency={history.currencyObj.abbreviation || 'BRL'}
        />
      )
    }

    return (
      <LimitBar
        limit={history.limit}
        used={history.total_amount}
        currency={history.currencyObj.abbreviation || 'BRL'}
      />
    )
  }

  function renderTextByStatus() {
    if (isUnderAnalysis() || isPending() || isAwaitingDocuments() || pendingDoc())
      return (
        <>
          <FlexColumn>
            <SubTitle>Solicitada em</SubTitle>
            <FormattedText>{format(new Date(history.created_at), 'dd/MM/yyyy')}</FormattedText>
          </FlexColumn>

          {isUnderAnalysis() && <Paragraph>2 horas úteis para análise.</Paragraph>}
          {isPending() && <Paragraph>2 horas úteis para análise após resolução.</Paragraph>}
          {(isAwaitingDocuments() || pendingDoc()) && (
            <Paragraph>Por favor, envie os documentos solicitados para aprovação da sua História.</Paragraph>
          )}
        </>
      )
    if (isApproved())
      return (
        <>
          <Badge>Aprovada</Badge>
          {renderOperationAllowed()}
          {history.limit && <LimitStatus>{renderLimitBar()}</LimitStatus>}
        </>
      )

    if (isDenied())
      return (
        <>
          {history.created_at !== history.updated_at && (
            <FlexColumn>
              <SubTitle>Atualizada em</SubTitle>
              <FormattedText>{format(new Date(history.updated_at), 'dd/MM/yyyy')}</FormattedText>
            </FlexColumn>
          )}
        </>
      )

    if (isFinished()) {
      const hasOperationCount = typeof history.operations_count !== 'undefined'
      const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: history.currencyObj.abbreviation || 'BRL',
      })

      return (
        <>
          {hasOperationCount && (
            <FlexColumn>
              <SubTitle>Operações realizadas</SubTitle>
              <FormattedText>{history.operations_count}</FormattedText>
            </FlexColumn>
          )}

          <FlexColumn>
            <SubTitle>Total operado</SubTitle>
            <FormattedText>{history.operations_net && currencyFormatter.format(history.operations_net)}</FormattedText>
          </FlexColumn>
        </>
      )
    }
    if (isExpired())
      return (
        <>
          {history.created_at !== history.updated_at && (
            <FlexColumn>
              <SubTitle>Atualizada em</SubTitle>
              <FormattedText>{format(new Date(history.updated_at), 'dd/MM/yyyy')}</FormattedText>
            </FlexColumn>
          )}

          <FlexColumn>
            <SubTitle>Operações realizadas</SubTitle>
            <FormattedText>{history.operations_count}</FormattedText>
          </FlexColumn>
        </>
      )

    return <></>
  }

  function deleteCard(e: any) {
    e.preventDefault()
    e.stopPropagation()

    usePostMessageHook.sendMessage({
      type: 'deleteHistory',
      data: history,
    })
  }

  return (
    <Paper onClick={() => historyClick(history)}>
      <>
        {buttonDetails.showWarning && isApproved() && (
          <WarningIcon height={24} width={24} src="/img/remessa-icons/warning.svg" alt="warning" />
        )}
        <section>
          <HistoryHeader
            title="ENVIO"
            icon={<img height={11} width={8} src="/img/remessa-icons/arrowHeader.svg" alt="icone de seta" />}
            flag={
              <img
                src={history.recipientObj?.countryObj.flag}
                alt={history.recipientObj?.countryObj.name}
                title={history.recipientObj?.countryObj.name}
              />
            }
            onDeleteClick={deleteCard}
          />

          <Separator />

          <section>
            <Title>{history.name || 'Sem nome'}</Title>
            {renderTextByStatus()}
          </section>
        </section>

        <footer>
          {isApproved() && (
            <Button onClick={buttonDetails.action}>
              <>
                {buttonDetails.actionDescription}
                <SendIcon>
                  <img height={14} width={14} src="/img/remessa-icons/arrowSend.svg" alt="icone de seta" />
                </SendIcon>
              </>
            </Button>
          )}
        </footer>
      </>
    </Paper>
  )
}
