export const natures = [
  {
    id: 1,
    type: 'Movimentação minha conta',
    selectedNature: 'MY_ACCOUNT',
    natureId: 4,
    imgPath: '/img/nature-icons/Finalidade.svg',
    phrase: 'Movimentar conta corrente no exterior',
  },
  {
    id: 2,
    type: 'Transferência para terceiro',
    selectedNature: 'TRANSFER_TO_THIRD_PARTIES',
    natureId: 6,
    imgPath: '/img/nature-icons/Dependente.svg',
    phrase: 'Enviar para familiar ou conhecido',
  },
  {
    id: 3,
    type: 'Transferência para corretora',
    selectedNature: 'TRANSFER_TO_BROKER',
    natureId: 7,
    imgPath: '/img/nature-icons/Investir.svg',
    phrase: 'Investir com corretora',
  },
  {
    id: 4,
    type: 'Receber AdSense',
    selectedNature: 'ADSENSE',
    natureId: 40,
    imgPath: '/img/nature-icons/AdSense.svg',
    phrase: 'Receber Google AdSense',
  },
  {
    id: 5,
    type: 'Educação',
    selectedNature: 'EDUCATION',
    natureId: 5,
    imgPath: '/img/nature-icons/Educacao.svg',
    phrase: 'Pagar curso ou faculdade',
  },
  {
    id: 6,
    type: 'Produto ou serviço',
    selectedNature: 'PRODUCT_OR_SERVICE',
    natureId: 11,
    imgPath: '/img/nature-icons/ProdutosServicos.svg',
    phrase: 'Pagar produto ou serviço',
  },
  {
    id: 7,
    type: 'Imóvel',
    selectedNature: 'REAL_ESTATE',
    natureId: 15,
    imgPath: '/img/nature-icons/Imovel.svg',
    phrase: 'Compra, venda ou aluguel de imóvel',
  },
  {
    id: 8,
    type: 'Aporte em empresa',
    selectedNature: 'INVESTMENT',
    natureId: 25,
    imgPath: '/img/nature-icons/Aporte.svg',
    phrase: 'Aporte em empresas ',
  },
  {
    id: 9,
    type: 'Tratamento de saúde',
    selectedNature: 'MEDICAL_TREATMENT',
    natureId: 14,
    imgPath: '/img/nature-icons/Health.svg',
    phrase: 'Pagar tratamento de saúde',
  },
  {
    id: 10,
    type: 'Outros',
    selectedNature: 'OTHERS',
    natureId: 0,
    imgPath: '/img/nature-icons/Outros.svg',
    phrase: 'Outros',
  },
]
