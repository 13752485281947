import { useEffect, useState, useCallback } from 'react'
import Banner from '../Generic/banner'
import { MgmBannerProps, MgmYourDiscountDefaultProps, MgmHowWorksProps } from '../enum'
import YourDiscount from '../Generic/yourDiscount'
import DiscountsService from '../../../services/Discounts/discounts.service'
import HowWorks from './howWorks'
import InviteList from './inviteList'
import { Container, Tip } from './style'

interface MgmContainerProps {
  userId: string | number
}

interface Step {
  step: number
  title: string
  description: string
}

interface Invite {
  id: number
  name: string
  email: string
  spread_discount_earned: string
  start_date: string
  end_date: string
}

const MgmContainer = ({ userId }: MgmContainerProps) => {
  const [yourDiscountProps, setYourDiscountProps] = useState(MgmYourDiscountDefaultProps)
  const [maximumDiscount, setMaximumDiscount] = useState<string>('')
  const [steps, setSteps] = useState<Step[]>([])
  const [invites, setInvites] = useState<Invite[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [resultsPerPage, setResultsPerPage] = useState<number>(5)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [totalInvites, setTotalInvites] = useState<number>(0)
  const [mgmCode, setMgmCode] = useState<string>('')
  const [mgmVoucherDiscount, setMgmVoucherDiscount] = useState<string>('')

  const fetchMgmInfo = useCallback(async () => {
    try {
      const info = await DiscountsService.getMgmInfo({ userId })
      const isElegible = !!info.mgmCurrentDiscount && +info.mgmCurrentDiscount > 0
      const discountValue = parseFloat(info.mgmCurrentDiscount).toFixed(0)
      const tag = isElegible ? `${discountValue}% de desconto` : MgmYourDiscountDefaultProps.discountTag

      setYourDiscountProps({
        elegible: isElegible,
        discountTag: tag,
      })

      setMaximumDiscount(info.maximumDiscount)
      setMgmCode(info.mgmCode)
      setMgmVoucherDiscount(info.mgmVoucherDiscount)

      const generatedSteps = MgmHowWorksProps(info.discountIncrement, info.mgmVoucherDiscount, info.validMonthlyPeriod)
      setSteps(generatedSteps)
    } catch {
      setYourDiscountProps(MgmYourDiscountDefaultProps)
    }
  }, [userId])

  const fetchInvitesList = useCallback(async () => {
    try {
      const response = await DiscountsService.getMgmInvitesList({ userId, page: currentPage, limit: resultsPerPage })
      const newInvites = response.data || []
      setTotalInvites(response.meta.total_items)
      setInvites((prev) => [...prev, ...newInvites])
      setHasMore(response.meta.current_page < response.meta.total_pages)
    } catch {
      setInvites([])
      setHasMore(false)
    }
  }, [userId, currentPage, resultsPerPage])

  const loadMore = () => {
    if (hasMore) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }

  const handleResultsPerPageChange = (limit: number) => {
    setResultsPerPage(limit)
    setCurrentPage(1)
  }

  useEffect(() => {
    fetchMgmInfo()
    fetchInvitesList()
  }, [fetchMgmInfo, fetchInvitesList])

  return (
    <Container>
      <Banner
        title={MgmBannerProps.title}
        description={MgmBannerProps.description(maximumDiscount)}
        image={MgmBannerProps.image}
        mgmCode={mgmCode}
        mgmVoucherDiscount={mgmVoucherDiscount}
      />
      <YourDiscount {...yourDiscountProps} />
      <HowWorks steps={steps} />
      <InviteList
        invites={invites}
        loadMore={loadMore}
        hasMore={hasMore}
        resultsPerPage={resultsPerPage}
        onResultsPerPageChange={handleResultsPerPageChange}
        totalInvites={totalInvites}
      />
      <Tip>*Descontos não acumulativos</Tip>
    </Container>
  )
}

export default MgmContainer
