import { Container } from './styles'

interface IFormContainer {
  children: React.ReactNode
  onSubmit: (e: React.ChangeEvent<any>) => void
}

export default function FormContainer({ children, onSubmit }: IFormContainer) {
  return <Container onSubmit={onSubmit}>{children}</Container>
}
