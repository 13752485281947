import React from 'react'
import { Switch } from 'react-router-dom'
import Route from 'routes/Route'
import { RegisterProvider } from 'context/register'
import HistoryList from 'components/UI/History/HistoryList'
import OnBoardingRegisterDocument from 'components/OnBoarding/RegisterDocument'
import FinishRegistration from 'components/Dashboard/FinishRegistration/FinishRegistration'
import TransferenceInformation from 'components/Transference/Information'
import DiscountBoxInfomation from 'components/DiscountBoxInformation'
import PersonalDataPage from 'pages/Register/PersonalDataPage'
import AddressPage from 'pages/Register/AddressPage'
import SendDocumentPage from 'pages/Register/SendDocument'
import NaturePage from 'pages/Register/NaturePage'
import DiscountsPages from 'components/DiscountsPage'
import UserUpdate from 'components/UserUpdate'
import MemberGetMemberPJPage from 'pages/MemberGetMemberPJ'

const Routes: React.FC = () => (
  <Switch>
    <Route path="/criar-conta/natureza" component={NaturePage} provider={RegisterProvider} exact />
    <Route path="/criar-conta/dados-pessoais" component={PersonalDataPage} provider={RegisterProvider} exact />
    <Route path="/criar-conta/endereco" component={AddressPage} provider={RegisterProvider} exact />
    <Route path="/criar-conta/envio-de-documento" component={SendDocumentPage} provider={RegisterProvider} exact />
    <Route path="/components/dashboard/finish-registration" component={FinishRegistration} exact />
    <Route path="/components/dashboard/historylist" component={HistoryList} exact />
    <Route path="/components/onboarding/document" component={OnBoardingRegisterDocument} exact />
    <Route path="/components/transference/information" component={TransferenceInformation} exact />
    <Route path="/components/discount/discountBoxInformation" component={DiscountBoxInfomation} exact />
    <Route path="/pages/discounts" component={DiscountsPages} exact />
    <Route path="/pages/customer-registration-update-form" component={UserUpdate} exact />
    <Route path="/pages/member-get-member-pj" component={MemberGetMemberPJPage} exact />
  </Switch>
)

export default Routes
