import styled from 'styled-components'

const ButtonStyle = styled.button`
  background-image: linear-gradient(90deg, #009ff5, #126fff);
  width: 100%;
  max-width: 350px;
  height: 4em;
  color: #fff;
  font-weight: 700;
  border: unset;
  border-radius: 32px;
  font-family: Open Sans, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 17px;
  cursor: pointer;
`
interface PrimaryButtonProps {
  children: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export default function PrimaryButton({ children, onClick }: PrimaryButtonProps) {
  return <ButtonStyle onClick={onClick}>{children}</ButtonStyle>
}
