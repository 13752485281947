import { FileWithPath } from 'react-dropzone'
import api from 'api'
import { RequestStatus } from '../../enums/requestStatus'

const uploadDocument = async (documentType: string, file: FileWithPath) => {
  const formData = new FormData()

  formData.append('files', file)
  formData.append('ocrDocType', documentType)

  const handle = await api.post('/v2/requirements/identity-document', formData)

  return handle?.data
}

let maxRetry = 3

const maxAttempt = () => {
  maxRetry -= 1

  return maxRetry
}

const uploadDoubleSidedDocument = async (
  documentType: string,
  documentFront: FileWithPath,
  documentVerse: FileWithPath,
  attempt = 0,
): Promise<Number> => {
  try {
    const formData = new FormData()

    formData.append('files', documentFront)
    formData.append('files', documentVerse)
    formData.append('ocrDocType', documentType)

    const sendDocument = await api.post('/v2/requirements/identity-document-front-back', formData)

    return sendDocument.status
  } catch (err: any) {
    if (err === RequestStatus.documentCorruptedError || attempt >= maxAttempt()) {
      return err?.response?.status
    }

    return uploadDoubleSidedDocument(documentType, documentFront, documentVerse, attempt + 1)
  }
}

export { uploadDocument, uploadDoubleSidedDocument }
