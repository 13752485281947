import { styled } from '@mui/material'

export const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const FormContainer = styled('div')`
  width: 100%;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 640px) {
    width: 85%;
  }
  @media only screen and (min-width: 1024px) {
    width: 45%;
    max-width: 31.5em;
  }
`
