import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  h3 {
    font-size: 1.58em;
    color: #1d3a78;
    font-weight: 300;
  }
  p {
    font-size: 0.95em;
    color: #888888;
    margin-bottom: 0.5em;
  }
`
