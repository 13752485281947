import { styled } from '@mui/material'

interface IFlag {
  src: string
}

export const PersonalContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  #cpf {
    border-bottom: 0.1005em solid lightgrey;
  }

  .phone-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (min-width: ${({ theme }) => theme.remessa.breakpoints.lg}) {
    .phone-group {
      flex-direction: row;
      justify-content: space-between;
      gap: 1.8em;
      margin-bottom: 1em;
      div {
        margin-bottom: 0;
      }
    }
  }
`
export const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 3em;
  margin: 2.8em 0.8em;
  @media only screen and (min-width: ${({ theme }) => theme.remessa.breakpoints.lg}) {
    width: 62%;
  }
`
export const DDIContentContainer = styled('div')`
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

export const Flag = styled('img')<IFlag>`
  width: 18px;
  height: 18px;
  margin-right: 0.5em;
  border-radius: 50%;
  object-fit: cover;
  background-position: center;
  background-image: url(${(props) => props.src});
`
