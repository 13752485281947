import axios, { AxiosRequestConfig } from 'axios'

interface IAxiosResponse {
  headers?: {
    authorization: string | undefined
  }
}

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BEE_CAMBIO_API}`,
})

api.interceptors.request.use((request: AxiosRequestConfig<IAxiosResponse>) => {
  const newRequest = { ...request }
  const handleToken = localStorage.getItem('beero.customerToken')

  if (newRequest.headers && handleToken) {
    newRequest.headers.authorization = `Bearer ${handleToken}`
  }

  return newRequest
})

api.interceptors.response.use((response) => response)

export default api
