import { useContext, useEffect, useState } from 'react'
import { RegisterContext } from 'context/register'
import tracker from 'utils/tracker'
import UploadBox from 'components/Register/SendDocument/UploadBox'
import LegacyButton from 'components/UI/LegacyButton'
import Paragraph from 'components/UI/Typography/Paragraph'
import { PageContainer } from '../../../../../pages/Register/SendDocument/style'

const UploadFrontDocument = () => {
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)
  const context = useContext(RegisterContext)

  const handleSendButton = () => {
    tracker({ category: 'account_pf' }).click({
      label: 'send_documents_button',
    })
    context.sendDocument()
  }

  useEffect(() => {
    tracker({ category: 'account_pf' }).pageView({
      label: 'upload_verse_document_page',
    })
  }, [])

  return (
    <PageContainer>
      <div className="box">
        <div className="information">
          <span className="number">3</span>
          <Paragraph fontSize={16} fontWeight={700} marginTop={0}>
            Envie a foto do verso do seu {context.documentType}
          </Paragraph>
        </div>

        <UploadBox
          setUploadSuccess={setUploadSuccess}
          documentSide="Verso"
          document={context.verseDocument}
          setDocument={context.setVerseDocument}
          onClick={() =>
            tracker({
              category: 'account_pf',
              eventProperties: {
                'A/B_Test_Name': 'onboarding_document_upload_new_flow',
                'A/B_Test_Group': 'Variant_B',
                side: 'back',
              },
            }).click({
              label: 'input_upload_document',
            })
          }
          onClickChangeFile={(error: boolean) =>
            tracker({
              category: 'account_pf',
              eventProperties: {
                'A/B_Test_Name': 'onboarding_document_upload_new_flow',
                'A/B_Test_Group': 'Variant_B',
                side: 'back',
                error: error ? 'yes' : 'no',
              },
            }).click({
              label: 'input_upload_document',
            })
          }
          setImageType={context.setVerseImageType}
        />

        <LegacyButton loading={context.loading} disabled={!uploadSuccess} onClick={() => handleSendButton()}>
          Continuar
        </LegacyButton>
      </div>
    </PageContainer>
  )
}

export default UploadFrontDocument
