import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  color: var(--rods-color-text-primary);
  background-color: var(--rods-color-white);
  font-family: 'Okta Neue';
  background-color: #f5f5f5;

  & > .infoContainer {
    order: 1;
    padding: 16px;
    gap: 16px;
    height: 280px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;

    & > h1 {
      color: var(--rods-color-text-primary);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }

    & > p {
      color: var(--rods-color-text-secondary);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }

  & > .imgContainer {
    order: 2;
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  & > .imgContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: 1025px) {
    flex-direction: row;
    height: 280px;
    align-items: center;

    & > .infoContainer {
      flex: 1;
      order: 1;
      text-align: left;
      padding: 64px 60px;
    }

    & > .imgContainer {
      flex: 1;
      order: 2;
      width: 50%;
    }

    & > .imgContainer img {
      width: 100%;
      height: 100%;
    }
  }
`
