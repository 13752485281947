import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'

export const ImageContainer = styled('div')`
  display: flex;
`

export const ContentContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  margin: 0.5em 0 1em 0;
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.remessa.palette.common.natureTitle};
  font-size: 24px;
  font-weight: 300;
`

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.remessa.palette.common.natureCardText};
  font-size: 16px;
  font-weight: 400;
  line-height: 28.88px;
`
