import styled from 'styled-components'

const Content = styled.div`
  height: 0.25em;
  position: relative;
  margin: 2px 0;
`

const Bar = styled.div`
  background: linear-gradient(0deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.5)), #006ff2;
  border-radius: 0.5em 0 0.5em 0.5em;
  display: block;
  height: 0.25em;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 2;
  border-right: 3px solid #fff;
`

const EmptyBar = styled.div`
  border: 0;
  background: hsla(214, 8%, 82%, 0.72);
  display: block;
  border-radius: 0.5em;
  height: 0.25em;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`

interface ProgressBarProps {
  progressPercentage: string
}

export default function ProgressBar({ progressPercentage }: ProgressBarProps) {
  return (
    <Content>
      <Bar style={{ width: progressPercentage }} />
      <EmptyBar />
    </Content>
  )
}
