/* eslint-disable react/no-danger */
import { Container } from './style'

interface RemessaMaisHowWorksProps {
  MainText: string
  DiscountTitle5: string
  DiscountTitle10: string
  DiscountTitle15: string
  DiscountDescription5: string
  DiscountDescription10: string
  DiscountDescription15: string
}

const HowWorks = ({
  MainText,
  DiscountTitle5,
  DiscountTitle10,
  DiscountTitle15,
  DiscountDescription5,
  DiscountDescription10,
  DiscountDescription15,
}: RemessaMaisHowWorksProps) => {
  return (
    <Container>
      <div className="titleContainer">
        <h1>Como Funciona</h1>
      </div>
      <div className="textsContainer">
        <p className="description" dangerouslySetInnerHTML={{ __html: MainText }} />
        <div className="discounts">
          <div className="discount">
            <h2>{DiscountTitle5}</h2>
            <p>{DiscountDescription5}</p>
          </div>
          <div className="discount">
            <h2>{DiscountTitle10}</h2>
            <p>{DiscountDescription10}</p>
          </div>
          <div className="discount">
            <h2>{DiscountTitle15}</h2>
            <p>{DiscountDescription15}</p>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default HowWorks
