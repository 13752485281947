const ShareOptionsPopup = ({
  onCopy,
  onShareWhatsApp,
  onShareEmail,
  onClose,
}: {
  onCopy: () => void
  onShareWhatsApp: () => void
  onShareEmail: () => void
  onClose: () => void
}) => (
  <div className="popupContentOptions">
    <div className="popupContentClose">
      <button type="button" className="popupCloseButton" onClick={onClose}>
        <img src="/img/discounts-page/icons/close.svg" alt="fechar" />
      </button>
    </div>
    <button type="button" className="popupButtonContent" onClick={onShareEmail}>
      <img src="/img/discounts-page/icons/email.svg" alt="" className="buttonIcon" />
      <p className="buttonText">Compartilhar por e-mail</p>
    </button>
    <button type="button" className="popupButtonContent" onClick={onShareWhatsApp}>
      <img src="/img/discounts-page/icons/whatsapp.svg" alt="" className="buttonIcon" />
      <p className="buttonText">Compartilhar por Whatsapp</p>
    </button>
    <button type="button" data-testid="buttonCopyOption" className="popupButtonContent" onClick={onCopy}>
      <img src="/img/discounts-page/icons/copy.svg" alt="" className="buttonIcon" />
      <p className="buttonText">Copiar</p>
    </button>
  </div>
)

export default ShareOptionsPopup
