import { Box, ScopedCssBaseline } from '@mui/material'
import { RegisterContext } from 'context/register'
import { ToastMessage } from 'components/UI/Toast'

import { useContext, useMemo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import nuService from 'services/Nu/nu.service'
import Header from '../../UI/Header'
import NubankBanner from '../../UI/NubankBanner'
import { Container } from './style'

interface ITemplate {
  children: React.ReactNode
  handleCloseIcon?: () => void
}

export default function TemplateRegister({ children, handleCloseIcon }: ITemplate) {
  const { loading, responseError, nuData, setNuData } = useContext(RegisterContext)
  const { search } = useLocation()
  const params = useMemo(() => new URLSearchParams(search), [search])
  const userToken = localStorage.getItem('beero.customerToken')

  useEffect(() => {
    async function getToken() {
      const obj = {
        affiliateId: params.get('ho_affiliate_id'),
        token: params.get('ho_external_code'),
        offerId: params.get('ho_offer_id'),
      }
      const data = await nuService.getNuToken(obj)

      setNuData(data)
    }
    if (userToken) getToken()
  }, [params, userToken, setNuData])

  return (
    <ScopedCssBaseline>
      <Header handleCloseIcon={handleCloseIcon} />
      {nuData?.isValid && nuData?.discountMessage && (
        <NubankBanner discountValue={nuData.discountMessage} subtitle="Parceria exclusiva da Nubank + Remessa Online" />
      )}
      {nuData?.message && <NubankBanner message={nuData.message} />}
      {loading ? (
        <Container>
          <CircularProgress />
        </Container>
      ) : (
        <>
          <Box>{children}</Box>
          {responseError && <ToastMessage errorMessage={responseError} />}
        </>
      )}
    </ScopedCssBaseline>
  )
}
