export enum RemessaMaisBannerProps {
  title = 'Remessa Mais',
  description = 'Nosso programa de fidelidade. Quanto mais você operar, mais descontos você ganha!',
  image = '/img/discounts-page/remessamais-banner.png',
}

export const MgmBannerProps = {
  title: 'Indique a Remessa para um amigo',
  description: (maximumDiscount: string) => {
    const discount = parseFloat(maximumDiscount).toFixed(0)

    return `Compartilhe seu cupom e ganhe até ${discount || 0}% de desconto`
  },
  image: '/img/member-get-member-pj/cover-1.png',
}

export const RemessaMaisYourDiscountDefaultProps = {
  elegible: false,
  discountTag: 'Ainda sem desconto',
  descriptionInfo: '',
}

export const MgmYourDiscountDefaultProps = {
  elegible: false,
  discountTag: 'Ainda sem desconto',
}

export enum RemessaMaisHowWorksProps {
  MainText = 'Nosso <strong>Programa de fidelidade Remessa Mais</strong> oferece descontos de até <strong>15%</strong> no custo das suas próximas transferências:',

  DiscountTitle5 = '5% de desconto',
  DiscountTitle10 = '10% de desconto',
  DiscountTitle15 = '15% de desconto',

  DiscountDescription5 = 'Se operou 1 vez nos últimos 90 dias',
  DiscountDescription10 = 'Se operou 1 vez nos últimos 60 dias',
  DiscountDescription15 = 'Se operou 1 vez nos últimos 30 dias',
}

export const MgmHowWorksProps = (discountIncrement: string, mgmVoucherDiscount: string, validMonthlyPeriod: number) => {
  const discountIncrementNumber = Math.floor(parseFloat(discountIncrement))
  const mgmVoucherDiscountNumber = Math.floor(parseFloat(mgmVoucherDiscount))

  return [
    {
      step: 1,
      title: 'Compartilhe seu cupom',
      description: `Envie seu cupom de desconto para a pessoa que deseja indicar. A cada pessoa que fizer a primeira operação com o seu cupom, você acumula ${discountIncrementNumber}% de desconto.`,
    },
    {
      step: 2,
      title: 'Aplicando o cupom de desconto',
      description:
        'No final da operação, haverá uma tela de revisão. É nesse momento que ela deve inserir o seu cupom de desconto.',
    },
    {
      step: 3,
      title: 'Agora é só aproveitar!',
      description: `A pessoa indicada terá um desconto de ${mgmVoucherDiscountNumber}% na primeira operação e você acumula ${discountIncrementNumber}% de desconto nas suas próximas operações, válido por até ${validMonthlyPeriod} meses.`,
    },
  ]
}

export enum RemessaMaisRulesProps {
  rule1 = 'Contam para o programa apenas as transferências acima de R$ 5 mil. Os descontos serão aplicados em todas as operações entre R$5 mil e R$ 30 mil',
  rule2 = 'Descontos de parceiros podem acumular com as da Promoção Remessa Mais. Outros cupons não acumulam (será aplicado o de maior valor)',
}

export const WhatsAppMessageTemplate = {
  message: (code: string, discount: string) => {
    const formattedDiscount = parseFloat(discount).toFixed(0)

    return `Oi! Aqui vai um cupom para você ganhar *${formattedDiscount}% de desconto* na sua primeira transferência internacional com a Remessa Online!

Cupom: *${code}*

Para usar é bem fácil, basta fazer seu cadastro no link (https://remessaonline.com.br?utm_voucher=${code}&utm_medium=mgm-share), aplicar o cupom no simulador e finalizar sua operação.

Aproveite!`
  },
}
