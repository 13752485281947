import { styled } from '@mui/material'

export const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 156px;
`

export const TitleAndSubtitleContainer = styled('div')`
  width: 100%;
  text-align: left;
  margin-bottom: 36px;
`

export const Title = styled('h4')`
  font-weight: 300;
  font-size: 24px;
  color: ${({ theme }) => theme.remessa.palette.common.natureTitle};
`

export const Subtitle = styled('p')`
  color: ${({ theme }) => theme.remessa.palette.common.natureSubtitle};
  font-size: 18px;
  line-height: 25px;
  font-weight: 300;
  margin-top: 16px;

  @media screen and (min-width: ${({ theme }) => theme.remessa.breakpoints.sm}) {
    line-height: 25px;
  }
`

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 900px;
  margin-top: 26px;
  @media screen and (min-width: ${({ theme }) => theme.remessa.breakpoints.sm}) {
    margin-top: 50px;
  }
`
