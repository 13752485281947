/* eslint-disable react-hooks/exhaustive-deps */
import usePostMessage from 'hooks/usePostMessage'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import InboundHistory from '../InboundHistory'
import { HistoryStatuses } from '../models/HistoryStatuses'
import { History } from '../models/History'
import OutboundHistory from '../OutboundHistory'

interface HistoryListProps {
  histories: History[]
  statuses: HistoryStatuses
}

const ListBox = styled.div`
  width: 104%;
  margin-left: -0.6em;
  display: flex;
  flex-wrap: wrap;
`

export default function HistoryList({ histories, statuses }: HistoryListProps) {
  const [state, setState] = useState<HistoryListProps>({
    histories,
    statuses,
  })

  const usePostMessageHook = usePostMessage('HistoryList', setState)

  function iframeResize() {
    usePostMessageHook.sendMessage({
      type: 'changeIframeSize',
      data: document.body.clientHeight,
    })
  }

  useEffect(() => {
    iframeResize()
    window.addEventListener('resize', iframeResize)
  })

  return (
    <ListBox>
      {state.histories?.map((history: History) => {
        if (history.senderObj) {
          return (
            <InboundHistory
              key={history.id}
              history={history}
              statuses={state.statuses}
              usePostMessageHook={usePostMessageHook}
            />
          )
        }

        return (
          <OutboundHistory
            key={history.id}
            history={history}
            statuses={state.statuses}
            usePostMessageHook={usePostMessageHook}
          />
        )
      })}
    </ListBox>
  )
}
