import { createContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FileWithPath } from 'react-dropzone'
import { uploadDocument, uploadDoubleSidedDocument } from 'services/Register/sendDocument.service'
import customerService from 'services/Register/customer.service'
import countryService from 'services/Register/country.service'
import { IUserData } from 'interfaces/Register/customer'
import { Nu } from 'interfaces/Nu/nu'
import { RequestStatus } from '../../enums/requestStatus'

const pdfType = 'application/pdf'

interface IRegisterContext {
  children: React.ReactNode
}

interface ICountries {
  id: number
  name: string
  flag: string
  ddi: string
}

interface IRegisterProvider {
  customer: IUserData | null
  setCustomer: (userData: IUserData) => void
  loading: boolean
  setLoading: (loadingData: boolean) => void
  nuData: Nu | null
  setNuData: (nuData: Nu | null) => void
  page: number
  setPage(page: number): void
  documentType?: string
  setDocumentType(documentType: string): void
  frontDocument?: FileWithPath
  setFrontDocument(document: FileWithPath): void
  verseDocument?: FileWithPath
  setVerseDocument(document: FileWithPath): void
  sendDocument(): void
  setFrontImageType(imageType: string): void
  setVerseImageType(imageType: string): void
  frontImageType?: string
  verseImageType?: string
  countries: ICountries[]
  responseError?: string | null
  setResponseError(responseError: string | null): void
  returnToPersonalData?: boolean
  setReturnToPersonalData(returnToPersonalData: boolean): void
  countErrorFile: number
  setCountErrorFile(countErrorFile: number): void
  openCorruptedFileModal: boolean
  setOpenCorruptedFileModal(openCorruptedFileModal: boolean): void
}

export const RegisterContext = createContext({} as IRegisterProvider)

export const RegisterProvider = ({ children }: IRegisterContext) => {
  const [customer, setCustomer] = useState<IUserData | null>(null)
  const [countries, setCountries] = useState<ICountries[]>([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [documentType, setDocumentType] = useState<string | undefined>()
  const [frontImageType, setFrontImageType] = useState<string>()
  const [verseImageType, setVerseImageType] = useState<string>()
  const [frontDocument, setFrontDocument] = useState<FileWithPath | undefined>()
  const [verseDocument, setVerseDocument] = useState<FileWithPath | undefined>()
  const [nuData, setNuData] = useState<Nu | null>(null)
  const [countErrorFile, setCountErrorFile] = useState(0)
  const [responseError, setResponseError] = useState<string | null>()
  const [returnToPersonalData, setReturnToPersonalData] = useState(false)
  const [openCorruptedFileModal, setOpenCorruptedFileModal] = useState(false)
  const history = useHistory()

  const defineDocumentToSend = () => {
    const hasAnyPdfImageType = frontImageType === pdfType || verseImageType === pdfType

    if (hasAnyPdfImageType && documentType === 'RG') {
      return verseDocument
    }

    if (hasAnyPdfImageType && (documentType === 'CNH' || documentType === 'RNE')) {
      return frontDocument
    }

    return null
  }

  const resetDocument = () => {
    setFrontDocument(undefined)
    setVerseDocument(undefined)
  }

  const setToPreviousPage = () => {
    setPage(page - 1)
  }

  const sendDocument = async () => {
    setLoading(true)
    const hasOnlyImageType = frontImageType?.includes('image') && verseImageType?.includes('image')

    if (hasOnlyImageType) {
      if (!documentType || !frontDocument || !verseDocument) {
        setLoading(false)
        return
      }

      const requestStatus = await uploadDoubleSidedDocument(documentType, frontDocument, verseDocument)

      if (
        requestStatus === RequestStatus.documentCorruptedError ||
        requestStatus === RequestStatus.documentUploadError
      ) {
        resetDocument()
        setCountErrorFile(countErrorFile + 1)
        setToPreviousPage()
        setOpenCorruptedFileModal(true)
      }
    } else {
      const documentFile = defineDocumentToSend()

      if (!documentType || !documentFile || !frontImageType || !verseImageType) {
        return
      }

      await uploadDocument(documentType, documentFile)
    }

    setLoading(false)
  }

  useEffect(() => {
    const handleCustomer = async () => {
      setLoading(true)
      const userData = await customerService.getCustomerData()
      setCustomer(userData)
      setLoading(false)

      if (!returnToPersonalData) {
        if (userData?.remittance_register_complete) {
          history.push('/criar-conta/envio-de-documento')
        } else if (userData?.doc_number) {
          history.push('/criar-conta/endereco')
        } else {
          history.push('/criar-conta/natureza')
        }
      }
    }

    if (!customer) {
      handleCustomer()
    }
  }, [customer, history, returnToPersonalData, nuData])

  useEffect(() => {
    const handleCountries = async () => {
      setLoading(true)
      const countriesData = await countryService.getCountries()
      setCountries(countriesData)
      if (customer) {
        setLoading(false)
      }
    }
    if (countries.length < 1) {
      handleCountries()
    }
  }, [countries, customer])

  const registerValue = {
    customer,
    setCustomer,
    countries,
    setCountries,
    loading,
    setLoading,
    nuData,
    setNuData,
    page,
    setPage,
    documentType,
    setDocumentType,
    frontDocument,
    setFrontDocument,
    verseDocument,
    setVerseDocument,
    sendDocument,
    setFrontImageType,
    setVerseImageType,
    frontImageType,
    verseImageType,
    responseError,
    setResponseError,
    returnToPersonalData,
    setReturnToPersonalData,
    countErrorFile,
    setCountErrorFile,
    openCorruptedFileModal,
    setOpenCorruptedFileModal,
  }

  return <RegisterContext.Provider value={registerValue}>{children}</RegisterContext.Provider>
}
