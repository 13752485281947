import styled from 'styled-components'
import Separator from 'components/UI/Separator'
import Paper from 'components/UI/Surfaces/Paper'
import Title from 'components/UI/Typography/Title'
import Badge from 'components/UI/Badge'
import { History } from '../models/History'
import HistoryHeader from '../HistoryHeader/HistoryHeader'
import { HistoryStatuses } from '../models/HistoryStatuses'

interface InboundHistoryProps {
  history: History
  statuses: HistoryStatuses
  usePostMessageHook: any
}

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`
const SubTitle = styled.div`
  margin-bottom: 3px;
  font-size: 12px;
  color: #737373;
`
const FormattedDate = styled.div`
  font-size: 16px;
  color: #568;
`

const RotatedIcon = styled.img`
  transform: rotate(180deg);
`

const SpacedBadge = styled.div`
  margin-bottom: 20px;
`

export default function InboundHistory({ history, statuses, usePostMessageHook }: InboundHistoryProps) {
  function isApproved() {
    return Number(history.status) === statuses.approved.value
  }

  function isUnderAnalysis() {
    return Number(history.status) === statuses.under_analysis.value
  }

  const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: history.currencyObj.abbreviation || 'BRL',
    currencyDisplay: 'code',
  })

  function inboundHistoryClick(inboundHistory: History) {
    usePostMessageHook.sendMessage({
      type: 'inboundHistoryClick',
      data: inboundHistory,
    })
  }

  function deleteCard(e: any) {
    e.preventDefault()
    e.stopPropagation()

    usePostMessageHook.sendMessage({
      type: 'deleteHistory',
      data: history,
    })
  }

  return (
    <Paper onClick={() => inboundHistoryClick(history)}>
      <section>
        <HistoryHeader
          title="RECEBIMENTO"
          icon={<RotatedIcon height={11} width={8} src="/img/remessa-icons/arrowHeader.svg" alt="icone de seta" />}
          onDeleteClick={deleteCard}
        />

        <Separator />

        <section>
          <SpacedBadge>
            {isApproved() && <Badge>Aprovada</Badge>}
            {isUnderAnalysis() && <Badge styles={{ backgroundColor: '#fbf0c5' }}>Em análise</Badge>}
          </SpacedBadge>
          <Title>{history.name || 'Sem nome'}</Title>
          <FlexColumn>
            <SubTitle>Saldo para fechamento</SubTitle>
            <FormattedDate>{`${
              history.available_credit ? currencyFormatter.format(history.available_credit) : '0,00'
            }`}</FormattedDate>
          </FlexColumn>
        </section>
      </section>
    </Paper>
  )
}
