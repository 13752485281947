/* eslint-disable jsx-a11y/label-has-associated-control */

interface Email {
  id: string
  email: string
}

interface EmailSetupPopupProps {
  emails: Email[]
  onAddEmail: () => void
  onRemoveEmail: (id: string) => void
  onEmailChange: (id: string, e: React.ChangeEvent<HTMLInputElement>) => void
  onSendEmail: () => void
  onClose: () => void
  hasValidEmails: boolean
  buttonShareColor?: string
  addImage?: string
}

const EmailSetupPopup = ({
  emails,
  onAddEmail,
  onRemoveEmail,
  onEmailChange,
  onSendEmail,
  onClose,
  buttonShareColor,
  hasValidEmails,
  addImage = '/img/discounts-page/icons/add-email.svg',
}: EmailSetupPopupProps) => (
  <div className="popupContentEmail">
    <div className="popupContentClose">
      <button type="button" className="popupCloseButton" onClick={onClose}>
        <img src="/img/discounts-page/icons/close.svg" alt="fechar" />
      </button>
    </div>
    <div className="emailFormContainer">
      {emails.map((email, index) => (
        <div key={email.id} className="emailInputContainer">
          {index === 0 && <label htmlFor={`email-${email.id}`}>E-mail</label>}
          <input
            id={`email-${email.id}`}
            type="email"
            value={email.email}
            onChange={(e) => onEmailChange(email.id, e)}
            placeholder="Informe o e-mail da pessoa indicada"
          />
          {emails.length > 1 && (
            <button type="button" className="removeEmailButton" onClick={() => onRemoveEmail(email.id)}>
              <img src="/img/discounts-page/icons/trash.svg" alt="remover e-mail" />
            </button>
          )}
        </div>
      ))}
      <button type="button" className="newEmailInput" onClick={onAddEmail}>
        <img src={addImage} alt="" />
        <p style={{ color: buttonShareColor }}>Adicionar outro e-mail</p>
      </button>
    </div>
    <button
      type="button"
      style={{ backgroundColor: buttonShareColor }}
      className="buttonCtaEmail"
      onClick={onSendEmail}
      disabled={!hasValidEmails}
    >
      Enviar
    </button>
  </div>
)

export default EmailSetupPopup
