import styled from 'styled-components'
import { isMobile } from 'utils/tracker/browser-utils'
import Card from '../../../UI/Card/Card'

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  height: 150px;
  padding: 30px 15px 30px 15px;
  box-shadow: 0px 5px 5px -3px rgba(116, 125, 134, 0.2), 0px 8px 10px 1px rgba(116, 125, 134, 0.08),
    0px 3px 14px 2px rgba(116, 125, 134, 0.08);
  border-radius: 16px;
  background-color: #fff;

  @media (max-device-width: 639px) {
    height: 200px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  margin: 0 8px 0 8px;

  @media (max-device-width: 639px) {
    flex-direction: row;
    margin-bottom: 20px;

    :last-child {
      margin-bottom: 0;
    }
  }
`
const SubtitleText = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  color: #676c72;

  @media (max-device-width: 639px) {
    text-align: left;
  }
`

const Icon = styled.img`
  @media (max-device-width: 639px) {
    margin-right: 20px;
  }
`

export default function Benefits() {
  return (
    <Card>
      <Content>
        <Section>
          <Icon height={30} src="/img/remessa-icons/exchange.svg" alt="icone de troca" />

          <SubtitleText>Enviar e receber do exterior</SubtitleText>
        </Section>
        {!isMobile() && (
          <Section>
            <Icon height={30} width={30} src="/img/remessa-icons/plus.svg" alt="icone de mais" />
          </Section>
        )}

        <Section>
          <Icon height={30} src="/img/remessa-icons/sack-dollar.svg" alt="icone de dolar" />
          <SubtitleText>Simular com valores exatos</SubtitleText>
        </Section>
        {!isMobile() && (
          <Section>
            <Icon height={30} width={30} src="/img/remessa-icons/plus.svg" alt="icone de mais" />
          </Section>
        )}
        <Section>
          <Icon height={30} src="/img/remessa-icons/hourglass-end.svg" alt="icone de relogio" />
          <SubtitleText>Operar com limite de R$100 mil por ano</SubtitleText>
        </Section>
      </Content>
    </Card>
  )
}
