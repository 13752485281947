import TemplateModal from 'components/Templates/TemplateModal'
import { ContentContainer, Title, Subtitle } from './styles'

interface IRegisterModal {
  show: boolean
  handleState?: () => void
}

const handleExit = () => {
  window.location.href = '/components/dashboard/finish-registration'
}

export default function RegisterModal({ show, handleState }: IRegisterModal) {
  return (
    <TemplateModal
      show={show}
      closeIconFunction={handleState}
      firstButtonLabel="Quero sair mesmo assim"
      firstButtonFunction={handleExit}
      secondButtonVariant="link"
      secondButtonLabel="Continuar o cadastro"
      secondButtonFunction={handleState}
    >
      <ContentContainer>
        <Title>Seus dados não serão salvos</Title>
        <Subtitle>Ao sair dessa página as informações que você inseriu serão perdidas</Subtitle>
      </ContentContainer>
    </TemplateModal>
  )
}
