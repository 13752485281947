import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { TextField, MenuItem } from '@mui/material'
import NumberFormat from 'react-number-format'
import { useFormik } from 'formik'
import customerService from 'services/Register/customer.service'
import { RegisterContext } from 'context/register'
import { parse, format } from 'date-fns'
import { RequestStatus } from 'enums/requestStatus'
import { RegisterInputProps } from 'components/UI/Theme'
import FormContainer from '../FormContainer'
import Button from '../../Button/index'
import { ButtonContainer, DDIContentContainer, Flag, PersonalContainer } from './styles'
import { registerSchema } from '../Schemas'
import { AgeValidation, dateValidation } from './validations'
import { ToastMessage } from '../../../UI/Toast'

export default function PersonalDataForm() {
  const [ageValidation, setAgeValidation] = useState(false)
  const [dateValidationState, setDateValidationState] = useState(false)
  const [toastCotrol, setToastControl] = useState(false)
  const [showError, setShowError] = useState(false)
  const { customer, setCustomer, countries, setLoading, setResponseError, setReturnToPersonalData } =
    useContext(RegisterContext)
  const history = useHistory()

  const { initialValues, validationSchema } = registerSchema

  const handleDate = (value: string | Date, currentFormat: string | null, finalFormat: string) => {
    let formatDate = null
    if (currentFormat && typeof value === 'string') {
      const parserDate = parse(value, currentFormat, new Date())
      formatDate = format(parserDate, finalFormat)
    }
    if (!currentFormat) {
      formatDate = format(new Date(value), finalFormat)
    }
    return formatDate
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setResponseError(null)
      if (!ageValidation) {
        const customerData = {
          name: values.name || customer?.name,
          mobile_phone: values.phone,
          birthday: handleDate(values.birth, 'ddMMyyyy', 'yyyy-MM-dd'),
          c_type: customer?.c_type,
          country_code: values.ddi,
          cpf_cnpj: values.cpf || customer?.cpf_cnpj,
          created_at: customer?.created_at,
          doc_number: values.rg || customer?.doc_number,
          email: customer?.email,
          genre: customer?.genre || values.gender,
          mother_name: customer?.mother_name || values.mothersName,
          last_login: customer?.last_login_remittance,
          last_quantity: '0.000000',
          maxima_iban: customer?.maxima_iban,
          mgm_code: customer?.mgm_code,
          remittance_limit: customer?.remittance_limit,
          remittance_total_value: '0.000000',
          total_value: customer?.total_value,
        }
        const updatedCustomer = await customerService.updateCustomerData(customerData)
        setCustomer(updatedCustomer)
        const result = await customerService.validateCustomerData()
        if (result?.status === RequestStatus.success) {
          history.push('/criar-conta/endereco')
        } else {
          setResponseError(result?.message || 'Algo deu errado, verifique as informações e tente novamente!')
        }
      } else {
        setToastControl(true)
      }
    },
  })

  const { setFieldValue, values, handleChange, handleBlur, touched, handleSubmit } = formik

  useEffect(() => {
    if (customer) {
      const formatCustomerDate = (customer.birthday && handleDate(customer.birthday, null, 'ddMMyyyy')) || undefined
      setFieldValue('name', customer.name || '')
      setFieldValue('cpf', customer.cpf_cnpj || '')
      setFieldValue('birth', formatCustomerDate || '')
      setFieldValue('rg', customer.doc_number || '')
      setFieldValue('phone', customer.mobile_phone || '')
      setFieldValue('gender', customer.genre || '')
      setFieldValue('mothersName', customer.mother_name || '')
    }
  }, [customer, setFieldValue, setLoading])

  return (
    <FormContainer onSubmit={handleSubmit}>
      {toastCotrol && <ToastMessage errorMessage="Não é permitido cadastro de um menor de 16 anos." />}
      <PersonalContainer>
        <TextField
          fullWidth
          id="name"
          name="name"
          variant="standard"
          margin="normal"
          label="Nome Completo"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!values.name && touched.name}
          InputProps={RegisterInputProps.inputProps}
        />
        <TextField
          fullWidth
          id="cpf"
          name="cpf"
          variant="standard"
          margin="normal"
          label="CPF"
          disabled
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
          value={values.cpf}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!values.cpf && touched.cpf}
        />
        <TextField
          fullWidth
          id="rg"
          variant="standard"
          margin="normal"
          label="RG"
          value={values.rg}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!values.rg && touched.rg}
          InputProps={RegisterInputProps.inputProps}
        />
        <NumberFormat
          fullWidth
          id="birth"
          variant="standard"
          margin="normal"
          label="Data de nascimento"
          type="tel"
          customInput={TextField}
          format="##/##/####"
          mask="_"
          value={values.birth}
          onValueChange={(valueChange) => {
            const { formattedValue, value } = valueChange
            const isValidDate = dateValidation(formattedValue)
            const isValidAge = AgeValidation(formattedValue)

            setToastControl(false)
            setDateValidationState(isValidDate)
            setAgeValidation(isValidAge)
            setFieldValue('birth', value)
          }}
          onBlur={(event: React.SyntheticEvent<EventTarget>) => {
            handleBlur(event)
            if (values.birth.length < 8) {
              setFieldValue('birth', '')
            }
          }}
          error={(!values.birth && touched.birth) || (!dateValidationState && touched.birth)}
          placeholder="__/__/____"
          InputLabelProps={{ shrink: true }}
          InputProps={RegisterInputProps.inputProps}
        />

        {!!countries?.length && (
          <TextField
            select
            SelectProps={RegisterInputProps.selectInputProps}
            InputProps={RegisterInputProps.inputProps}
            fullWidth
            id="nationality"
            name="nacionalidade"
            variant="standard"
            margin="normal"
            label="Nacionalidade"
            value={values.nationality}
            onChange={(event) => setFieldValue('nationality', event.target.value)}
          >
            {countries?.map((item) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        {(!customer?.genre || customer?.genre === '') && (
          <TextField
            fullWidth
            id="gender"
            select
            label="Gênero"
            value={values.gender}
            onChange={({ target }) => setFieldValue('gender', target.value)}
            variant="standard"
            margin="normal"
            onBlur={handleBlur}
            error={!values.gender && showError}
            onClick={() => setShowError(true)}
            InputProps={RegisterInputProps.inputProps}
            SelectProps={RegisterInputProps.selectInputProps}
          >
            <MenuItem value="F">Feminino</MenuItem>
            <MenuItem value="M">Masculino</MenuItem>
          </TextField>
        )}
        {(!customer?.mother_name || customer?.mother_name === '') && (
          <TextField
            fullWidth
            id="mothersName"
            variant="standard"
            margin="normal"
            label="Nome da mãe"
            value={values.mothersName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!values.mothersName && touched.mothersName}
            helperText="Igual está escrito no seu documento de identificação"
            InputProps={RegisterInputProps.inputProps}
          />
        )}
        {!!countries?.length && (
          <div className="phone-group">
            <TextField
              select
              SelectProps={RegisterInputProps.selectInputProps}
              InputProps={RegisterInputProps.inputProps}
              fullWidth
              id="ddi"
              variant="standard"
              margin="normal"
              data-testid="ddi"
              label="DDI"
              value={values.ddi}
              onChange={(event) => setFieldValue('ddi', event.target.value)}
            >
              {countries?.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  <DDIContentContainer>
                    <Flag src={item.flag} data-testid={`ddi${item.name}`} /> {item.name} ({item.ddi})
                  </DDIContentContainer>
                </MenuItem>
              ))}
            </TextField>
            <NumberFormat
              fullWidth
              id="phone"
              variant="standard"
              data-testid="telefone"
              aria-label="telefone"
              margin="normal"
              label="Telefone"
              className="class-birth"
              type="tel"
              customInput={TextField}
              format="(##) #####-####"
              mask="_"
              value={values.phone}
              onValueChange={({ value }) => setFieldValue('phone', value)}
              onBlur={handleBlur}
              error={!values.phone && touched.phone}
              placeholder="(__) _____-____"
              InputLabelProps={{ shrink: true }}
              InputProps={RegisterInputProps.inputProps}
            />
          </div>
        )}
        <ButtonContainer>
          <Button
            type="submit"
            element="Próximo passo"
            variant="contained"
            handleClick={() => setReturnToPersonalData(true)}
          />
        </ButtonContainer>
      </PersonalContainer>
    </FormContainer>
  )
}
