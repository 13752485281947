import TemplateModal from 'components/Templates/TemplateModal'
import { ContentContainer, Subtitle, Title } from './styles'

interface ISendDocumentModal {
  show: boolean
  handleState?: () => void
}

const handleExit = () => {
  window.location.href = '/components/dashboard/finish-registration'
}

export default function SendDocumentModal({ show, handleState }: ISendDocumentModal) {
  return (
    <TemplateModal
      show={show}
      closeIconFunction={handleState}
      firstButtonLabel="Ok, entendi"
      firstButtonFunction={handleExit}
    >
      <ContentContainer>
        <Title>Não esqueça de enviar seu documento</Title>
        <Subtitle>Você poderá adicionar o documento de identificação na tela de Perfil.</Subtitle>
      </ContentContainer>
    </TemplateModal>
  )
}
