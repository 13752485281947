import { useEffect, useState } from 'react'
import PersonalDataForm from 'components/Register/Forms/PersonalDataForm'
import TitleSubtitle from 'components/Register/TitleSubtitle'
import TemplateRegister from 'components/Templates/TemplateRegister'
import TemplateForm from 'components/Templates/TemplateForm'
import RegisterModal from 'components/UI/Modals/RegisterModal'
import AppModal from 'components/UI/Modals/AppModal'

export default function PersonalDataPage() {
  const [open, setOpen] = useState(false)
  const [openAppModal, setOpenAppModal] = useState(false)

  useEffect(() => {
    const width = window.innerWidth
    if (width <= 640) {
      setOpenAppModal(true)
    }
  }, [])

  const handleClick = async (modal: string) => {
    if (modal === 'Register') {
      setOpen((prev) => !prev)
    } else {
      setOpenAppModal((prev) => !prev)
    }
  }

  return (
    <TemplateRegister handleCloseIcon={() => handleClick('Register')}>
      {open && <RegisterModal show={open} handleState={() => handleClick('Register')} />}
      {openAppModal && <AppModal show={openAppModal} handleState={() => handleClick('App')} />}
      <TemplateForm
        form={<PersonalDataForm />}
        titleSubtitle={<TitleSubtitle title="Dados Pessoais" subtitle="Passo 2/3" />}
      />
    </TemplateRegister>
  )
}
