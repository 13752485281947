import TemplateModal from 'components/Templates/TemplateModal'
import { RegisterContext } from 'context/register'
import { useContext } from 'react'
import { ContentContainer, Subtitle, Title } from './styles'

interface ICorruptedFileModal {
  show: boolean
  firstButtonFunction?: () => void
}

const handleExit = () => {
  // TODO: enviar o usuário para a dashboard
}

export default function CorruptedFileModal({ show, firstButtonFunction }: ICorruptedFileModal) {
  const { countErrorFile } = useContext(RegisterContext)

  return (
    <TemplateModal
      show={show}
      closeIconFunction={handleExit}
      firstButtonLabel="Enviar"
      firstButtonFunction={firstButtonFunction}
      secondButtonLabel={countErrorFile > 1 ? 'Enviar mais tarde' : undefined}
      secondButtonFunction={countErrorFile > 1 ? handleExit : undefined}
      secondButtonVariant="outlined"
    >
      <ContentContainer>
        <img src="/img/remessa-icons/corrupted-file.svg" alt="Icone arquivo corrompido" />
        <Title>Arquivo corrompido =(</Title>
        <Subtitle>Por favor, vamos precisar que você envie novos arquivos.</Subtitle>
      </ContentContainer>
    </TemplateModal>
  )
}
