import { LinkStyled } from './style'

interface ILink {
  href?: string
  children: React.ReactNode
  onClick: () => void
}

export default function ButtonLink({ href, children, onClick }: ILink) {
  return (
    <LinkStyled data-locator="buttonLink" href={href} onClick={onClick} underline="none">
      {children}
    </LinkStyled>
  )
}
