import styled from 'styled-components'

export const ButtonContainer = styled.button`
  width: 100%;
  background: linear-gradient(310.56deg, #2244ff 27.06%, #0099ff 100%);
  border-radius: 32px;
  align-items: center;
  padding: 15px 40px;
  color: #ffffff;
  font-size: 1.6em;
  font-weight: 700;
  box-shadow: none;
  border: none;
  cursor: pointer;

  &:disabled {
    background: #c7d0db;
  }

  &.btLoading {
    color: transparent !important;
    pointer-events: none;
    position: relative;
    &:after {
      animation: loading 500ms infinite linear;
      border: 3px solid #fff;
      border-radius: 100%;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 16px;
      left: 50%;
      margin-left: -0.5em;
      margin-top: -0.5em;
      position: absolute;
      top: calc(50% - 3px);
      width: 16px;
    }
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`
