import React, { useCallback, useEffect, useState, useContext } from 'react'
import { TextField, CircularProgress } from '@mui/material'
import NumberFormat from 'react-number-format'
import { useFormik } from 'formik'
import Button from 'components/Register/Button'
import addressDataService from 'services/Register/address.service'
import { RegisterContext } from 'context/register'
import { useHistory } from 'react-router-dom'
import { RegisterInputProps } from 'components/UI/Theme'
import FormContainer from '../FormContainer'
import { addressSchema } from '../Schemas'
import { ButtonContainer, CepContainer, StreetAndNumberContainer } from './styles'

export default function AddressForm() {
  const [loading, setLoading] = useState(false)
  const [showFields, setShowFields] = useState(false)
  const [cepError, setCepError] = useState(false)
  const { customer, setCustomer, setReturnToPersonalData } = useContext(RegisterContext)
  const { initialValues, validationSchema } = addressSchema
  const history = useHistory()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const postValuesData = {
        address: values.address,
        city: values.city,
        neighbor: values.neighbor,
        state: values.uf,
        str_number: values.number,
        uf: values.uf,
        zip: values.cep,
        complement: values.complement,
      }
      const address = await addressDataService.postAddressData(postValuesData)
      if (customer) {
        customer.remittance_register_complete = true
        customer.addressList.push(address)
        setCustomer({ ...customer })
      }
      await addressDataService.postCompletePreSignup()
      history.push('/criar-conta/envio-de-documento')
    },
  })

  const { values, handleChange, setFieldValue, touched, handleSubmit, handleBlur } = formik

  const fetchAddress = useCallback(async () => {
    setLoading(true)
    const data = await addressDataService.getAddressByCep(values.cep)
    setLoading(false)
    return data
  }, [values.cep])

  const getAddress = useCallback(async () => {
    const data = await fetchAddress()

    if (values.cep.length >= 8) {
      setShowFields(true)
      setCepError(true)
      setFieldValue('address', '')
      setFieldValue('neighbor', '')
      setFieldValue('city', '')
      setFieldValue('uf', '')
    }

    if (data && data.cep) {
      setFieldValue('address', data.address)
      setFieldValue('neighbor', data.neighbor)
      setFieldValue('city', data.city)
      setFieldValue('uf', data.uf)
      setCepError(false)
    }
  }, [fetchAddress, setFieldValue, values.cep.length])

  useEffect(() => {
    if (values.cep.length >= 8) {
      getAddress()
    }
  }, [getAddress, values.cep.length])

  const handleReturnToPersonalData = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault()
    setReturnToPersonalData(true)
    history.push('/criar-conta/dados-pessoais')
  }

  return (
    <FormContainer onSubmit={handleSubmit}>
      <CepContainer>
        <NumberFormat
          fullWidth
          id="cep"
          variant="standard"
          margin="normal"
          label="CEP"
          onValueChange={({ value }) => setFieldValue('cep', value)}
          value={values.cep}
          type="tel"
          customInput={TextField}
          format="#####-###"
          mask="_"
          helperText={cepError && 'CEP não encontrado'}
          FormHelperTextProps={{
            className: 'Mui-error',
          }}
          placeholder="_____-___"
          InputLabelProps={{ shrink: true }}
          InputProps={RegisterInputProps.inputProps}
        />
        {loading && <CircularProgress className="circular-progress" size={18} />}
      </CepContainer>
      {showFields && (
        <div>
          <StreetAndNumberContainer>
            <TextField
              id="address"
              label="Rua"
              variant="standard"
              margin="normal"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              error={(!values.address && touched.address) || values.address.length > 50}
              inputProps={{ ...RegisterInputProps.inputProps, maxLength: 50 }}
            />
            <TextField
              id="number"
              label="Número"
              variant="standard"
              margin="normal"
              value={values.number}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              error={(!values.number && touched.number) || values.number.length > 10}
              inputProps={{ ...RegisterInputProps.inputProps, maxLength: 10 }}
            />
          </StreetAndNumberContainer>
          <TextField
            id="complement"
            label="Complemento"
            variant="standard"
            margin="normal"
            value={values.complement}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            inputProps={{ ...RegisterInputProps.inputProps, maxLength: 30 }}
          />
          <TextField
            id="neighbor"
            label="Bairro"
            variant="standard"
            margin="normal"
            value={values.neighbor}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            error={(!values.neighbor && touched.neighbor) || (values.neighbor.length > 30 && touched.neighbor)}
            inputProps={{ ...RegisterInputProps.inputProps, maxLength: 30 }}
          />
          <TextField
            id="city"
            label="Cidade"
            variant="standard"
            margin="normal"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            error={!values.city && touched.city}
            inputProps={{ ...RegisterInputProps.inputProps, maxLength: 30 }}
          />
          <TextField
            id="uf"
            label="Estado"
            variant="standard"
            margin="normal"
            value={values.uf}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            error={!values.uf && touched.uf}
            inputProps={{ ...RegisterInputProps.inputProps, maxLength: 2 }}
          />
        </div>
      )}
      <ButtonContainer>
        <Button
          isLoading={loading}
          type="submit"
          element="Continuar"
          variant="contained"
          handleClick={() => setReturnToPersonalData(false)}
        />
      </ButtonContainer>
      <ButtonContainer link>
        <Button variant="link" element="Voltar para dados pessoais" handleClick={handleReturnToPersonalData} />
      </ButtonContainer>
    </FormContainer>
  )
}
