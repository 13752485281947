/**
 * tracker is a helper for send tracking
 */

interface DataLayerProps {
  event: string
  interaction?: object
  page?: object
}

const gtmTracker = ({ event, interaction = {}, page = {} }: DataLayerProps) => {
  const payload = {
    type: 'eventGtm',
    data: { event, page, interaction },
  }
  window?.top?.postMessage(JSON.stringify(payload), '*')
}

export default gtmTracker
