import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 100px;
`

export const Tip = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0px;
  margin-top: 40px;
`
