import { Container } from './style'

interface YourDiscountProps {
  elegible: boolean
  discountTag: string
  descriptionInfo?: string
}

const YourDiscount = ({ elegible, discountTag, descriptionInfo }: YourDiscountProps) => {
  return (
    <Container elegible={elegible}>
      <h1>Seu desconto atual</h1>
      <div className="tagContainer">
        <p className="tagText">{discountTag}</p>
      </div>
      {descriptionInfo && <p className="description">{descriptionInfo}</p>}
    </Container>
  )
}

export default YourDiscount
