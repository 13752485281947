import { useHistory } from 'react-router-dom'
import { Grid } from '@mui/material'
import customerService from 'services/Register/customer.service'
import { ButtonIcon } from './style'
import { natures } from './NatureData'

export default function NatureRegister() {
  const history = useHistory()

  const handleClick = (nature: string) => {
    customerService.sendNatureTypeToCRM(nature)
    history.push('/criar-conta/dados-pessoais')
  }

  return (
    <Grid container spacing={1.8} columns={{ xs: 1, sm: 2, md: 3, lg: 3 }}>
      {natures.map((item) => (
        <Grid item xs={1} key={item.id}>
          <ButtonIcon type="button" key={item.id} onClick={() => handleClick(item.selectedNature)}>
            <img src={item.imgPath} alt={item.type} />
            <p>{item.phrase}</p>
          </ButtonIcon>
        </Grid>
      ))}
    </Grid>
  )
}
