import PrimaryButton from 'components/UI/Button/PrimaryButton'
import styled from 'styled-components'
import usePostMessage from 'hooks/usePostMessage'
import { useEffect } from 'react'
import { isMobile } from 'utils/tracker/browser-utils'
import Card from '../../UI/Card/Card'
import Benefits from './Benefits/Benefits'

const Content = styled.div`
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 40px;
  height: 270px;
  background-color: #fff;

  @media (max-device-width: 639px) {
    padding-left: 0;
    padding-right: 0;
    background: none;
    flex-direction: column;
    height: 460px;
  }
`

const LeftContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  @media (max-device-width: 639px) {
    flex-grow: 0;
    margin-bottom: 12px;
  }
`

const RightContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`
const SubtitleText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #676c72;
  font-weight: 600;

  @media (max-device-width: 639px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`
const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #112f71;

  @media (max-device-width: 639px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`

const ButtonStyle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #112f71;
  align-self: center;
  width: 350px;
  height: 4em;

  @media (max-device-width: 639px) {
    width: 220px;
    height: 48px;
  }
`

const StyledBenefits = styled(Benefits)`
  @media (max-device-width: 639px) {
    margin-top: 15px;
  }
`

export default function FinishRegistration() {
  const usePostMessageHook = usePostMessage('FinishRegistration', () => {})

  function iframeResize() {
    usePostMessageHook.sendMessage({
      type: 'changeRegistrationIframeSize',
      data: document.body.clientHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', iframeResize)
    window.addEventListener('load', iframeResize)

    return () => {
      window.removeEventListener('resize', iframeResize)
      window.removeEventListener('load', iframeResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function completeProfile() {
    usePostMessageHook.sendMessage({
      type: 'completeProfile',
    })
  }

  return (
    <Card>
      <Content>
        <LeftContent>
          <Title>
            Boas-vindas! <br /> Vamos concluir o cadastro?
          </Title>
          {!isMobile() && (
            <ButtonStyle>
              <PrimaryButton onClick={completeProfile}>Concluir cadastro</PrimaryButton>
            </ButtonStyle>
          )}
        </LeftContent>

        <RightContent>
          <SubtitleText>Falta pouco para você:</SubtitleText>

          <StyledBenefits />

          {isMobile() && (
            <ButtonStyle>
              <PrimaryButton onClick={completeProfile}>Concluir cadastro</PrimaryButton>
            </ButtonStyle>
          )}
        </RightContent>
      </Content>
    </Card>
  )
}
