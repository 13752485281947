import { PostMessageSendMessageI } from 'interfaces/postMessage'
import { createContext } from 'react'
import { FileWithPath } from 'react-dropzone'

interface Context {
  userToken?: string
  page: number
  setPage(page: number): void
  documentType?: string
  setDocumentType(documentType: string): void
  frontDocument?: FileWithPath
  setFrontDocument(document: FileWithPath): void
  verseDocument?: FileWithPath
  setVerseDocument(document: FileWithPath): void
  sendDocument(): void
  loading?: boolean
  setLoading(loading: boolean): void
  sendPostMessage(message: PostMessageSendMessageI): void
  setFrontImageType(imageType: string): void
  setVerseImageType(imageType: string): void
  frontImageType?: string
  verseImageType?: string
}

export const OnBoardingContext = createContext<Context>({
  page: 1,
  setPage: () => {},
  setDocumentType: () => {},
  setFrontDocument: () => {},
  setVerseDocument: () => {},
  sendDocument: () => {},
  setLoading: () => {},
  sendPostMessage: () => {},
  setFrontImageType: () => {},
  setVerseImageType: () => {},
})

export default OnBoardingContext
